// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the Files
// ------------------------------------------------------------------------------

export const FILEHEADERLOGO = "-LOGO-";
// Be carefull as the space between the 2 lines is important !!
export const FILEHEADERQUOTATION = "-QUOTATION-";

export const FILEHEADERPICTURERESSOURCE = "-PICTURERESOURCE-";

export const FILEHEADERPICTUREIMAGE = "-PICTUREIMAGE-";

export const FILEHEADERIMAGENAMECOMPLEMENTMINIWEB = "miniweb-";

export const FILEHEADERIMAGENAMECOMPLEMENTMINIWEBBACGROUNDSHOWROOM =
  "miniwebBackgroundShowroom-";
export const FILEHEADERIMAGENAMECOMPLEMENTMINIWEBBACGROUNDSERVICES =
  "miniwebBackgroundServices-";

export const FILEHEADERIMAGENAMECOMPLEMENTMINIWEBINSIDESHOWROOM =
  "miniwebInsideShowroom-";

export const FILEHEADERCOPYMINIWEB = "-copyminiweb";
