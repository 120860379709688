// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Generic functions for Time Mngt
// ------------------------------------------------------------------------------

// Build a static table with the list of Minutes from '00' to '59'
export function buildMinutesTable(table) {
  var value = "";
  for (var i = 0; i < 60; i++) {
    switch (i) {
      case 0:
        value = "00";
        break;
      case 1:
        value = "01";
        break;
      case 2:
        value = "02";
        break;
      case 3:
        value = "03";
        break;
      case 4:
        value = "04";
        break;
      case 5:
        value = "05";
        break;
      case 6:
        value = "06";
        break;
      case 7:
        value = "07";
        break;
      case 8:
        value = "08";
        break;
      case 9:
        value = "09";
        break;
      default:
        value = i.toString();
        break;
    }
    table.push({ value: value });
  }
}

export function isTimeStartBiggerTimeEnd(
  timeStartHH,
  timeStartMM,
  timeEndHH,
  timeEndMM,
) {
  const timeStart = parseInt(timeStartHH) + parseInt(timeStartMM) / 100;
  // console.log('timeStart', timeStart)
  const timeEnd = parseInt(timeEndHH) + parseInt(timeEndMM) / 100;
  // console.log('timeEnd', timeEnd)
  if (timeStart > timeEnd) {
    return true;
  }
  return false;
}

export function isDateStartBiggerDateEnd(startDate, endDate) {
  const start = new Date(startDate);
  // console.log('start', start)
  const end = new Date(endDate);
  // console.log('end', end)
  if (start > end) {
    return true;
  }
  return false;
}
