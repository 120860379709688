<template>
  <v-theme-provider :dark="dark">
    <div>
      <a href="https://kidiwidigital.com" target="_blank">
        <base-img
          :src="require('@/assets/KIDIWI-digital-white.svg')"
          alt="KIDIWI digital logo"
          contain
          max-width="256"
          min-height="140"
          width="100%"
        />
      </a>

      <base-title
        size="text-body-1"
        space="4"
        title="100% DIGITAL"
        weight="regular"
      />

      <base-body>
        {{ $t("kidiwiInfo.general") }}
      </base-body>
      <!--
      <base-btn
        class="mb-12"
        color="white"
        outlined
        @click="openKidiwiDigital()"
      >
        {{ $t('button.moreInfo') }}
      </base-btn>
      -->
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseInfo",

  props: {
    title: String,
    dark: Boolean,
  },

  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Address",
        text: "8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137",
      },
      {
        icon: "mdi-cellphone",
        title: "Phone",
        text: "01 (800) 433 744<br>01 (800) 433 633",
      },
      {
        icon: "mdi-email",
        title: "Email",
        text: "john@vuetifyjs.com<br>heather@vuetifyjs.com",
      },
    ],
  }),

  methods: {
    openKidiwiDigital() {
      window.open("http://kidiwidigital.com");
    },
  },
};
</script>
