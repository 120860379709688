<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to manage the email input
// v-model: field to store the mail address. Mail is mandatory and must followup the mail format.
// Props: None
// Example of usage:
          <kdw-text-field-email
            id="IDcpymail"
            v-model="myServiceProvider.emailcpy"
          />
// ------------------------------------------------------------------------------>
<template>
  <v-text-field
    outlined
    dense
    :label="$t('identity.mail') + $t('meta.star')"
    :rules="[rules.required, rules.email]"
    prepend-icon="mdi-email"
    clearable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot slot="append-outer" name="append-outer" />
  </v-text-field>
</template>

<script>
export default {
  name: "BaseTextFieldEmail",
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$i18n.t("AccountSP.required"),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$i18n.t("AccountSP.mailFormat");
        },
      },
    };
  },
};
</script>

<style lang="sass">
//
</style>
