export function getBackgroundPicture() {
  const max = 67;
  const random = String(Math.floor(Math.random() * max)).padStart(3, "0");
  // console.log(`random is ${random}`)
  return require(`@/assets/background/background${random}.jpg`);
}

// this function convert a color from the format in hex ("#ff0000") to RGB
export function hexToRGB(hex) {
  // console.log('before - hex:', hex, ' r:', hex.substring(1, 3), ' g:', hex.substring(3, 5), ' b:', hex.substring(5, 7))
  var r = parseInt(hex.substring(1, 3), 16);
  var g = parseInt(hex.substring(3, 5), 16);
  var b = parseInt(hex.substring(5, 7), 16);
  // console.log('hex:', hex, ' r:', r, ' g:', g, ' b:', b)
  return [r, g, b];
}

export function isColorDark(hex) {
  const colorArray = hexToRGB(hex);
  const brightness =
    (colorArray[0] * 0.299 + colorArray[1] * 0.587 + colorArray[2] * 0.114) /
    255;
  if (brightness > 0.5) {
    return false; // ligth color
  } else return true; // dark color
}
