<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This component is used extract the service list around a specific localisation (gps, radius) when a search is triggered. In this mode the service provider ID is ignored.
// If the searchedID is empty string, then we will return the list of services for a specific Service Provider ID.
// This is specialised for Miniweb only as we do not display the logo & company name in this module.
// The display is based on Expansion panels.
// The props list has not be changed comparing to its parent: <kdw-general-search-results>
// Props:
// - searchEvent: tell if the search is to be done or not (true or false)
// - searchedId: Category ID selected by the end user + ',' + ???
// - localisation: Object {
//    . found = boolean, true if the name of the town is found
//    . id = ID of the town
//    . latitude = latitude of the town searched
//    . longitude = longitude of the town searched
//    . radius = radius around the town search
//    . townName = Name of the town found (string)
//    }
//  - serviceProviderId = ID of the SP
// Events:
// -ResetSearchEvent: Emit an event to reset the searchResultFlag
// - NoServiceFound: TBD
// - ServiceSelected: TBD
// Component:
    <kdw-general-search-results-extension-based
      :search-event="searchResultFlag"
      :searched-id="searchedId"
      :localisation="localisation"
      @ResetSearchEvent="resetSearchEvent()"
      @NoServiceFound="NoServiceFound()"
      @ServiceSelected="ServiceSelected($event)"
    />
// ------------------------------------------------------------------------------>

<template>
  <div id="generalsearchresultsextensionbased">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card
          v-show="displayServices"
          elevation="11"
          class="mx-auto my-12 rounded"
          shaped
        >
          <v-system-bar
            v-if="searchedId !== ''"
            color="primary"
            class="grey--text text--lighten-3"
          >
            <v-icon class="ml-1 grey--text text--lighten-3">
              mdi-folder-pound
            </v-icon>
            <span class="font-weight-bold">{{
              foundServices.length > 0
                ? $t(foundServices[0].categoryNameTag).toUpperCase()
                : ""
            }}</span>
          </v-system-bar>
          <v-row justify="center">
            <v-expansion-panels popout focusable>
              <v-expansion-panel v-for="(item, i) in foundServices" :key="i">
                <v-expansion-panel-header>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row no-gutters justify="space-between">
                        <v-col cols="12" md="6">
                          <v-list-item three-line>
                            <v-list-item-content>
                              <v-list-item-title
                                class="primary--text ml-4 mt-4"
                                style="white-space: normal"
                              >
                                <strong>{{ item.name }}</strong>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                v-show="item.subCategory !== ''"
                                style="white-space: normal"
                                class="concrete--text"
                              >
                                <v-icon small class="concrete--text">
                                  mdi-folder-pound-outline
                                </v-icon>
                                {{ item.subCategory }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle class="concrete--text">
                                <v-icon small class="concrete--text">
                                  mdi-map-marker
                                </v-icon>
                                {{ item.townName }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <!-- This is a desktop logic -->
                        <v-col cols="12" md="6" class="hidden-sm-and-down">
                          <v-row no-gutters>
                            <v-col cols="12" md="6">
                              <v-row no-gutters class="d-flex align-end">
                                <v-spacer />
                                <v-col>
                                  <v-list-item-action class="mt-1">
                                    <kdw-label
                                      :text="
                                        displayServicePrice(
                                          item.isFixedPrice,
                                          item.ServicePrice,
                                        )
                                      "
                                    />
                                  </v-list-item-action>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-list-item-action class="">
                                <v-tooltip bottom>
                                  <template #activator="{ on, attrs }">
                                    <kdw-btn
                                      :id="
                                        'informationSearchButtonMobile' +
                                        item.number
                                      "
                                      color="sunflower"
                                      elevation="9"
                                      min-width="49"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="serviceDescriptionDetails(item)"
                                    >
                                      <v-icon color="white">
                                        mdi-information-outline
                                      </v-icon>
                                    </kdw-btn>
                                  </template>
                                  <span>{{
                                    $t("tooltip.serviceOverview")
                                  }}</span>
                                </v-tooltip>
                              </v-list-item-action>
                              <v-list-item-action class="">
                                <v-tooltip bottom>
                                  <template #activator="{ on, attrs }">
                                    <kdw-btn
                                      :id="
                                        'selectOfferSearchButtonDesktop' +
                                        item.number
                                      "
                                      color="sea"
                                      elevation="10"
                                      min-width="50"
                                      v-bind="attrs"
                                      dark
                                      v-on="on"
                                      @click="selectOffer(item)"
                                    >
                                      <v-icon>mdi-check-bold</v-icon>
                                    </kdw-btn>
                                  </template>
                                  <span>{{ $t("tooltip.PickSP") }}</span>
                                </v-tooltip>
                              </v-list-item-action>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <!----- THIS IS THE MOBILE LOGIC ---->
                      <v-card flat class="d-flex" color="transparent">
                        <kdw-label
                          class="mt-1 hidden-md-and-up"
                          :text="
                            displayServicePrice(
                              item.isFixedPrice,
                              item.ServicePrice,
                            )
                          "
                        />
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <kdw-btn
                              :id="
                                'informationSearchButtonMobile' + item.number
                              "
                              class="ml-1 mr-5 my-auto hidden-md-and-up"
                              color="sunflower"
                              elevation="9"
                              min-width="49"
                              v-bind="attrs"
                              v-on="on"
                              @click="serviceDescriptionDetails(item)"
                            >
                              <v-icon color="white">
                                mdi-information-outline
                              </v-icon>
                            </kdw-btn>
                          </template>
                          <span>{{ $t("tooltip.serviceOverview") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <kdw-btn
                              :id="
                                'selectOfferSearchButtonMobile' + item.number
                              "
                              class="ml-1 my-auto hidden-md-and-up"
                              dark
                              color="sea"
                              elevation="3"
                              min-width="49"
                              v-bind="attrs"
                              v-on="on"
                              @click="selectOffer(item)"
                            >
                              <v-icon>mdi-check-bold</v-icon>
                            </kdw-btn>
                          </template>
                          <span>{{ $t("tooltip.PickSP") }}</span>
                        </v-tooltip>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item>
                    <!------- 4 infos when expanding with: Category, Service desciption , Legal & Duration  ---->
                    <v-list-item-content>
                      <v-list-item-subtitle style="white-space: normal">
                        <v-icon small> mdi-folder-pound </v-icon>
                        {{ item.category }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-show="item.serviceDescriptionShort !== ''"
                        class="concrete--text"
                        style="white-space: nowrap"
                      >
                        <v-icon color="concrete" small dark>
                          mdi-information-outline
                        </v-icon>
                        {{ item.serviceDescriptionShort }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.serviceLegaldescription !== ''"
                      >
                        <strong style="white-space: pre-wrap">
                          <v-icon color="concrete" small dark>
                            mdi-comment-flash-outline
                          </v-icon>
                          {{
                            item.serviceLegaldescription ||
                            $t("hero.nodescription")
                          }}
                        </strong>
                      </v-list-item-subtitle>
                      <v-list-item-action>
                        <kdw-label
                          :text="item.serviceDuration"
                          :white="false"
                        />
                      </v-list-item-action>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <!---- We are taking care of the bottom of the card --->
          <v-divider />
          <v-card-actions class="mt-4">
            <v-combobox
              :key="'ComboFilter1' + displayTrick"
              v-model="filterOptionsSelected"
              :items="getFilterOptions"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.filter')"
              light
              dense
              class="mx-2 ml-5"
              @change="filterOptionsChanged()"
            />
            <v-combobox
              :key="'ComboFilter2' + displayTrick"
              v-model="rowByPageSelected"
              :items="getRowByPage"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.rowsByPage')"
              class="mx-2"
              dense
              disabled
              light
            />
            <v-spacer />
            <v-chip disabled label class="mb-4"> 1/1 </v-chip>
            <kdw-btn
              min-width="10"
              small
              class="ml-3 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon>mdi-chevron-left </v-icon>
            </kdw-btn>
            <kdw-btn
              min-width="10"
              small
              class="mr-5 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon> mdi-chevron-right </v-icon>
            </kdw-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { fetchTownfromPostalCode, fetchTownfromPartialName } from '@/services/PostalCode'
// import { getByCityPostalCode } from '@/services/commonTools'
// import { normaliseTextFR } from '@/helpers/GenericFunctions'
import {
  servicesfromOnePostalCodeRayon,
  servicesfromOneSP,
} from "@/services/Distance";
import { formatServicePriceString } from "@/helpers/Formatter";
import { Logger } from "aws-amplify";
const logger = new Logger("GeneralSearchResultsExtensationBased");

export default {
  name: "GeneralSearchResults",

  props: {
    searchEvent: {
      type: Boolean,
      default: false,
    },
    searchedId: {
      type: String,
      default: "",
    },
    localisation: {
      type: Object,
    },
    serviceProviderId: {
      type: String,
      default: "",
    },
  },

  // TODO translations
  data: () => ({
    // Mngt of the display
    itemHeigth: 150,
    resultHeigthMobile: 150,
    resultHeigthDesktop: 110,
    triggerMobileDesktop: 500,
    marginEndItem: 70,
    // Screen mangement - Extracted from plug-in : VueScreenSize - https://github.com/johndatserakis/vue-screen-size/blob/develop/src/vue-screen-size.js
    event: null,
    vssWidth: null,
    vssHeight: null,
    // Managment of the filters
    rowByPage: [],
    rowByPageSelected: { id: "all" },
    filterOptions: [],
    filterOptionsSelected: { id: "NAME↓" },
    displayTrick: 0,
    /*
      filterOptions: [{ id: 'PRICE↑', name: this.$i18n.t('generalSearchResults.filterPriceUp') },
                      { id: 'PRICE↓', name: this.$i18n.t('generalSearchResults.filterPriceDown') },
                      { id: 'SUBCATEGORY↑', name: this.$i18n.t('generalSearchResults.filterSubCategoryUp') },
                      { id: 'SUBCATEGORY↓', name: this.$i18n.t('generalSearchResults.filterSubCategoryDown') },
                      { id: 'NAME↑', name: this.$i18n.t('generalSearchResults.filterNameUp') },
                      { id: 'NAME↓', name: this.$i18n.t('generalSearchResults.filterNameDown') }],
      filterOptionsSelected: { id: 'NAME↓', name: this.$i18n.t('generalSearchResults.filterNameDown') },
      */
    displayServices: false,
    foundServices: [
      // { cpyLogo: 'https://cdn.vuetifyjs.com/images/john.png', name: 'Peinture Intérieure Intérieure Intérieure Intérieure', serviceDescription: 'Peinture de votre intérieur', isFixedPrice: false, id: '2c804156-81c0-4aec-b242-7336d1b65b68', serviceDescriptionShort: 'Peinture de votre intérie...', serviceDuration: '1h', ServicePrice: 'Quote based', subCategory: 'Indoor painting', category: 'Painting', townName: 'MOUGINS' },
      // { cpyLogo: '', name: 'Peinture extérieure', serviceDescription: 'Peinture de votre extérieur', isFixedPrice: false, id: '2c804156-81c0-4aec-b242-7336d1b65b68', serviceDescriptionShort: 'Peinture de votre ext..', serviceDuration: '3h', ServicePrice: 'Quote based', subCategory: 'Outdoor painting', category: 'Painting', townName: 'MOUGINS' },
      // { cpyLogo: 'https://cdn.vuetifyjs.com/images/john.png', name: 'Peinture extérieure2', serviceDescription: 'Peinture de votre extérieur', isFixedPrice: true, id: '2c804156-81c0-4aec-b242-7336d1b65b68', serviceDescriptionShort: 'blabla...ext..', serviceDuration: '2h', ServicePrice: '111', subCategory: 'Outdoor painting', category: 'Painting', townName: 'MOUGINS' },
    ],
  }),

  computed: {
    getRowByPage: function () {
      return this.getRowByPageMethod();
    },
    getFilterOptions: function () {
      return this.getFilterOptionsMethod();
    },
    getHeigthUsingScreenWidth: function () {
      return this.getHeigthUsingScreenWidthMethod();
    },
    getHeigthUsingScreenHeigth: function () {
      // compute the size of the max screen heigth
      const maxScreenHeigth = Math.trunc(this.vssHeight * 0.7);
      // console.log('maxScreenHeigth:', maxScreenHeigth)
      // Compute max heigth needed:
      const maxHeigthNeeded = this.foundServices.length * this.itemHeigth;
      if (maxHeigthNeeded + this.marginEndItem < maxScreenHeigth) {
        return maxHeigthNeeded + this.marginEndItem;
      }
      return maxScreenHeigth;
    },
    // Management of the screen change
    $vssEvent: function () {
      return this.event;
    },
    $vssWidth: function () {
      // console.log('change vssWidth')
      return this.vssWidth || this.getScreenWidth();
    },
    $vssHeight: function () {
      // console.log('change vssHeight')
      return this.vssHeight || this.getScreenHeight();
    },
  },

  watch: {
    // We check if the parent requested for a search
    searchEvent: function (val) {
      if (val) {
        // console.log('searchEvent:', this.searchEvent)
        // console.log('searchedId:', this.searchedId)
        // console.log('localisation:')
        // console.log(this.localisation)
        // console.log('serviceProviderId:', this.serviceProviderId)
        // We ask to reset the parent
        this.$emit("ResetSearchEvent");
        this.search();
      }
    },
    vssWidth: function (val) {
      // console.log('change vssWidth:', val)
      this.itemHeigth = this.getHeigthUsingScreenWidthMethod(val);
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created: function () {
    this.vssWidth = screen.width;
    this.vssHeight = screen.height;
    this.getFilterOptionsMethod();
    this.getRowByPageMethod();
  },
  methods: {
    // Management of the screen size
    getScreenWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },
    getScreenHeight() {
      return (
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      );
    },
    handleResize(event) {
      this.event = event;
      this.vssWidth = this.getScreenWidth();
      this.vssHeight = this.getScreenHeight();
    },
    $vssDestroyListener() {
      window.removeEventListener("resize", this.handleResize);
    },

    // logic before
    getHeigthUsingScreenWidthMethod(itemWith) {
      if (itemWith > 500) {
        // console.log('getHeigthUsingScreenWidth : 110')
        return 110;
      }
      // console.log('getHeigthUsingScreenWidth : 150')
      return 150;
    },
    /*
      getHeigthUsingScreenHeigth () {
        return Math.trunc(screen.height * 0.7)
      },
      */
    serviceDescriptionDetails(item) {
      console.log("serviceDescriptionDetails - information click");
    },
    displayServicePrice(isFixedPrice, ServicePrice) {
      // TODO better place for scrolling?
      // BEG scroll to results
      /* TO CHECK WHAT IS THAT???????????????????????????????????????????????
        this.$vuetify.goTo('#generalsearchresults', {
          duration: 1000,
          offset: 0,
          easing: 'easeInOutQuint',
        })
        */
      // END scroll to results
      return formatServicePriceString(isFixedPrice, ServicePrice);
    },
    getRowByPageMethod: function () {
      const table = [
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "5", name: "5" },
        { id: "10", name: "10" },
        { id: "15", name: "15" },
        { id: "all", name: this.$i18n.t("generalSearchResults.all") },
      ];
      if (this.rowByPageSelected.id === "all") {
        this.rowByPageSelected.name = this.$i18n.t("generalSearchResults.all");
      }
      return table;
    },
    getFilterOptionsMethod() {
      const table = [
        {
          id: "PRICE↑",
          name: this.$i18n.t("generalSearchResults.filterPriceUp"),
        },
        {
          id: "PRICE↓",
          name: this.$i18n.t("generalSearchResults.filterPriceDown"),
        },
        {
          id: "SUBCATEGORY↑",
          name: this.$i18n.t("generalSearchResults.filterSubCategoryUp"),
        },
        {
          id: "SUBCATEGORY↓",
          name: this.$i18n.t("generalSearchResults.filterSubCategoryDown"),
        },
        {
          id: "NAME↑",
          name: this.$i18n.t("generalSearchResults.filterNameUp"),
        },
        {
          id: "NAME↓",
          name: this.$i18n.t("generalSearchResults.filterNameDown"),
        },
      ];
      // console.log('this.filterOptionsSelected before')
      // console.log(this.filterOptionsSelected)
      if (this.filterOptionsSelected.id === "PRICE↑") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterPriceUp",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "PRICE↓") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterPriceDown",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "SUBCATEGORY↑") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterSubCategoryUp",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "SUBCATEGORY↓") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterSubCategoryDown",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "NAME↑") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterNameUp",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "NAME↓") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterNameDown",
        );
      }
      // console.log('this.filterOptionsSelected after')
      // console.log(this.filterOptionsSelected)
      this.displayTrick++;
      return table;
    },

    // The end user requested a search
    async search() {
      // console.log('this.foundServices')
      // console.log(this.foundServices)
      // console.log('this.searchCategory')
      // console.log(this.searchCategory)
      // console.log('this.searchedId')
      // console.log(this.searchedId)
      // console.log('headers')
      // console.log(headers)

      // We check the case we are in : search coming from Hero page with a postal code or coming from a miniweb page
      if (this.searchedId !== "" && this.serviceProviderId === "") {
        // We are coming from the search of Hero page
        var headers = this.searchedId.split(",");
        // clear the found service table
        this.foundServices.splice(0, this.foundServices.length);
        // Perform the search
        servicesfromOnePostalCodeRayon(
          this.localisation.latitude,
          this.localisation.longitude,
          this.localisation.radius,
          headers[0],
          this.foundServices,
        )
          .then(() => {
            if (this.foundServices.length === 0) {
              this.$emit("NoServiceFound");
              this.displayServices = false;
            } else {
              this.displayServices = true;
              this.sortFoundServices();
              // console.log('this.foundServices:')
              // console.log(this.foundServices)
            }
          })
          .catch((err) => logger.error(err));
      } else {
        // We are coming from mini-web
        console.log("mini-web request", this.serviceProviderId);
        servicesfromOneSP(this.serviceProviderId, this.foundServices)
          .then(() => {
            if (this.foundServices.length === 0) {
              this.$emit("NoServiceFound");
              this.displayServices = false;
            } else {
              this.displayServices = true;
              this.sortFoundServices();
              // console.log('this.foundServices:')
              // console.log(this.foundServices)
            }
          })
          .catch((err) => logger.error(err));
      }
    },

    // Filter option changed
    filterOptionsChanged() {
      // console.log('filterOptionsChanged', this.filterOptionsSelected)
      this.sortFoundServices();
    },
    selectOffer(service) {
      // console.log('selected Service:')
      // console.log(service)
      // We emit the result to the parent as the end user select the offer
      this.$emit("ServiceSelected", service);
    },
    // This is managing the ordering options of the search content
    sortFoundServices() {
      // console.log('this.foundServices')
      // console.log(this.foundServices)
      switch (this.filterOptionsSelected.id) {
        case "NAME↑": // sort name by alphabetic order inverse
          this.foundServices.sort(function (a, b) {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return 1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return -1;
            }
            return 0;
          });
          break;
        case "NAME↓": // sort name by alphabetic order
          this.foundServices.sort(function (a, b) {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          break;
        case "PRICE↑": // sort by price inverse (from big to small)
          this.foundServices.sort(function (a, b) {
            if (a.ServicePrice < b.ServicePrice) {
              return 1;
            }
            if (a.ServicePrice > b.ServicePrice) {
              return -1;
            }
            return 0;
          });
          break;
        case "PRICE↓": // sort by price (from small to big)
          this.foundServices.sort(function (a, b) {
            if (a.ServicePrice < b.ServicePrice) {
              return -1;
            }
            if (a.ServicePrice > b.ServicePrice) {
              return 1;
            }
            return 0;
          });
          break;
        case "SUBCATEGORY↑": // sort subCategory by alphabetic order inverse
          this.foundServices.sort(function (a, b) {
            if (a.subCategory.toUpperCase() < b.subCategory.toUpperCase()) {
              return 1;
            }
            if (a.subCategory.toUpperCase() > b.subCategory.toUpperCase()) {
              return -1;
            }
            return 0;
          });
          break;
        case "SUBCATEGORY↓": // sort subCategory by alphabetic order
          this.foundServices.sort(function (a, b) {
            if (a.subCategory.toUpperCase() < b.subCategory.toUpperCase()) {
              return -1;
            }
            if (a.subCategory.toUpperCase() > b.subCategory.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          break;
      }
    },
  },
};
</script>
