<template>
  <v-text-field
    v-model="variable"
    outlined
    required
    dense
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot slot="append-outer" name="append-outer" />
  </v-text-field>
</template>

<script>
export default {
  name: "BaseTextFieldVariable",
};
</script>

<style lang="sass">
//
</style>
