<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display the information list of a review.
// Props:
// - reviews: review array
// Example of usage:
      <kdw-reviews-display-items
        :reviews="reviews"
      />
// ------------------------------------------------------------------------------>

<template>
  <v-list>
    <v-list-item v-for="review in reviews" :key="review.euReviewComment">
      <v-list-item-content>
        <v-list-item-title>
          <v-icon small color="primary"> mdi-star </v-icon>
          ( {{ review.spRating }}/5)
        </v-list-item-title>
        <v-list-item-title class="font-weight-bold">{{
          review.euReviewComment
        }}</v-list-item-title>
        <v-list-item-subtitle>{{
          review.spReviewComment
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "ReviewDisplaysItems",
  mixins: [Heading],
  props: {
    reviews: {
      type: Array,
      required: true,
    },
  },

  data: () => {
    return {};
  },

  created: function () {},
  methods: {},
};
</script>
