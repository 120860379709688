// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the schedule function for one or several ressources
// ------------------------------------------------------------------------------

// import { Logger } from 'aws-amplify'
// import { createResource as createResourceMutation, updateResource as updateResourceMutation, deleteResource as deleteResourceMutation } from '@/graphql/mutations'
// import { getResource } from '@/graphql/queries'
// import { getUserSub } from '@/services/User'
import { Scheduler } from "@ssense/sscheduler";
import { DateTime } from "luxon";

// const logger = new Logger('Scheduler')

export class Kscheduler {
  scheduler = new Scheduler();
  // availability = []

  constructor() {
    console.log("Scheduler created");
  }

  /* This is what we want to produce with this function
    {
      from: '09:00',
      to: '17:00',
      unavailability: [
        { from: '12:00', to: '13:00' },
      ],
    },
    from the weekWorkingDaysArray
  */
  buildDaySchedule(weekWorkingDaysArray) {
    // console.log('weekWorkingDaysArray')
    // console.log(weekWorkingDaysArray)
    var unavailability = [];
    var unavailabilityObject = {};
    var mainObject = {};
    // console.log(weekWorkingDaysArray.length)
    if (weekWorkingDaysArray.length === 0) {
      return mainObject;
    }
    var found = false;
    for (var i = 0; i < weekWorkingDaysArray.length; i++) {
      // console.log(weekWorkingDaysArray[i])
      if (weekWorkingDaysArray[i].type === "Working") {
        found = true;
        mainObject.from =
          weekWorkingDaysArray[i].startHH +
          ":" +
          weekWorkingDaysArray[i].startMM;
        mainObject.to =
          weekWorkingDaysArray[i].endHH + ":" + weekWorkingDaysArray[i].endMM;
      }
      if (weekWorkingDaysArray[i].type === "Break") {
        unavailabilityObject = {};
        unavailabilityObject.from =
          weekWorkingDaysArray[i].startHH +
          ":" +
          weekWorkingDaysArray[i].startMM;
        unavailabilityObject.to =
          weekWorkingDaysArray[i].endHH + ":" + weekWorkingDaysArray[i].endMM;
        unavailability.push(unavailabilityObject);
      }
    }
    if (found === false) {
      return mainObject;
    }
    if (found === true) {
      if (unavailability.length > 0) {
        mainObject.unavailability = unavailability;
      }
      return mainObject;
    }
    return {};
  }

  // We are converting the day off into the specific format understood by the scheduler library
  // two different types of unavailability structure
  // { from: '2017-02-20 00:00', to: '2017-02-27 00:00' },
  // { date: '2017-02-15', from: '12:00', to: '13:00' },
  buildUnavailabilitySchedule(unavailability) {
    // console.log('unavailability')
    // console.log(unavailability)
    if (unavailability.length === 0) {
      return [];
    }
    var mainObject = [];
    var found = false;
    for (var i = 0; i < unavailability.length; i++) {
      if (unavailability[i].type === "rangeByDate") {
        found = true;
        mainObject.push({
          from: unavailability[i].startDate + "T00:00",
          to: unavailability[i].endDate + "T23:59",
        });
      }
      if (unavailability[i].type === "rangeBytime") {
        found = true;
        mainObject.push({
          date: unavailability[i].startDate,
          from: unavailability[i].startHH + ":" + unavailability[i].startMM,
          to: unavailability[i].endHH + ":" + unavailability[i].endMM,
        });
      }
    }
    if (found === false) {
      return [];
    }
    if (found === true) {
      return mainObject;
    }
    return [];
  }

  // We are converting the appointment list into an allocated format for the library (Array).
  //    appointmentList = [{ type: 'rangeByDuration', startDate: '2021-09-01', startHH: '14', startMM: '00', duration: '60' },
  //                       { type: 'rangeByDuration', startDate: '2021-09-10', startHH: '15', startMM: '00', duration: '60' }]
  // allocated: [
  //  { from: '2021-11-08 10:00', duration: 60 },
  //  { from: '2021-11-16 10:00', duration: 120 },
  // ],
  buildAppointmentsSchedule(appointments) {
    // console.log('appointments:')
    // console.log(appointments)
    /*
    if (appointments.length === 0) {
      return []
    }
    var mainObject = []
    var found = false
    for (var i = 0; i < appointments.length; i++) {
      if (appointments[i].type === 'rangeByDuration') {
        found = true
        mainObject.push({ from: appointments[i].startDate + ' ' + appointments[i].startHH + ':' + appointments[i].startMM, duration: appointments[i].duration })
      }
    }
    if (found === false) {
      return []
    }
    if (found === true) {
      return mainObject
    }
    return []
    */
  }

  // this method is that to remove all the false result {time: '12:00', available: false, reference: null} and clean up the object
  // it return the date with a simplified object:
  // {time: '12:00'}
  cleanAvailability(availability) {
    // console.log('availability before :')
    // console.log(availability)
    if (
      Object.entries(availability).length === 0 &&
      availability.constructor === Object
    ) {
      return {};
    }
    var j = 0;
    var newAvailability = {};
    var newAvailabilityDay = [];
    for (const [key, value] of Object.entries(availability)) {
      // console.log(key)
      // console.log(value)
      // newAvailabilityDay.splice(0, newAvailabilityDay.length)
      newAvailabilityDay = [];
      j = 0;
      while (j < value.length) {
        if (value[j].available === true) {
          newAvailabilityDay.push({ time: value[j].time });
        }
        j++;
      }
      if (newAvailabilityDay.length > 0) {
        // console.log('newAvailabilityDay')
        // console.log(newAvailabilityDay)
        newAvailability[key] = newAvailabilityDay;
      }
    }
    // console.log('newAvailability :')
    // console.log(newAvailability)
    return newAvailability;
  }

  // this method is that to remove all the time slot object which are already passed in time
  // Time object example: {time: '12:00'}
  cleanPassedHours(availability, duration) {
    // console.log('availability before cleanPassedHours :')
    // console.log(availability)
    // console.log('-----------------------duration:', duration)
    if (
      Object.entries(availability).length === 0 &&
      availability.constructor === Object
    ) {
      return {};
    }
    var j = 0;
    const now = DateTime.now();
    var dateFetched, dateFetchedPlusDuration;
    var newAvailability = {};
    var newAvailabilityDay = [];
    for (const [key, value] of Object.entries(availability)) {
      // console.log(key)
      // console.log(value)
      // console.log(now.toFormat('yyyy-LL-dd HH:mm'))
      // newAvailabilityDay.splice(0, newAvailabilityDay.length)
      if (now.toFormat("yyyy-LL-dd") === key) {
        // console.log('THIS IS THE DATE ----------------------------')
        newAvailabilityDay = [];
        j = 0;
        while (j < value.length) {
          dateFetched = new Date(key + "T" + value[j].time + ":00");
          // console.log('dateFetched:', dateFetched.toString())
          dateFetchedPlusDuration = DateTime.fromJSDate(dateFetched).plus({
            minutes: duration,
          });
          // console.log('dateFetchedPlusDuration:', dateFetchedPlusDuration.toString())
          if (now.toSeconds() < dateFetchedPlusDuration.toSeconds()) {
            // console.log('WE ADD THE TIME')
            newAvailabilityDay.push({ time: value[j].time });
          }
          j++;
        }
        // console.log('newAvailabilityDay:', key)
        // console.log(newAvailabilityDay)
        newAvailability[key] = newAvailabilityDay;
      } else {
        // console.log('newAvailabilityDay:', key)
        // console.log(availability[key])
        newAvailability[key] = availability[key];
      }
    }
    // console.log('newAvailability after cleanPassedHours:')
    // console.log(newAvailability)
    return newAvailability;
  }

  verifySlotResource(resource, slot, duration) {
    // from, to, duration,
    var fromDate = new Date(slot.date);
    var toDate = new Date(fromDate.getTime() + 24 * 60 * 60 * 1000 * 9);
    // console.log('from:', from)
    // console.log('toDate:', toDate)
    const to = toDate.toISOString().substring(0, 10);
    // console.log('to:', to)
    var availability = this.computeAvailabilityForOneResource(
      resource,
      slot.date,
      to,
      duration,
    );
    // console.log('availability')
    // console.log(availability)
    if (
      Object.keys(availability).length !== 0 &&
      availability.constructor === Object
    ) {
      // console.log(availability[slot.date])
      if (availability[slot.date] !== undefined) {
        if (availability[slot.date].length > 0) {
          var found = false;
          var i = 0;
          while (!found && i < availability[slot.date].length) {
            if (availability[slot.date][i].time === slot.time) {
              found = true;
              // console.log('DOUBLE FOUND')
              return true;
            }
            i++;
          }
        }
      }
    }
    return false;
  }

  computeAvailabilityForOneResource(resource, from, to, duration) {
    var monday, tuesday, wednesday, thursday, friday, saturday, sunday;
    var availability = [];
    var allocated = [];
    var unavailability = [];
    var found = false;
    // We compute the daily part of the schedule
    var schedule = {};
    monday = this.buildDaySchedule(resource.weekWorkingDays[0]);
    tuesday = this.buildDaySchedule(resource.weekWorkingDays[1]);
    wednesday = this.buildDaySchedule(resource.weekWorkingDays[2]);
    thursday = this.buildDaySchedule(resource.weekWorkingDays[3]);
    friday = this.buildDaySchedule(resource.weekWorkingDays[4]);
    saturday = this.buildDaySchedule(resource.weekWorkingDays[5]);
    sunday = this.buildDaySchedule(resource.weekWorkingDays[6]);

    // console.log('monday:', i)
    // console.log(monday)
    // console.log('sunday:', i)
    // console.log(sunday)
    if (Object.keys(monday).length !== 0 && monday.constructor === Object) {
      schedule.Monday = monday;
      found = true;
    }
    if (Object.keys(tuesday).length !== 0 && tuesday.constructor === Object) {
      schedule.Tuesday = tuesday;
      found = true;
    }
    if (
      Object.keys(wednesday).length !== 0 &&
      wednesday.constructor === Object
    ) {
      schedule.Wednesday = wednesday;
      found = true;
    }
    if (Object.keys(thursday).length !== 0 && thursday.constructor === Object) {
      schedule.Thursday = thursday;
      found = true;
    }
    if (Object.keys(friday).length !== 0 && friday.constructor === Object) {
      schedule.Friday = friday;
      found = true;
    }
    if (Object.keys(saturday).length !== 0 && saturday.constructor === Object) {
      schedule.Saturday = saturday;
      found = true;
    }
    if (Object.keys(sunday).length !== 0 && sunday.constructor === Object) {
      schedule.Sunday = sunday;
      found = true;
    }
    if (found === false) {
      return [];
    }
    // We compute the unavailability of the schedule
    unavailability = this.buildUnavailabilitySchedule(resource.dayOffList);
    if (unavailability.length > 0) {
      schedule.unavailability = unavailability;
    }

    allocated = [];
    if (resource.appointments.list.length !== 0) {
      allocated = resource.appointments.allocated();
    }
    //  example allocated: [
    //  { from: '2017-02-01 13:00', duration: 60 },
    //  { from: '2017-02-01 14:00', duration: 60 },
    // ],
    // allocated = this.buildAppointmentsSchedule(resourceList[i].appointments)
    if (allocated.length !== 0) {
      schedule.allocated = allocated;
    }
    // console.log('Ressource:')
    // console.log(resource)
    // console.log('allocated:')
    // console.log(allocated)
    // the schedule is empty and not defined by the ressource, so no need to consider this ressource.
    if (Object.keys(schedule).length !== 0 && schedule.constructor === Object) {
      if (resource.interval !== null && resource.interval !== 0) {
        // We are getting the availability for this ressource using the schedule built and the param & the scheduler library
        // console.log('from:')
        // console.log(from)
        // console.log('to')
        // console.log(to)
        // console.log('interval:')
        // console.log(resource.interval)
        /*
        var schedule1 = {
          monday: {
            from: '09:00',
            to: '17:00',
          },
        }
        */
        // console.log('schedule1:')
        // console.log(schedule1)
        // console.log('schedule:')
        // console.log(schedule)
        availability = this.scheduler.getAvailability({
          from: from,
          to: to,
          duration: duration,
          interval: resource.interval,
          schedule: schedule,
        });
        // console.log('availability before cleanAvailability:')
        // console.log(availability)

        // We are cleaning the data to remove what we dont need. Format cleaning & keep only the hours
        availability = this.cleanAvailability(availability);
        // console.log('availability after clean:')
        // console.log(availability)
        availability = this.cleanPassedHours(availability, duration);

        // this.getAvailability1()
        return availability;
      }
    }
    return [];
  }

  // get the availability for each ressource passed in the "resourceList" for a specific period (from: 2017-02-01, to: 2017-02-01), specific interval, and job duration.
  // it return a list of bookingDatas
  getAvailability(resourceList, bookingDatas, from, duration) {
    var fromDate = new Date(from);
    var toDate = new Date(fromDate.getTime() + 24 * 60 * 60 * 1000 * 9);
    // console.log('from:', from)
    // console.log('toDate:', toDate)
    const to = toDate.toISOString().substring(0, 10);
    // console.log('to:', to)

    var pictureUrl = "";

    // We clean the table of booking
    bookingDatas.splice(0, bookingDatas.length);
    // console.log('resourceList')
    // console.log(resourceList)

    if (resourceList.length === 0) {
      return;
    }
    var availability = [];
    for (var i = 0; i < resourceList.length; i++) {
      availability = this.computeAvailabilityForOneResource(
        resourceList[i],
        from,
        to,
        duration,
      );
      // console.log('availability:')
      // console.log(availability)
      // Now we are computing the Booking data for this ressource:
      if (
        Object.keys(availability).length !== 0 &&
        availability.constructor === Object
      ) {
        if (resourceList[i].pictureUrl !== null) {
          pictureUrl = resourceList[i].pictureUrl;
        } else {
          // On the other side we will show: <v-img :src="require('@/assets/Avatar-02.svg')" />
          pictureUrl = "";
          // pictureUrl = 'https://www.freeiconspng.com/thumbs/person-icon/clipart--person-icon--cliparts-15.png'
        }
        bookingDatas.push({
          id: resourceList[i].id,
          name: resourceList[i].firstname,
          shortName:
            resourceList[i].firstname.substr(0, 1) +
            resourceList[i].lastname.substr(0, 1),
          img: pictureUrl,
          availabilities: availability,
        });
      }
    }
    // console.log('bookingDatas:')
    // console.log(bookingDatas)
  }

  /*
  getAvailability1 () {
    var schedule = {
      Monday: {
        from: '09:00',
        to: '17:00',
      },
      Tuesday: {
        from: '09:00',
        to: '17:00',
      },
      Wednesday: {
        from: '09:00',
        to: '17:00',
      },
      Thursday: {
        from: '09:00',
        to: '17:00',
      },
      Friday: {
        from: '09:00',
        to: '17:00',
      },
      unavailability: [
        { from: '2021-11-15T00:00', to: '2021-11-16T00:00' },
        { date: '2021-11-12', from: '14:00', to: '18:00' },
      ],
    }

    var availability = this.scheduler.getAvailability({
      from: '2021-11-08',
      to: '2021-12-21',
      duration: 60,
      interval: 60,
      schedule: schedule,
    })

    // We are getting the availability for this ressource using the schedule built and the param & the scheduler library
    console.log(' ---------availability1:')
    console.log(availability)
  }
  */
  /*
  getAvailability2 () {
    var schedule1 = {
      monday: {
        from: '09:00',
        to: '17:00',
      },
      tuesday: {
        from: '09:00',
        to: '17:00',
      },
      wednesday: {
        from: '09:00',
        to: '17:00',
      },
      thursday: {
        from: '09:00',
        to: '17:00',
      },
      friday: {
        from: '09:00',
        to: '17:00',
      },
    }
    var schedule2 = {

    }
    const availability = this.scheduler.getAvailability({
      from: '2017-02-01',
      to: '2017-03-01',
      duration: 60,
      interval: 60,
      schedule: {
          weekdays: {
              from: '09:00',
              to: '17:00',
              unavailability: [
                  { from: '12:00', to: '13:00' },
              ],
          },
          unavailability: [
              // two different types of unavailability structure
              { from: '2017-02-20 00:00', to: '2017-02-27 00:00' },
              { date: '2017-02-15', from: '13:00', to: '18:00' },
          ],
          allocated: [
            { from: '2017-02-01 13:00', duration: 60 },
            { from: '2017-02-01 14:00', duration: 60 },
          ],
      },
    })

  console.log(' ---------availability2:')
  console.log(availability)
  }
  */
}

// =========================================================
// Notes FOR IMPLEMENTING THE LIB
// =========================================================
/*
      var availability = this.scheduler.getAvailability({
        from: '2017-02-01',
        to: '2017-03-01',
        duration: 30,
        interval: 15,
        schedule: {
          weekdays: {
            from: '09:00',
            to: '17:00',
            unavailability: [
              { from: '12:00', to: '13:00' },
            ],
          },
          unavailability: [
            // two different types of unavailability structure
            { from: '2017-02-20 00:00', to: '2017-02-27 00:00' },
            { date: '2017-02-15', from: '12:00', to: '13:00' },
          ],
          allocated: [
            { from: '2017-02-01 13:00', duration: 60 },
            { from: '2017-02-01 14:00', duration: 60 },
          ],
        },
      })

      var availability = this.scheduler.getIntersection({
        from: '2017-02-01',
        to: '2017-03-01',
        duration: 60,
        interval: 60,
        schedules: [
          // The doctor's schedule
          {
            weekdays: {
              from: '09:00',
              to: '17:00',
              unavailability: [
                { from: '12:00', to: '13:00' },
              ],
            },
            unavailability: [
              { from: '2017-02-20 00:00', to: '2017-02-27 00:00' },
            ],
            allocated: [
              { from: '2017-02-01 13:00', duration: 60 },
              { from: '2017-02-01 14:00', duration: 60 },
            ],
          },
          // The room's schedule
          {
            weekdays: {
              from: '08:00', to: '20:00',
            },
            unavailability: [
              { from: '2017-02-06 00:00', to: '2017-02-16 00:00' },
            ],
          },
        ],
      })
      // console.log('availability:', availability)
      */

/*
      Another way to make it work by using the days
      We need to add the day only if it is filled, if not this this not ok.
          var availability = this.scheduler.getAvailability({
      from: '2021-11-08',
      to: '2021-11-21',
      duration: 60,
      interval: 60,
      schedule: {
          monday: {
            from: '09:00',
            to: '17:00',
            unavailability: [
              { from: '12:00', to: '13:00' },
            ],
          },
          tuesday: {
            from: '09:00',
            to: '17:00',
            unavailability: [
              { from: '12:00', to: '13:00' },
            ],
          },
          custom_schedule: [
            { date: '2017-01-23', from: '12:00', to: '17:00' },
          ],
          unavailability: [
            { from: '2021-11-09 00:00', to: '2021-11-10 00:00' },
          ],
          allocated: [
            { from: '2021-11-08 10:00', duration: 60 },
            { from: '2021-11-16 10:00', duration: 120 },
          ],
      },
    })
    console.log('availability:')
    console.log(availability)
      */
