<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to define the list of services to be used for a specific ressource.
// Props:
//  - ressourceid: contains an id to identify the ressource.
// Example of usage:
      <kdw-dialog-resource-sub-resource-services
        :ressourceid="resource.id"
      />
with an example of the variable to use:
// ------------------------------------------------------------------------------>

<template>
  <v-container>
    <!----- This is a dialog box to add the service  --->
    <v-dialog v-model="dialogAddService" max-width="500px">
      <v-card>
        <v-card-title>
          <base-info-card
            :title="$t('ressourceService.dialogRessourceService')"
            color="primary"
          />
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="(serviceToAdd, i) in serviceResources.serviceCanAdd"
            :key="i"
            justify="space-between"
          >
            <span>{{ serviceToAdd.name }}</span>
            <v-checkbox
              v-model="serviceToAdd.add"
              @click="serviceToAddChecked(i)"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn text @click="closeServiceDialog()">
            {{ $t("button.cancel") }}
          </kdw-btn>
          <kdw-btn @click="validateServiceDialog()">
            {{ $t("button.validateQuestion") }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---- This is the header of the component: buttons to add ranges of day off  --------------------------------------------->
    <!-- class="grey white--text mt-1" -->
    <v-row
      v-for="(service, i) in serviceResources.serviceLinkedRessource"
      :key="i"
      justify="space-between"
      dense
    >
      <v-col>
        <span class="ml-3">{{ service.name }}</span>
      </v-col>
      <v-col>
        <v-row justify="end">
          <kdw-icon-action-tooltip
            min-width="auto"
            tipkey="ressourceService.deleteService"
            @click="deleteService(i)"
          >
            mdi-delete
          </kdw-icon-action-tooltip>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="end" dense>
      <kdw-icon-action-tooltip
        min-width="auto"
        tipkey="button.addServiceInRessource"
        @click="openServiceDialog()"
      >
        mdi-plus-circle
      </kdw-icon-action-tooltip>
    </v-row>
  </v-container>
</template>

<script>
import { ServiceResources } from "@/services/ServiceResources";
export default {
  name: "RessourceService",

  props: {
    ressourceid: {
      type: String,
      default: "",
    },
  },

  data: () => {
    return {
      // to ON/OFF the dialog to add a service
      dialogAddService: false,
      serviceResources: new ServiceResources(),
    };
  },

  created: function () {
    this.serviceResources.ressourceIdLocal = this.ressourceid;
    this.serviceResources.fetch();
  },

  methods: {
    emitResult() {
      this.$emit("input", this.dayOffs);
    },

    openServiceDialog() {
      this.dialogAddService = true;
    },

    closeServiceDialog() {
      this.dialogAddService = false;
    },
    validateServiceDialog() {
      this.serviceResources.addSelectedServices().then(() => {
        // We update the current parameters.
        this.$root.kdwsnackbar.alert("ressourceService.alertSucessCreateRes");
        this.serviceResources.fetch();
      });
      this.closeServiceDialog();
    },
    serviceToAddChecked(index) {
      this.serviceResources.serviceCanAdd[index].add = true;
    },
    deleteService(index) {
      this.serviceResources.deleteService(index).then(() => {
        // We update the current parameters.
        this.serviceResources.fetch();
      });
    },
  },
};
</script>
