<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component used to display a phone number with flags. Based on Vue Tel Input component. Adding the slected option and the management of the verification of the number.
// v-model is passed on the parents.
// Props:
// - selectId: selector of the phone number. Optional, default value is: IDphone
// - disabled: to check if the input is disabled or not. By default, it is not.
// - vif: to check of the component is displayed or not. By default, it is true so displayed.
// Events:
// - internationalPhoneNumber returns a string with the international phone number.
  <kdw-vue-tel-input
    v-model="myServiceProvider.contact.phonecpy"
    select-id="IDcpyphone"
    @internationalPhoneNumber="internationalPhoneNumberFct($event)"
  />
// ------------------------------------------------------------------------------>
<!-- great doc about two way binding with components https://www.digitalocean.com/community/tutorials/vuejs-add-v-model-support -->
<template>
  <v-input
    v-if="vif"
    prepend-icon="mdi-phone"
    :rules="[() => isValid || $t('identity.phoneFormat')]"
    :error="!isValid"
    persistent-hint
    :disabled="disabled"
  >
    <vue-tel-input
      :input-options="{ id: selectId, placeholder: $t('identity.phone') }"
      auto-format:="true"
      default-country="FR"
      mode="national"
      :preferred-countries="['FR']"
      :dropdown-options="{
        showDialCodeInSelection: true,
        showFlags: true,
        width: '390px',
      }"
      v-bind="$attrs"
      @input="setNumber"
      v-on="$listeners"
    />
    <!-- auto-default-country:="false" -->
    <!-- TODO use something from browser (locale?) to set defaultCountry -->
  </v-input>
</template>

<script>
// lib pulled by vue-tel-input
// import { parsePhoneNumberFromString } from "libphonenumber-js";
export default {
  name: "KdwVueTelInput",
  props: {
    selectId: {
      type: String,
      default: "IDphone",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vif: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    // value is given from parent component
    // lets format it to national format
    return {
      // internal: '', // parsePhoneNumberFromString(this.value)?.formatNational(),
      isValid: false,
      // id: this.selectId,
      /*
        bindProps = {
          mode: 'national',
          defaultCountry: 'FR',
          disabledFetchingCountry: false,
          disabled: false,
          disabledFormatting: false,
          placeholder: 'Enter a phone number',
          required: false,
          enabledCountryCode: true,
          enabledFlags: true,
          preferredCountries: [],
          onlyCountries: [],
          ignoredCountries: [],
          autocomplete: 'on',
          name: 'telephone',
          maxLen: 25,
          wrapperClasses: '',
          inputClasses: '',
          // dropdownOptions: {
          //   disabledDialCode: true,
          //  showflags: true,
          //  showDialCodeInList: true,
          // },
          inputOptions: {
            showDialCode: false,
          },
        }
        */
    };
  },
  watch: {
    /*
      value (val) {
        // value is given from parent component
        if (val) {
          // lets format it to national format
          this.internal = parsePhoneNumberFromString(val)?.formatNational()
        }
      },
      */
    /*
      internal (val) {
        if (val) {
          // console.log('val is ', val)
          this.internal = val
        }
      },
      */
  },
  created: function () {
    // console.log('this.value:')
    // console.log(this.value)
    // console.log('result:', parsePhoneNumberFromString(this.value)?.formatNational())
    // this.internal = parsePhoneNumberFromString(this.value)?.formatNational()
  },
  methods: {
    setNumber: function (number, phoneObject) {
      // this.internal = phoneObject.formatted
      this.isValid = phoneObject.valid;
      this.$emit("internationalPhoneNumber", phoneObject.number);
    },
  },
};
// <style src="vue-tel-input/dist/vue-tel-input.css"></style>
</script>
