<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component a the display of a specific ressource. It manages the ressource name, login, worktime, day off, etc.
// Props:
// - v-model: contains the list of ressources [Ressource1 object, Ressource2 object]
// - index: contains the Ressource number in this table of v-model
// - deleted-possible: define if the button delete should be appearing or not. This is optional. By default it is displayed.
// - service-displayed: Do we want to adisplay the service section. This is optional. By default it is displayed.
// - external-access-displayed: display or not the check-box to allow the external access of the ressource. This is optional. By default it is displayed.
// - online-access-displayed: display or not the check-box to allow the online access of the ressource. This is optional. By default it is displayed.
// Events:
// - deleteRessource is triggered when a request is done to delete this ressource. The delete is performed at the parent level.<template>
// - updateResource is triggered when the end user is asking to save the ressource new parameters. This event is returning a Ressource object to be used by the parent to update the table.
    <kdw-dialog-resource
      :key="'ressource' + refreshFront"
      v-model="myResourcesList.list"
      :index="r"
      :deleted-possible="false"
      :service-displayed="false"
      :external-access-displayed="false"
      :online-access-displayed="false"
      @deleteResource="deleteResource(r)"
      @updateResource="updateResource(r, $event)"
    />
// ------------------------------------------------------------------------------>

<template>
  <v-container>
    <!----   Dialog box to confirm the delete of the Ressources ---------------------------->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text class="hidden-sm-and-down">
          <kdw-avatar-card
            :key="'myKey1'"
            :icon="'mdi-help-circle-outline'"
            :outlined="false"
            title="ressources.deleteQuestion"
            color="transparent"
            horizontal
            space="0"
          />
        </v-card-text>
        <v-card-text class="hidden-md-and-up">
          <kdw-avatar-card
            :key="'myKey1'"
            :outlined="false"
            title="ressources.deleteQuestion"
            color="transparent"
            horizontal
            space="0"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn id="cancelDeleteResource" text outlined @click="closeDelete">
            {{ $t("button.cancel") }}
          </kdw-btn>
          <v-spacer />
          <kdw-btn id="confirmDeleteResource" @click="deleteItemConfirm">
            {{ $t("button.validateQuestion") }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!------- Start of the component display ------->
    <v-card class="rounded-xl mx-auto" max-width="1200">
      <v-system-bar color="jean" class="grey--text text--lighten-3">
        <v-icon class="ml-3 grey--text text--lighten-3">
          mdi-account-key
        </v-icon>
        <span class="font-weight-bold ml-2">
          {{ resource.firstname }} {{ resource.lastname.toUpperCase() }}
        </span>
        <v-spacer />
        <!-- TODO review colors here -->
        <kdw-icon-action-tooltip
          min-width="auto"
          color="#EEEEEE"
          tipkey="tooltip.saveRessource"
          @click="updateResource()"
        >
          mdi-content-save
        </kdw-icon-action-tooltip>
        <kdw-icon-action-tooltip
          v-if="deletedPossible"
          color="#EEEEEE"
          min-width="auto"
          tipkey="tooltip.deleteRessource"
          @click="deleteItem()"
        >
          mdi-delete
        </kdw-icon-action-tooltip>
      </v-system-bar>
      <!--------------- Details of the ressource --------------------------------------->
      <v-card-actions>
        <kdw-btn
          min-width="auto"
          class="ml-2"
          text
          color="jean"
          @click="extendDetails = !extendDetails"
        >
          {{ $t("ressources.details") }}
        </kdw-btn>
        <v-spacer />
        <v-btn icon @click="extendDetails = !extendDetails">
          <v-icon>
            {{ extendDetails ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="extendDetails">
          <base-divider color="candy" dense space="1" />
          <v-card>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-col cols="12" sm="12">
                    <kdw-text-field
                      :id="'IDfirstname' + index"
                      :key="'IDfirstname' + index"
                      v-model="resource.firstname"
                      class="mt-3"
                      :label="$t('identity.firstname') + $t('meta.star')"
                      prepend-icon="mdi-account-circle"
                    />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <kdw-text-field
                      :id="'IDlastname' + index"
                      :key="'IDlastname' + index"
                      v-model="resource.lastname"
                      :label="$t('identity.lastname') + $t('meta.star')"
                      prepend-icon="mdi-account-circle"
                    />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <kdw-text-field-email
                      id="IDcpymail"
                      :key="'IDcpymail' + index"
                      v-model="resource.email"
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" sm="6">
                  <kdw-picture-selector
                    v-model="resource.logo"
                    nature="Picture"
                    :display="trickUpdatePicture"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-col>
                    <kdw-vue-tel-input
                      v-model="resource.phone"
                      @internationalPhoneNumber="
                        internationalPhoneNumberFct($event)
                      "
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-col>
                    <kdw-text-field
                      id="IDinterval"
                      :key="'IDinterval' + index"
                      v-model="resource.interval"
                      type="number"
                      :label="$t('ressources.interval') + $t('meta.star')"
                      :hint="$t('ressources.interval_hint')"
                      prepend-icon="mdi-cog"
                    />
                  </v-col>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <v-col>
                    <v-checkbox
                      v-show="onlineAccessDisplayed"
                      id="IDbookingVisible"
                      :key="'IDbookingVisible' + index"
                      v-model="resource.canBookOnline"
                      class="ml-1"
                      :label="$t('ressources.bookingExternally')"
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-col>
                    <v-checkbox
                      v-show="externalAccessDisplayed"
                      id="IDexternalVisible"
                      :key="'IDexternalVisible' + index"
                      v-model="resource.webSiteAccess"
                      class="ml-1"
                      :label="$t('ressources.visibleExternally')"
                    />
                  </v-col>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-col>
                    <kdw-text-field
                      v-if="resource.webSiteAccess"
                      id="IDlogin"
                      :key="'IDlogin' + index"
                      v-model="resource.login"
                      :disabled="true"
                      :label="$t('ressources.idLogin')"
                      prepend-icon="mdi-login"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-expand-transition>
      <!--------------- Details of the Week work day --------------------------------------->
      <v-card-actions>
        <kdw-btn
          min-width="auto"
          class="ml-2"
          text
          color="jean"
          @click="extendWorkingHours = !extendWorkingHours"
        >
          {{ $t("ressources.workhours") }}
        </kdw-btn>
        <v-spacer />
        <v-btn icon @click="extendWorkingHours = !extendWorkingHours">
          <v-icon>
            {{ extendWorkingHours ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="extendWorkingHours">
          <base-divider color="candy" dense space="1" />
          <v-card>
            <kdw-dialog-resource-sub-worktime-Week
              :key="'weekWorkingDays' + internalIndex"
              v-model="resource.weekWorkingDays"
              :ressourceid="resource.id"
            />
          </v-card>
        </div>
      </v-expand-transition>
      <!--------------- Details of the Day off --------------------------------------->
      <v-card-actions>
        <kdw-btn
          min-width="auto"
          class="ml-2"
          text
          color="jean"
          @click="extendDayOff = !extendDayOff"
        >
          {{ $t("ressources.dayOff") }}
        </kdw-btn>
        <v-spacer />
        <v-btn icon @click="extendDayOff = !extendDayOff">
          <v-icon>
            {{ extendDayOff ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="extendDayOff">
          <base-divider color="candy" dense space="1" />
          <v-card>
            <kdw-dialog-resource-sub-day-Off
              :key="'dayOffList' + internalIndex"
              v-model="resource.dayOffList"
              :ressourceid="resource.id"
            />
          </v-card>
        </div>
      </v-expand-transition>
      <!--------------- Details of the Services used by the ressources --------------------------------------->
      <v-card-actions v-show="serviceDisplayed">
        <kdw-btn
          min-width="auto"
          class="ml-2"
          text
          color="jean"
          @click="extendService = !extendService"
        >
          {{ $t("ressources.services") }}
        </kdw-btn>
        <v-spacer />
        <v-btn icon @click="extendService = !extendService">
          <v-icon>
            {{ extendService ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="extendService">
          <base-divider color="candy" dense space="1" />
          <v-card>
            <kdw-dialog-resource-sub-resource-services
              :ressourceid="resource.id"
            />
          </v-card>
        </div>
      </v-expand-transition>
      <!--------------- Bottom of the card --------------------------------------->
      <v-card-actions>
        <v-divider />
      </v-card-actions>
      <v-card-actions>
        <!-- color="orange lighten-2" -->
        <kdw-btn
          v-show="deletedPossible"
          :id="'deleteResourceN' + index"
          color="jean"
          dark
          @click="deleteItem()"
        >
          <v-icon left> mdi-delete </v-icon>
          {{ $t("button.deleteRequestEU") }}
        </kdw-btn>
        <v-spacer />
        <kdw-btn
          :id="'saveResourceN' + index"
          color="jean"
          dark
          @click="updateResource()"
        >
          <v-icon left> mdi-content-save </v-icon>
          {{ $t("button.saveRessource") }}
        </kdw-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { Resource } from "@/services/Resource";
import { FILEHEADERPICTURERESSOURCE } from "@/services/File";

export default {
  name: "ResourceDisplay",
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: [Number, String],
      default: 0,
    },
    deletedPossible: {
      type: Boolean,
      default: true,
    },
    serviceDisplayed: {
      type: Boolean,
      default: true,
    },
    externalAccessDisplayed: {
      type: Boolean,
      default: true,
    },
    onlineAccessDisplayed: {
      type: Boolean,
      default: true,
    },
  },

  data: () => {
    return {
      resource: new Resource(),
      internalIndex: 0,
      extendDetails: false,
      extendWorkingHours: false,
      extendDayOff: false,
      extendService: false,
      dialogDelete: false,

      trickUpdatePicture: 0,
    };
  },

  watch: {
    value: function (newValue) {
      // We are checking to see if a delete of a ressource happened
      if (newValue.id !== this.resource.id) {
        this.internalIndex = this.index;
        // We copy this ressource to manage the display.
        this.resource = new Resource();
        this.resource.copy(newValue);
      }
    },
  },

  created: function () {
    this.internalIndex = this.index;
    // We copy this ressource to manage the display.
    this.resource.copy(this.value);
    // console.log('this.resource:')
    // console.log(this.resource)
    this.trickUpdatePicture++;
  },

  methods: {
    internationalPhoneNumberFct(internationPhoneNumber) {
      // console.log('intPhoneNumber:', internationPhoneNumber)
      this.resource.phoneInt = internationPhoneNumber;
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    deleteItem() {
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // This is confirmed so we can take care of deleting the ressource
      // We are emiting a proprietary event to the parent component
      this.$emit("deleteResource");
      this.closeDelete();
    },

    updateResource() {
      // This is to confirm that we are updating the ressource
      // We are emiting a proprietary event to the parent component
      this.$emit("updateResource", this.resource);
    },
    previewImage() {
      this.resource.pictureUrl = URL.createObjectURL(
        this.resource.pictureObject,
      );
      this.resource.pictureName =
        this.resource.id +
        FILEHEADERPICTURERESSOURCE +
        this.resource.pictureObject.name;
      this.resource.pictureChange = true;
    },
    deleteLogoClick() {
      this.resource.pictureUrl = null;
      this.resource.pictureObject = null;
      this.resource.pictureName = "";
      this.resource.pictureChange = true;
    },
  },
};
</script>
