<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This is 2 avatar. The internal one is containing a logo (mdi) and the external one is changing color when we are passing above it.
// Props:
//  - color: default color of the external avatar (white is default)
//  - colorHover: color of the external avatar when we are hovering the (default is primary)
//  - avatarColor: color of the internal avatar when we are hovering the (default is primary)
//  - mdi: mdi (logo) to be used in the middle of internal avatar (default is mdi-account-circle). List is here: https://pictogrammers.github.io/@mdi/font/2.0.46/
// Example of usage:
      <miniweb-btn-deco-hover />
// ------------------------------------------------------------------------------>
<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      elevation="15"
      class="ma-5"
      fab
      large
      :style="{ 'background-color': hover ? colorHover : color }"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-avatar :color="avatarColor">
        <v-icon :color="color">
          {{ mdi }}
        </v-icon>
      </v-avatar>
      <slot />
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "BtnDecoHover",

  props: {
    color: {
      type: String,
      default: "white",
    },
    colorHover: {
      type: String,
      default: "primary",
    },
    avatarColor: {
      type: String,
      default: "primary",
    },
    mdi: {
      type: String,
      default: "mdi-account-circle",
    },
  },
};
</script>
