import QnA from "@/services/QnA";
import { Logger } from "aws-amplify";
import { RequestService } from "@/services/Request";
import { getUserSubSync as getUserSub } from "@/services/User";
import { navigate } from "@/helpers/Navigation";
import {
  fetchASubCategoryReturnQuestion,
  fetchACategoryReturnQuestion,
  fetchASubCategory,
} from "@/services/Category";
import { dealWithBookingFirst } from "./Booking";
import { createAppointment } from "@/services/Appointment";

const logger = new Logger("QuestionsController");

var readyItems = [];

// Enduser or Anonymous selected a Service
export function pickItem(obj, item, postalCodeId, locale) {
  // console.log('pickItem in Question')
  // console.log(item)
  obj.pickedItem = Object.assign({}, item);
  // LETSWAIT is a trick used by handleSignIn from login.vue to allow the backend update to finish first.

  // We are managing the special case of the booking (for fixed price).
  if (item.isFixedPrice && item.onlineBooking) {
    obj.bookingOnlineFromHero = true;
    dealWithBookingFirst(obj, item);
    // console.log('We are returning')
    return;
  }

  // Magic trick
  obj.navigateNext = "LETSWAIT";
  // We managed the other cases (excluding fixed price booking)
  if (item.isFixedPrice) {
    // logger.info('FixedPrice item selected ' + JSON.stringify(item))
    const ProcessCreateFixedPriceRequest = () => {
      const userSub = getUserSub();
      // We need to create this request in DB.
      var myRequestService = new RequestService();
      myRequestService
        .createFixedPriceRequest(userSub, item)
        .then((result) => {
          obj.dialogAuth = false;
          if (item.onlineBooking) {
            // removed -- dealWithBooking(obj, item, result)
          } else {
            // We are going to the service page
            navigate(obj, "EndUserMyServices");
          }
        })
        .catch((err) => {
          logger.error(err);
          return false;
        });
    };
    // We check if the user is already logged or not.
    if (obj.userType !== obj.ENDUSER) {
      // obj.questions = obj.defaultItem
      obj.question = {};
      obj.dialogAuth = true;
      readyItems.push(ProcessCreateFixedPriceRequest);
    } else {
      ProcessCreateFixedPriceRequest();
    }
  } else {
    // This is a QUOTATION based request.
    // logger.info('Quotation based item selected ' + JSON.stringify(item))
    obj.qna = new QnA();
    console.log("We are in the questions part");
    obj.questions = obj.qna.fetchQuestions(item, locale);
    console.log("We fetched the questions");
    obj.postalCodeId = postalCodeId;
    // logger.info('Found questions ' + JSON.stringify(obj.questions))
    obj.questions.category = item.category;
    obj.questions.subCategory = item.subCategory;
    obj.stepper = 1;
    console.log("We display the questions");
    obj.dialogQuestions = true;
  }
}

// Enduser or Anonymous selected a Service from Hero page - Case Booking service on fixedPrice
export function pickItemFixedPriceBookingFromHero(obj, item, appointmentData) {
  // console.log('Appointment data in pickItemFixedPriceBookingFromHero -v1')
  // console.log(appointmentData)
  // LETSWAIT is a trick used by handleSignIn from login.vue to allow the backend update to finish first.
  obj.navigateNext = "LETSWAIT";
  // We managed the other cases (excluding fixed price booking)
  if (item.isFixedPrice) {
    // logger.info('FixedPrice item selected ' + JSON.stringify(item))
    const ProcessCreateFixedPriceRequest = () => {
      const userSub = getUserSub();
      console.log("START ProcessCreateFixedPriceRequest ---------", userSub);
      // We need to create this request in DB.
      var myRequestService = new RequestService();
      const appointmentDateTime = {
        date: appointmentData.selectedSlot.date,
        time: appointmentData.selectedSlot.time,
      };
      // console.log('appointmentDateTime in pickItemFixedPriceBookingFromHero')
      // console.log(appointmentDateTime)
      myRequestService
        .createFixedPriceRequest(userSub, item, appointmentDateTime)
        .then((result) => {
          // HERE WE WILL NEED TO CREATE THE APPOINTMENT ALSO
          // We need to collect the answerID created.
          // console.log('result createFixedPriceRequest:')
          // console.log(result)
          appointmentData.answerId = result;
          // Then we can create the appointment.
          // console.log('Appointment data in pickItemFixedPriceBookingFromHero-v2')
          // console.log(appointmentData)
          createAppointment(
            appointmentData.selectedSlot,
            appointmentData.serviceId,
            appointmentData.serviceName,
            appointmentData.serviceDuration,
            appointmentData.resourceList.list,
            appointmentData.scheduler,
            appointmentData.answerId,
            userSub,
          ).then((result) => {
            obj.dialogAuth = false;
            // We are going to the service page
            navigate(obj, "EndUserMyServices");
          });
        })
        .catch((err) => {
          logger.error(err);
          return false;
        });
    };
    // We check if the user is already logged or not.
    if (obj.userType !== obj.ENDUSER) {
      obj.question = {};
      obj.dialogAuth = true;
      readyItems.push(ProcessCreateFixedPriceRequest);
    } else {
      ProcessCreateFixedPriceRequest();
    }
  }
}

async function asyncFetchQuestions(categoryId, subCategoryId, catName, locale) {
  // TODO would be great merge all 3x fetchQuestions functions
  // - one from Hero
  // - one from QnA
  // - one here
  // console.log(categoryId)
  // console.log(subCategoryId)
  // console.log('obj.questions avant')
  try {
    const item = {
      isFixedPrice: false,
      subCategoryID: subCategoryId,
      categoryID: categoryId,
      name: catName,
    };

    // logger.info('No service found, we are building the request.')
    // We are fetching the question from the sub category.

    var localQuestion = "";
    if (subCategoryId !== "") {
      localQuestion = await fetchASubCategoryReturnQuestion(
        subCategoryId,
        locale,
      );
    }
    // console.log('localQuestion after sub cat')
    // console.log(localQuestion)

    // We are fetching the question from the category.
    if (localQuestion === "") {
      localQuestion = await fetchACategoryReturnQuestion(categoryId, locale);
    }

    // console.log('localQuestion after cat')
    // console.log(localQuestion)

    item.question = localQuestion;
    return item;
  } catch (err) {
    console.log(err);
  }
}

export function noServiceBuildRequestBasedQuestion(
  obj,
  categoryId,
  subCategoryId,
  postalCodeId,
  catName,
  locale,
) {
  // console.log('noServiceBuildRequestBasedQuestion')
  // console.log(obj)
  // console.log(categoryId)
  // console.log(subCategoryId)
  // console.log(postalCodeId)
  // console.log(catName)
  // console.log(locale)
  try {
    asyncFetchQuestions(categoryId, subCategoryId, catName, locale).then(
      (item) => {
        // console.log('asyncFetchQuestions passed')
        obj.pickedItem = Object.assign({}, item);
        obj.qna = new QnA();
        // console.log('qna is instanciated')
        obj.questions = obj.qna.fetchQuestions(item, locale);
        obj.postalCodeId = postalCodeId;
        // logger.info('Found questions ' + JSON.stringify(obj.questions))
        obj.questions.category = catName;
        fetchASubCategory(subCategoryId).then((subCatName) => {
          obj.questions.subCategory = subCatName;
        });
        obj.stepper = 1;
        obj.dialogQuestions = true;
        // console.log('asyncFetchQuestions end')
      },
    );
  } catch (err) {
    console.log(err);
  }
}

// Anonymous became an EndUser and we persist previously selected Service(s)
export function clearPersistQuestions() {
  readyItems = [];
}

export function isPersistQuestionsEmpty() {
  if (readyItems.length === 0) {
    return true;
  }
  return false;
}

// Anonymous became an EndUser and we persist previously selected Service(s)
export function persistQuestions(obj) {
  // console.log('readyItems:')
  // console.log(readyItems)
  for (var item of readyItems) {
    // TODO should we deal with only 1 item ? --> This is managed by the flushing at the end of the function.

    item();
  }
  // After execution, we flush the list of points
  readyItems = [];
  // console.log('readyItems after flush:')
  // console.log(readyItems)
}

// Anonymous or EndUser press Cancel button
export function cancelQuestions(obj) {
  obj.dialogQuestions = false;
  obj.dialogAuth = false;
  obj.editedItem = obj.defaultItem;

  // uncommenting following line means horrible things happening !
  // obj.questions = obj.defaultItem
  obj.question = {};
  obj.stepper = 0;
  clearPersistQuestions();
}

// Retrieve inputs from Anonymous or EndUser
export function getAnswerFromUser(obj) {
  var value = null;
  if (obj.question.type === "MultipleSelect") {
    value = obj.valueMultipleSelect.toString();
  } else if (obj.question.type === "OneSelect") {
    value = obj.valueOneSelect;
  } else if (["InputText", "InputNumber"].includes(obj.question.type)) {
    value = obj.valueInputText;
    // logger.info('answer is ' + JSON.stringify(value))
  }
  return value || obj.question.default;
}

// Set default answers or previous inputs to Anonymous or EndUser
export function setAnswerForUser(obj, value = null) {
  if (["MultipleSelect", "OneSelect"].includes(obj.question.type)) {
    // We clean the previous answers
    obj.itemsSelect.splice(0, obj.itemsSelect.length);
    // we populate with the new proposed answers
    for (var q of obj.question.answers) {
      obj.itemsSelect.push(q.text);
    }
  }
  if (obj.question.type === "MultipleSelect") {
    obj.valueMultipleSelect = value.split(",");
  }
  if (obj.question.type === "OneSelect") {
    obj.valueOneSelect = value;
  }
  if (["InputText", "InputNumber"].includes(obj.question.type)) {
    obj.valueInputText = value;
  }
}

// Moving to a new question - usually done by question id in the stepper component
export function nextStep(obj, n = null) {
  // logger.info('moving to step number ' + n + ' stepper is now ' + obj.stepper)
  if (obj.questions.question.length === 0) {
    // there is no questions to deal with
    // happens when a fixed price item is selected by an Anonymous
    return;
  }
  if ("id" in obj.question) {
    // logger.info('storing answer ' + obj.getAnswerFromUser())
    // We store the customer answers if we're not in initial state
    obj.qna.storeAnswers(obj.getAnswerFromUser());
  }

  // if n is provided and within boundaries
  // OR if n is not provided and current question is followed by another one
  if (
    (n && n > 0 && n <= obj.questions.question.length) ||
    (!n && obj.question.hasNext === true)
  ) {
    obj.question = obj.qna.forward(n) || obj.defaultItem;
    // logger.info('New question is ' + JSON.stringify(obj.question))

    // We validate that the end user never provided an answer
    if (obj.question.givenAnswer) {
      // The end user had an answer previously
      obj.setAnswerForUser(obj.question.givenAnswer);
    } else {
      // Setting the default value for the question
      obj.setAnswerForUser(obj.question.default);
    }
    // stepper may already be in good position
    obj.stepper = obj.question.index;
  } else if ((n && n > 0) || (!n && obj.question.hasNext === false)) {
    // we filter out the case when n = 0 happening on cancellation

    const jsonReport = JSON.stringify(obj.qna.getReport());
    // logger.info('And the report is........................... ' + jsonReport)
    // we copy the item
    const item = Object.assign({}, obj.pickedItem);
    // and reset old one to default (for the sake of not leaving a mess ?)
    obj.pickedItem = obj.defaultItem;

    // We verify if we have an empty item, and if this is the case, we it means that we are in the case of no answer, so we build a fake one to move forward.
    /*
      if ((item.questions.length === 0) && ( item.isFixedPrice === undefined)) {
        item.isFixedPrice = false
        item.subCategoryID =
        item.name =
        item.categoryID
      }
      */
    const ProcessCreateQuotationRequest = () => {
      const userSub = getUserSub();
      var myRequestService = new RequestService();
      myRequestService
        .createQuotationRequest(
          userSub,
          item,
          jsonReport,
          obj.postalCodeId,
          obj,
        )
        .then(() => {
          obj.dialogAuth = false;
          navigate(obj, "EndUserMyServices");
        })
        .catch((err) => {
          logger.error(err);
          return false;
        });
    };
    if (obj.userType === obj.ENDUSER) {
      ProcessCreateQuotationRequest();
    } else {
      // report is ready to be persisted upon successful EndUser signin
      // readyItems.slice(0, readyItems.length) // We clean the array first
      readyItems.push(ProcessCreateQuotationRequest);
      obj.navigateNext = "LETSWAIT";
      // we move to last step : authenticator
      obj.stepperFixed = "C";
      obj.dialogAuth = true;
      obj.dialogQuestions = false;
    }
  }
}

// Moving to a question - 1
export function previousStep(obj, n = null) {
  // logger.info('moving to step number ' + n + ' stepper is now ' + obj.stepper)
  // logger.info('storing answer ' + obj.getAnswerFromUser())
  // We store the customer answers
  obj.qna.storeAnswers(obj.getAnswerFromUser());

  obj.question = obj.qna.backward(n);

  // Setup the system with the previous answer
  obj.setAnswerForUser(obj.question.givenAnswer);

  obj.stepper = obj.question.index;
}
