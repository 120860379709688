<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This component is used to select the postal code, the towns, and the search radius before triggering a search
// Props:
// - dark: define of the theme is dark. This prop is optional and the default is true.
// Events:
// - localisationData: return an event to the parent with the following data: radius in km, id of the town selected, townName is the name of the town selected, latitude and longitude of the selected town. found indicates if a town is selected or not. This event is emitted all the time.
// Component:
    <kdw-postalcode-town
      :dark="true"
      @localisationData="localisationData($event)"
    />
// ------------------------------------------------------------------------------>

<template>
  <v-theme-provider :dark="dark">
    <div>
      <v-row>
        <v-col cols="12" sm="5" md="4" lg="4" xl="4">
          <v-autocomplete
            id="SelectedTown"
            v-model="selectedTown"
            :items="towns"
            :loading="isLoading"
            :search-input.sync="search"
            auto-select-first
            prepend-icon="mdi-magnify"
            hide-no-data
            clearable
            item-text="townName"
            item-value="number"
            :label="$t('hero.cityPostal')"
            @change="townChanged()"
          >
            <template #no-data />
            <!-- We decided to remove if no Data
            <template #no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('hero.city') }}
                  <strong>{{ $t('hero.cityPostal') }}</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
            -->
            <template id="townNameResultlist" #item="{ item }">
              <v-list-item-content :id="'townNameResult' + item.id">
                <v-list-item-title
                  :id="'townNameItem' + item.id"
                  v-text="item.townName"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-action>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="7" md="8" lg="8" xl="8">
          <v-slider
            v-model="radius"
            :tick-labels="distanceRange"
            color="primary"
            :max="7"
            step="1"
            tick-size="7"
            ticks="always"
            dense
            @change="radiusChanged()"
          />
          {{ $t("hero.radius") }}: {{ displayRadius() }} km
        </v-col>
      </v-row>
    </div>
  </v-theme-provider>
</template>

<script>
// import { fetchTownfromPostalCode, fetchTownfromPartialName } from '@/services/PostalCode'
import { getByCityPostalCode } from "@/services/commonTools";
import { normaliseTextFR } from "@/helpers/GenericFunctions";

export default {
  name: "PostalCodeTownSelection",

  props: {
    dark: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    // userLocationEntry: null,
    towns: [],
    distanceRange: ["0", "10", "20", "30", "50", "100", "200", "All"],
    radius: 4,
    selectedTown: "",

    isLoading: false,
    search: null,
    lastSearchedValue: "",
    // tab: null,
  }),

  watch: {
    /*
      model (val) {
        console.log('model Value:', val)
        if (val != null) this.tab = 0
        else this.tab = null
      },
      */
    search(val) {
      this.querySelections();
    },
  },

  methods: {
    querySelections() {
      this.isLoading = true;
      // Simulated ajax query
      setTimeout(() => {
        // console.log('search Value:', this.search)
        if (this.lastSearchedValue !== this.search) {
          this.lastSearchedValue = this.search;
          const val = this.search;
          // WE DO THE REAL SEARCHING JOB
          // We check if the input is empty
          if (val === null) {
            this.resetTown();
            this.emitResult();
            return;
          }
          // We check if the input is less than 3 characters (saving the number of request) and not part of special cases
          const minLength = 3;
          if (
            val.length < minLength &&
            ![
              "BY",
              "SY",
              "BU",
              "Y",
              "US",
              "GY",
              "UR",
              "EU",
              "UZ",
              "OZ",
              "RY",
              "OO",
              "PY",
              "RI",
            ].includes(val.toUpperCase())
          ) {
            this.resetTown();
            this.emitResult();
            return;
          }
          // if (this.items.length > 0) return
          // We go for search
          this.isLoading = true;
          if (isNaN(val) === false) {
            // This is a number
            if (val.length !== 5) {
              // the post code is not having the rigth size
              this.resetTown();
              this.emitResult();
              return;
            }
            // We have a real postal code to search
            getByCityPostalCode(val).then((result) => {
              // console.log(result)
              this.addtownResults(result);
              // console.log('this.towns')
              // console.log(this.towns)
              this.isLoading = false;
            });
          } else {
            // this is a town name with more than 3 charaters or a special case, which are are trying to search for
            // We normalise the request
            const normalisedRequest = normaliseTextFR(val);
            getByCityPostalCode(normalisedRequest).then((result) => {
              this.addtownResults(result);
              // console.log('this.towns')
              // console.log(this.towns)
              this.isLoading = false;
            });
          }
        }
        this.isLoading = false;
      }, 800);
    },
    // We reset the town data
    resetTown() {
      this.towns.splice(0, this.towns.length);
      // this.selectedTown = ''
    },

    // We emit the result to the parent
    emitResult() {
      var localisationData = {};
      // We check if a toan is selected
      if (this.selectedTown === "" || this.selectedTown === null) {
        localisationData.found = false;
      } else {
        localisationData.id = this.towns[this.selectedTown].id;
        localisationData.townName = this.towns[this.selectedTown].townName;
        localisationData.latitude = this.towns[this.selectedTown].latitude;
        localisationData.longitude = this.towns[this.selectedTown].longitude;
        localisationData.found = true;
      }
      // We prepare the radius data
      if (this.distanceRange[this.radius] === "All") {
        localisationData.radius = 2000;
      } else {
        localisationData.radius = parseInt(this.distanceRange[this.radius]);
      }
      this.$emit("localisationData", localisationData);
      this.isLoading = false;
    },
    setDefaultTown() {
      if (this.towns.length === 1) {
        this.selectedTown = this.towns[0].number;
      }
    },
    townChanged() {
      this.emitResult();
    },
    addtownResults(townListFound) {
      if (townListFound.length <= 0) {
        return;
      }
      // We sort the result table
      townListFound.sort(function (a, b) {
        var nameA = a.nom.toUpperCase(); // ignore upper and lowercase
        var nameB = b.nom.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      // We empty the existing table
      this.resetTown();
      // We fill the table with the proper results
      for (var i = 0; i < townListFound.length; i++) {
        this.towns.push({
          number: i,
          townName:
            townListFound[i].nom + " (" + townListFound[i].codesPostaux + ")",
          id: townListFound[i].id,
          latitude: townListFound[i].latitude,
          longitude: townListFound[i].longitude,
        });
      }
      // We define the value by default
      this.setDefaultTown();
      // We emit the result to the parent & stop the loading
      this.emitResult();
    },
    radiusChanged() {
      // console.log('rayon initial: ' + this.radius)
      // this.selectedRadius = this.displayRadius()
      this.emitResult();
    },
    displayRadius() {
      return this.distanceRange[this.radius];
    },
  },
};
</script>
