<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display the list of answers provided to the questions requested to the end user:
// Props:
// - questionItem: Array of object containing the list of question and their answers
//                the object is containing: {question, givenAnswer}
// Example of usage:
  <kdw-dialog-question-results
    v-model="dialogQuestions"
    :questionItem="questionItem"
  />
  with dialogQuestions that need to be set to true to display the Question list result
// ------------------------------------------------------------------------------>

<template>
  <v-dialog max-width="500px" v-bind="$attrs" v-on="$listeners">
    <v-card>
      <v-card-title>
        <base-info-card
          :title="$t('RequestEU.dialogQuestion')"
          color="primary"
        />
      </v-card-title>
      <v-card-text v-if="questionItem !== null">
        <v-list>
          <v-list-item
            v-for="myquestion in questionItem"
            :key="myquestion.question"
          >
            <v-list-item-content>
              <h3>{{ myquestion.question }}</h3>
              <p>{{ myquestion.givenAnswer }}</p>
              <!--
              <v-list-item-title v-text="myquestion.question"></v-list-item-title>
              <v-list-item-subtitle v-text="myquestion.givenAnswer"></v-list-item-subtitle>
              -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!--
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <ul id="kdw">
                <li
                  v-for="myquestion in questionItem"
                  :key="myquestion.question"
                >
                  <b> {{ myquestion.question }} </b> <br>
                  {{ myquestion.givenAnswer }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
        -->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <kdw-btn id="closeDialogButtonQuestions" @click="closeDialog">
          {{ $t("button.close") }}
        </kdw-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "KDWQuestionsResultDisplay",

  mixins: [Heading],

  props: {
    questionItem: {
      type: Array,
    },
  },

  data: () => {
    return {
      dialogStatus: false,
    };
  },
  created: function () {
    // console.log('this.$attr = ', this.$attrs)
    // console.log('this.$listeners = ', this.$listeners)
    // console.log(this.value)
    this.dialogStatus = false;
  },
  methods: {
    emitResult() {
      this.$emit("input", this.dialogStatus);
    },
    closeDialog() {
      this.dialogStatus = false;
      this.emitResult();
    },
  },
};
</script>
