<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display a title and an associated icon in a system bar
// Props:
//  - color: color of the system bar.
//  - icon: mid associated to the system bar
//  - titleTag: title to be translated and refering to the translation tag
// Example of usage:
      <kdw-title-card-system-bar
        color="jean"
        icon="mdi-card-account-details-outline"
        title-tag="AccountSP.mainTitle"
      />
// ------------------------------------------------------------------------------>
<template>
  <v-system-bar :color="color" class="grey--text text--lighten-3">
    <v-icon class="ml-3 grey--text text--lighten-3">
      {{ icon }}
    </v-icon>
    <span class="font-weight-bold ml-2">
      {{ $t(titleTag).toUpperCase() }}
    </span>
  </v-system-bar>
</template>

<script>
export default {
  name: "TitleSystemBar",

  props: {
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "mdi-card-account-details-outline",
    },
    titleTag: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
