<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to define the list of day off for a specific ressource. Range by Date (from date to another date) or Range by time (for a specific date, from this time to another time)
//            { from: '2017-02-20 00:00', to: '2017-02-27 00:00' },
//            { date: '2017-02-15', from: '12:00', to: '13:00' },
// Props:
//  - ressourceid: contains an id to identify the case of delete of a ressource.
// Example of usage:
      <kdw-dialog-resource-sub-day-Off
        v-model="dayOffList"
        :ressourceid="resource.id"
      />
with an example of the variable to use:
    dayOffList = [{ type: 'rangeByDate', startDate: '2021-09-01', endDate: '2021-09-10' },
                  { type: 'rangeBytime', startDate: '2021-09-01', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }]
// ------------------------------------------------------------------------------>

<template>
  <v-container>
    <!----- This is a dialog box to edit the Day Off when it is by date range  --->
    <v-dialog v-model="dialogEditSlotByDate" max-width="500px">
      <v-card>
        <v-card-title>
          <base-info-card
            :title="$t('dayOff.dialogEditDayOffTitleByDate')"
            color="primary"
          />
        </v-card-title>
        <v-card-text>
          <v-row class="black--text font-weight-bold">
            {{ $t("worktimeDay.start") }}
          </v-row>
          <v-row>
            <kdw-Date-Picker v-model="dayOffByDate.startDate" />
          </v-row>
          <v-row class="black--text font-weight-bold">
            {{ $t("worktimeDay.end") }}
          </v-row>
          <v-row>
            <kdw-Date-Picker v-model="dayOffByDate.endDate" />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn text @click="closeDialogEditSlotByDate()">
            {{ $t("button.cancel") }}
          </kdw-btn>
          <kdw-btn @click="saveDialogEditSlotByDate()">
            {{ $t("button.validateQuestion") }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!----- This is a dialog box to edit the Day Off when it is by time range on a specific date  --->
    <v-dialog v-model="dialogEditSlotByTime" max-width="500px">
      <v-card>
        <v-card-title>
          <base-info-card
            :title="$t('dayOff.dialogEditDayOffTitleByTime')"
            color="primary"
          />
        </v-card-title>
        <v-card-text>
          <v-row>
            <kdw-Date-Picker v-model="dayOffByTime.startDate" />
          </v-row>
          <v-row class="black--text font-weight-bold">
            {{ $t("worktimeDay.start") }}
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                key="startHH"
                v-model="dayOffByTime.startHH"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticHours"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-eight"
                :label="$t('serviceSP.DurationHH_hint')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                key="startMM"
                v-model="dayOffByTime.startMM"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticMinutes"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-eight"
                :label="$t('serviceSP.DurationMM_hint')"
              />
            </v-col>
          </v-row>
          <v-row class="black--text font-weight-bold">
            {{ $t("worktimeDay.end") }}
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                key="startHH"
                v-model="dayOffByTime.endHH"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticHours"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-five"
                :label="$t('serviceSP.DurationHH_hint')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                key="startMM"
                v-model="dayOffByTime.endMM"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticMinutes"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-five"
                :label="$t('serviceSP.DurationMM_hint')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn text @click="closeDialogEditSlotByTime()">
            {{ $t("button.cancel") }}
          </kdw-btn>
          <kdw-btn @click="saveDialogEditSlotByTime()">
            {{ $t("button.validateQuestion") }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---- This is the header of the component: buttons to add ranges of day off  --------------------------------------------->
    <!-- class="grey white--text mt-1" -->
    <v-row justify="end" dense>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            small
            rounded
            v-bind="attrs"
            v-on="on"
            @click="addDayOffByDateRange()"
          >
            +{{ $t("dayOff.byDaysPeriod") }}
          </v-btn>
        </template>
        <span>{{ $t("button.addDayOffByRangeDate") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            small
            rounded
            v-bind="attrs"
            v-on="on"
            @click="addDayOffByTimeRange()"
          >
            +{{ $t("dayOff.byTimePeriod") }}
          </v-btn>
        </template>
        <span>{{ $t("button.addDayOffByRangeTime") }}</span>
      </v-tooltip>
    </v-row>
    <!------ We display the list of slots  ------>
    <v-row
      v-for="(dayOff, i) in dayOffs"
      :key="i"
      justify="space-between"
      dense
    >
      <span>{{ getDayOffString(i) }}</span>
      <span>
        <kdw-icon-action-tooltip
          min-width="auto"
          tipkey="button.modifyWorkingSlot"
          @click="openDialogEditDayOff(dayOff, i)"
        >
          mdi-pencil
        </kdw-icon-action-tooltip>
        <kdw-icon-action-tooltip
          min-width="auto"
          tipkey="button.deleteWorkingSlot"
          @click="deleteDayOff(i)"
        >
          mdi-delete
        </kdw-icon-action-tooltip>
      </span>
    </v-row>
  </v-container>
</template>

<script>
import {
  buildMinutesTable,
  isTimeStartBiggerTimeEnd,
  isDateStartBiggerDateEnd,
} from "@/helpers/GenericFunctionsTime";
export default {
  name: "WorktimeDay",

  props: {
    value: {
      type: Array,
      required: true,
    },
    ressourceid: {
      type: String,
      default: "",
    },
  },

  data: () => {
    return {
      // for the menu of the date, by time
      menu: false,
      menu1: false,
      // list of the day off inherited from the parent
      dayOffs: [],
      // In case we are modifying a slot.
      dayOffByDate: {
        type: "rangeByDate",
        startDate: "2021-09-01",
        endDate: "2021-09-10",
        itemNumber: 0,
      },
      dayOffByTime: {
        type: "rangeBytime",
        startDate: "2021-09-01",
        startHH: "09",
        startMM: "00",
        endHH: "17",
        endMM: "00",
        itemNumber: 0,
      },
      // List of the time slot for the day we are trying to edit. Every slot in timsSlots is out of Slot nature with type='Working' or 'Break'
      timeSlots: [],
      // to ON/OFF the slot edition dialog using the slot data
      dialogEditSlotByTime: false,
      dialogEditSlotByDate: false,
      staticHours: [
        { value: "00" },
        { value: "01" },
        { value: "02" },
        { value: "03" },
        { value: "04" },
        { value: "05" },
        { value: "06" },
        { value: "07" },
        { value: "08" },
        { value: "09" },
        { value: "10" },
        { value: "11" },
        { value: "12" },
        { value: "13" },
        { value: "14" },
        { value: "15" },
        { value: "16" },
        { value: "17" },
        { value: "18" },
        { value: "19" },
        { value: "20" },
        { value: "21" },
        { value: "22" },
        { value: "23" },
      ],
      staticMinutes: [],
      ressourceIdLocal: "",
    };
  },

  computed: {
    reversedMessage: function () {
      // `this` points to the vm instance
      return this.message.split("").reverse().join("");
    },
  },

  watch: {
    value: function (newValue) {
      // We are checking to see if a delete of a ressource happened
      if (this.ressourceIdLocal !== this.ressourceid) {
        // console.log('delete dectected.........................')
        // We regenerate the content of the day off
        this.dayOffs = [...this.value];
        this.ressourceIdLocal = this.ressourceid;
      }
    },
  },

  created: function () {
    // console.log(this.value)
    this.dayOffs = [...this.value];
    buildMinutesTable(this.staticMinutes);
    // console.log('this.$attr = ', this.$attrs)
    // console.log('this.$listeners = ', this.$listeners)
    this.ressourceIdLocal = this.ressourceId;
  },

  methods: {
    emitResult() {
      this.$emit("input", this.dayOffs);
    },
    openDialogEditDayOff(dayOff, i) {
      if (dayOff.type === "rangeBytime") {
        // this.dayOffByTime.type = dayOff.type
        this.dayOffByTime.startDate = dayOff.startDate;
        this.dayOffByTime.startHH = dayOff.startHH;
        this.dayOffByTime.startMM = dayOff.startMM;
        this.dayOffByTime.endHH = dayOff.endHH;
        this.dayOffByTime.endMM = dayOff.endMM;
        this.dayOffByTime.itemNumber = i;
        this.dialogEditSlotByTime = true;
      } else {
        this.dialogEditSlotByDate = true;
        this.dayOffByDate.startDate = dayOff.startDate;
        this.dayOffByDate.endDate = dayOff.endDate;
        this.dayOffByDate.itemNumber = i;
      }
    },

    closeDialogEditSlotByTime() {
      this.dialogEditSlotByTime = false;
    },
    closeDialogEditSlotByDate() {
      this.dialogEditSlotByDate = false;
    },
    saveDialogEditSlotByTime() {
      // We need to verify that the ending time is after the starting time
      if (
        isTimeStartBiggerTimeEnd(
          this.dayOffByTime.startHH,
          this.dayOffByTime.startMM,
          this.dayOffByTime.endHH,
          this.dayOffByTime.endMM,
        )
      ) {
        this.$root.kdwsnackbar.alert("worktimeDay.errorStartTimeBiggerEndTime");
        return;
      }
      // We need to record the data
      this.dayOffs[this.dayOffByTime.itemNumber].startDate =
        this.dayOffByTime.startDate;
      this.dayOffs[this.dayOffByTime.itemNumber].startHH =
        this.dayOffByTime.startHH;
      this.dayOffs[this.dayOffByTime.itemNumber].startMM =
        this.dayOffByTime.startMM;
      this.dayOffs[this.dayOffByTime.itemNumber].endHH =
        this.dayOffByTime.endHH;
      this.dayOffs[this.dayOffByTime.itemNumber].endMM =
        this.dayOffByTime.endMM;
      this.dialogEditSlotByTime = false;
      // We pass back the data to v-model variable
      this.emitResult();
      this.$root.kdwsnackbar.alert("AccountEU.alertEUSuccessUpdate");
    },
    saveDialogEditSlotByDate() {
      // We need to verify that the ending time is after the starting time
      if (
        isDateStartBiggerDateEnd(
          this.dayOffByDate.startDate,
          this.dayOffByDate.endDate,
        )
      ) {
        this.$root.kdwsnackbar.alert("dayOff.errorStartDateBiggerEndDate");
        return;
      }
      // We need to record the data
      this.dayOffs[this.dayOffByDate.itemNumber].startDate =
        this.dayOffByDate.startDate;
      this.dayOffs[this.dayOffByDate.itemNumber].endDate =
        this.dayOffByDate.endDate;
      this.dialogEditSlotByDate = false;
      // We pass back the data to v-model variable
      this.emitResult();
      this.$root.kdwsnackbar.alert("AccountEU.alertEUSuccessUpdate");
    },
    addDayOffByTimeRange() {
      // We verify if a working slot is not already existing.
      /*
        var i = 0
        while (i < this.timeSlots.length) {
          if (this.timeSlots[i].type === 'Working') {
            this.$root.kdwsnackbar.alert(this.$i18n.t('worktimeDay.workingSlotAlreadyExist'))
            return
          }
          i++
        }
        */
      var today = new Date();
      var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
      this.dayOffs.push({
        type: "rangeBytime",
        startDate: tomorrow.toISOString().substring(0, 10),
        startHH: "14",
        startMM: "00",
        endHH: "18",
        endMM: "00",
      });
      this.emitResult();
    },
    addDayOffByDateRange() {
      // We just add another break that the SP can change later on
      var today = new Date();
      var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
      this.dayOffs.push({
        type: "rangeByDate",
        startDate: today.toISOString().substring(0, 10),
        endDate: tomorrow.toISOString().substring(0, 10),
      });
      this.$root.kdwsnackbar.alert("worktimeDay.breakSlotAdded");
      this.emitResult();
    },
    deleteDayOff(itemNumber) {
      this.dayOffs.splice(itemNumber, 1);
      this.emitResult();
      this.$root.kdwsnackbar.alert("worktimeDay.slotdeleted");
    },
    getDayOffString(i) {
      var output = "";
      if (this.dayOffs[i].type === "rangeByDate") {
        output = this.$i18n.t("dayOff.byDaysPeriod");
        output =
          output +
          ":   " +
          this.dayOffs[i].startDate +
          " / " +
          this.dayOffs[i].endDate;
      } else {
        output = this.$i18n.t("dayOff.byTimePeriod");
        output =
          output +
          ":   " +
          this.dayOffs[i].startDate +
          " (" +
          this.dayOffs[i].startHH +
          ":" +
          this.dayOffs[i].startMM +
          "-" +
          this.dayOffs[i].endHH +
          ":" +
          this.dayOffs[i].endMM +
          ")";
      }
      return output;
    },
  },
};
</script>
