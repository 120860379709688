<template>
  <v-container>
    <v-chip v-if="white" label color="rgba(60,60,59,0.5)" class="white--text">
      {{ text }}
    </v-chip>
    <v-chip v-if="!white" label color="rgba(60,60,59,0.5)" class="white--text">
      {{ text }}
    </v-chip>
  </v-container>
</template>

<script>
export default {
  name: "KdwLabel",

  props: {
    text: String,
    white: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
