<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to manage the address collection
// v-model: object having the follwing parameters {
//        address1: '',       First line of the address
//        address2: '',       Second line of the address
//        postcode: '',    PostCode of the address
//        cityId: '',      Id of the city
//        countryId: '',   Id of the country
      }
// Props:
//  - idAdditional: this text can be added to identify the items uniquelly. This is optional.
//  - disabled: is the address is disabled or not - Optional and default value is false
//  - display: this is a trick to replace key & to force the update of the input value. It is mandatory. This value is a counter to increase. It is used when the value is updated by the parent.
// Example of usage:
      <kdw-address
        v-model="myServiceProvider.addresscpy"
        idAdditional="company"
        :display="trickUpdateAddress"
      />
// ------------------------------------------------------------------------------>
<template>
  <v-container :id="'address' + idAdditional">
    <v-row no-gutters>
      <v-col cols="12" sm="12">
        <kdw-text-field
          :id="'IDaddress1' + idAdditional"
          v-model="address1"
          :label="$t('identity.address1') + $t('meta.star')"
          prepend-icon="mdi-map-marker"
          clearable
          :disabled="getDisabled()"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="12">
        <kdw-text-field
          :id="'IDaddress2' + idAdditional"
          v-model="address2"
          :label="$t('identity.address2')"
          prepend-icon="mdi-map-marker"
          clearable
          :disabled="getDisabled()"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="3">
        <kdw-text-field
          :id="'IDpostcode' + idAdditional"
          v-model="postcode"
          :label="$t('identity.postcode') + $t('meta.star')"
          prepend-icon="mdi-map-marker"
          clearable
          :disabled="getDisabled()"
          @input="postCodeChanged"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          v-if="postcode !== null && postcode.length === 5"
          :id="'IDtownname' + idAdditional"
          v-model="cityId"
          :items="towns"
          item-text="townName"
          class="mt-3"
          item-value="id"
          auto-select-first
          dense
          prepend-icon="mdi-map-marker"
          :label="$t('identity.city') + $t('meta.star')"
          :disabled="getDisabled()"
        />
      </v-col>
      <v-col cols="12" sm="5">
        <v-autocomplete
          v-if="countries.length != 0"
          :id="'IDcountry' + idAdditional"
          v-model="countryId"
          :items="countries"
          class="mt-3"
          item-text="name"
          item-value="id"
          auto-select-first
          dense
          prepend-icon="mdi-map-marker"
          :label="$t('identity.country') + $t('meta.star')"
          :disabled="getDisabled()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { fetchCountries } from "@/services/Country";
import { fetchTownfromPostalCode } from "@/services/PostalCode";

export default {
  name: "AddressComponent",

  props: {
    value: {
      type: Object,
      required: true,
    },
    idAdditional: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    display: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data: () => ({
    // local data with the address
    address1: "",
    address2: "",
    postcode: "",
    cityId: "",
    countryId: "",
    // Towns list
    towns: [],
    // Country list
    countries: [],
  }),
  watch: {
    display: function (val) {
      // console.log('-- in key --------------------Before')
      this.valueCheckAndAllocationToLocal(this.value);
      // console.log('-- in key --------------------After')
    },
    address1: function (val) {
      this.address1 = val;
      this.emitResult();
    },
    address2: function (val) {
      this.address2 = val;
      this.emitResult();
    },
    cityId: function (val) {
      this.cityId = val;
      this.emitResult();
    },
    countryId: function (val) {
      this.countryId = val;
      this.emitResult();
    },
  },
  created() {
    // console.log('this.value:')
    // console.log(this.value)
    // fetch the country list
    fetchCountries(this.countries);
    // We collect the address data coming from the v-model
    // console.log('-- in create --------------------Before')
    this.valueCheckAndAllocationToLocal(this.value);
    // console.log('-- in create --------------------After')
  },
  methods: {
    // this method check the object name valueReceived, which is containing all of the adress parameters and copy them locally.
    valueCheckAndAllocationToLocal(valueReceived) {
      // console.log('valueReceived')
      // console.log(valueReceived)
      // console.log(valueReceived.address1 + ';' + valueReceived.address2 + ';' + valueReceived.postcode + ';' + valueReceived.cityId + ';' + valueReceived.countryId)
      // console.log('Result:')
      // console.log('condition1:', valueReceived && valueReceived.address1 !== null)
      // console.log('condition2:', valueReceived && valueReceived.address1 !== null && valueReceived.address1 !== undefined)
      // console.log('condition3:', valueReceived && valueReceived.address1 !== null && valueReceived.address1 !== undefined && valueReceived.address1 !== this.address1)
      // console.log('condition4:', valueReceived.address1 !== this.address1)
      // console.log('compare:', valueReceived.address1 + ';' + this.address1)

      if (
        valueReceived &&
        valueReceived.address1 !== null &&
        valueReceived.address1 !== undefined &&
        valueReceived.address1 !== this.address1
      )
        this.address1 = valueReceived.address1;
      if (
        valueReceived &&
        valueReceived.address2 !== null &&
        valueReceived.address2 !== undefined &&
        valueReceived.address2 !== this.address2
      )
        this.address2 = valueReceived.address2;
      if (
        valueReceived &&
        valueReceived.postcode !== null &&
        valueReceived.postcode !== undefined &&
        valueReceived.postcode !== this.postcode
      )
        this.postcode = valueReceived.postcode;
      if (
        valueReceived &&
        valueReceived.cityId !== null &&
        valueReceived.cityId !== undefined &&
        valueReceived.cityId !== this.cityId
      )
        this.cityId = valueReceived.cityId;
      if (
        valueReceived &&
        valueReceived.countryId !== null &&
        valueReceived.countryId !== undefined &&
        valueReceived.countryId !== this.countryId
      )
        this.countryId = valueReceived.countryId;
      // console.log(this.address1 + ';' + this.address2 + ';' + this.postcode + ';' + this.cityId + ';' + this.countryId)
      if (this.postcode.length === 5) {
        // console.log(towns)
        fetchTownfromPostalCode(this.postcode, this.towns);
      }
    },
    getDisabled() {
      return this.disabled;
    },
    emitResult() {
      if (
        this.address1 === this.value.address1 &&
        this.address2 === this.value.address2 &&
        this.postcode === this.value.postcode &&
        this.cityId === this.value.cityId &&
        this.countryId === this.value.countryId
      ) {
        // console.log('NO Change, we do not EMIT ----')
        return;
      } else {
        // console.log('-----We emit ----')
      }
      var address = {};
      if (this.address1) {
        address.address1 = this.address1;
      } else address.address1 = "";
      if (this.address2) {
        address.address2 = this.address2;
      } else address.address2 = "";
      if (this.postcode) {
        address.postcode = this.postcode;
      } else address.postcode = "";
      if (this.cityId) {
        address.cityId = this.cityId;
      } else address.cityId = "";
      if (this.countryId) {
        address.countryId = this.countryId;
      } else address.countryId = "";
      // console.log(address.address1 + ';' + address.address2 + ';' + address.postcode + ';' + address.cityId + ';' + address.countryId)
      this.$emit("input", address);
    },
    async postCodeChanged() {
      // console.log('postCode Changed :' + this.postcode)
      if (this.postcode === null) {
        // console.log('I pass here.. null')
        this.towns.splice(0, this.towns.length);
        this.postcode = "";
        this.cityId = "";
        this.countryId = "";
        this.emitResult();
        return;
      }
      if (this.postcode.length === 5) {
        // console.log('postcode 5 digits')
        await fetchTownfromPostalCode(this.postcode, this.towns);
        // We take the country of the first town
        if (this.towns.length > 0) {
          // console.log('serviceProvider :', this.myServiceProvider)
          // console.log(this.myServiceProvider)
          // We are selecting the first town available
          this.cityId = this.towns[0].id;
          // We are selecting the first country available
          this.countryId = this.towns[0].countryCodeId;
        }
      }
      this.emitResult();
    },
  },
};
</script>
