<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This is displaying a set of buttons with a specific color
// Props:
//   - color: Color of the buttons
//   - button: array of boolean to enable each button. The order of the button is the order of the array.
//      The template for each button is: { id: 'mdi-eye', disabled: true, display: true }
// Example of usage:
//    <miniweb-bouton-set
//      :color="dataTemplate.template.themeColor"
//      :buttons="[{ id: 'mdi-eye', disabled: true, display: true }, { id: 'mdi-star', disabled: false, display: dataTemplate.miniwebToggle.ratings.score }, { id: 'mdi-fountain-pen-tip', disabled: false, display: true }, ]"
//    />
// ------------------------------------------------------------------------------>
<template>
  <v-container>
    <template v-for="button in buttons">
      <kdw-btn
        v-if="button.display"
        :key="button.id"
        class="ma-2"
        :color="color"
        dark
        min-width="70"
        :disabled="button.disabled"
      >
        <v-icon>{{ button.id }}</v-icon>
      </kdw-btn>
    </template>
  </v-container>
</template>

<script>
export default {
  name: "ElegantTemplateBoutonSet",
  props: {
    color: {
      type: String,
    },
    buttons: {
      type: Array,
    },
  },
  data: () => ({}),
  created: function () {
    // console.log('buttons')
    // console.log(this.buttons)
  },
  methods: {},
};
</script>
