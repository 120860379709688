<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display a legal sentence.
// Example of usage:
      <kdw-legal-sentence />
// ------------------------------------------------------------------------------>

<template>
  <div>
    <!-- start the sentence -->
    {{ $t("AccountEU.consent1") }}
    <v-tooltip bottom>
      <template #activator="{ on }">
        <a target="_blank" :href="urlTermsAndConditions" @click.stop v-on="on">
          {{ $t("AccountEU.consent2") }}
        </a>
      </template>
      {{ $t("AccountEU.openInNewWindow") }}
    </v-tooltip>
    {{ $t("AccountEU.consent3") }}
    <v-tooltip bottom>
      <template #activator="{ on }">
        <a target="_blank" :href="urlLegal" @click.stop v-on="on">
          {{ $t("AccountEU.consent4") }}
        </a>
      </template>
      {{ $t("AccountEU.openInNewWindow") }}
    </v-tooltip>
    {{ $t("AccountEU.consent5") }}
    <v-tooltip bottom>
      <template #activator="{ on }">
        <a target="_blank" :href="urlDataManagement" @click.stop v-on="on">
          {{ $t("AccountEU.consent6") }}
        </a>
      </template>
      {{ $t("AccountEU.openInNewWindow") }}
    </v-tooltip>
  </div>
</template>

<script>
import { getPictureUrlfromS3Bucket } from "@/helpers/GenericFunctionsS3Download";

export default {
  name: "Calendar",

  data: () => {
    return {
      urlTermsAndConditions: "",
      urlLegal: "",
      urlDataManagement: "",
    };
  },
  created() {
    getPictureUrlfromS3Bucket("ConditionsGenerales.pdf").then((url) => {
      this.urlTermsAndConditions = url;
    });
    getPictureUrlfromS3Bucket("MentionLegales.pdf").then((url) => {
      this.urlLegal = url;
    });
    getPictureUrlfromS3Bucket("GDPR.pdf").then((url) => {
      this.urlDataManagement = url;
    });
  },

  methods: {},
};
</script>
