//
export function dealWithBooking(obj, item, answerId) {
  // console.log('dealWithBooking - obj:')
  // console.log(obj)
  // console.log('dealWithBooking - item:')
  // console.log(item)
  // console.log('dealWithBooking - answerid:')
  // console.log(answerId)
  if (!item.onlineBooking) {
    // TODO error & snackbar here ?
    return;
  }
  // this service is OnlineBooking, so we need to trigger the booking window
  // console.log('WE NEED TO DO SOMETHING TO BOOK')
  obj.dialogOnlineBooking = true;
  obj.ServiceIDforOnlineBooking = item.id;
  // navigate(obj, 'EndUserMyServices')
  if (answerId !== "") {
    obj.answerId = answerId;
  }
}

export function dealWithBookingFirst(obj, item) {
  // console.log('dealWithBooking - obj:')
  // console.log(obj)
  // console.log('dealWithBooking - item:')
  // console.log(item)
  if (!item.onlineBooking) {
    // TODO error & snackbar here ?
    return;
  }
  // this service is OnlineBooking, so we need to trigger the booking window
  // console.log('WE NEED TO DO SOMETHING TO BOOK')
  obj.dialogOnlineBooking = true;
  obj.ServiceIDforOnlineBooking = item.id;
}
