<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This component is search opportunity for the SP around a specific location
// Props:
// - searchResultFlag: Trigger this to true when a seach must occur.
// - list-category-sp: List of categories object used for the search. { id: category ID, name: category name, nameTag: name tag of the category }
// - localisation: {latitude, longitude, radius }
// Events:
// -ResetSearchEvent: Emit an event to reset the searchResultFlag
// Component:
    <kdw-market-search-results
      :search-event="searchResultFlag"
      :list-category="listCategorySP"
      :localisation="localisation"
      @ResetSearchEvent="resetSearchEvent()"
    />
// ------------------------------------------------------------------------------>

<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card
          v-show="displayServices"
          elevation="11"
          class="mx-auto my-12 rounded"
          shaped
        >
          <v-system-bar color="primary" class="grey--text text--lighten-3">
            <v-icon class="ml-1 grey--text text--lighten-3">
              mdi-folder-pound
            </v-icon>
            <span class="font-weight-bold">{{
              $t("marketSearchResults.someOpportunity")
            }}</span>
          </v-system-bar>
          <v-card-text v-for="(requestItem, r) in request.requests" :key="r">
            <v-divider v-if="r !== 0" light />
            <!------- Main title  ---->
            <v-list-item-title
              class="primary--text ml-4 mt-4"
              style="white-space: normal"
            >
              <strong>{{ requestItem.serviceName }}</strong>
            </v-list-item-title>
            <v-list-item>
              <v-list-item-content>
                <!-- We display the category if any -->
                <v-list-item-subtitle
                  v-show="requestItem.catNameTag !== ''"
                  style="white-space: normal"
                  class="concrete--text"
                >
                  <v-icon small class="concrete--text">
                    mdi-folder-pound
                  </v-icon>
                  {{ $t(requestItem.catNameTag) }}
                </v-list-item-subtitle>
                <!-- We display the sub-category if any -->
                <v-list-item-subtitle
                  v-show="requestItem.subcatNameTag !== ''"
                  style="white-space: normal"
                  class="concrete--text"
                >
                  <v-icon small class="concrete--text">
                    mdi-folder-pound-outline
                  </v-icon>
                  {{ $t(requestItem.subcatNameTag) }}
                </v-list-item-subtitle>
                <!-- We display the request location -->
                <v-list-item-subtitle class="concrete--text">
                  <v-icon small class="concrete--text"> mdi-map-marker </v-icon>
                  {{ requestItem.townName }}
                </v-list-item-subtitle>
                <!-- We display the request date -->
                <v-list-item-subtitle class="concrete--text">
                  <v-icon small class="concrete--text">
                    mdi-calendar-import
                  </v-icon>
                  {{ requestItem.createdAt }}
                </v-list-item-subtitle>
                <!-- We display the current state -->
                <v-list-item-subtitle class="concrete--text">
                  <v-icon small class="concrete--text">
                    mdi-arrange-send-to-back
                  </v-icon>
                  {{ requestItem.state.currentState }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-spacer />
              <!----  THIS IS THE DESKTOP LOGIC  --->
              <v-list-item-group class="hidden-sm-and-down">
                <!-- This is questions reminder ----->
                <v-list-item-action v-show="requestItem.qna !== null">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        :id="'editQuestionsSPButtonMarketDesktop' + r"
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        @click="editQuestions(requestItem)"
                      >
                        <v-icon color="white"> mdi-help </v-icon>
                      </kdw-btn>
                    </template>
                    <span>{{ $t("tooltip.question") }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <!-- This is quotation proposal ----->
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        :id="'editQuotationSPButtonMarketDesktop' + r"
                        color="primary"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        @click="editQuotation(requestItem)"
                      >
                        <v-icon>mdi-currency-eur</v-icon>
                      </kdw-btn>
                    </template>
                    <span>{{ $t("tooltip.createQuotation") }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item-group>
            </v-list-item>
            <!----- THIS IS THE MOBILE LOGIC ---->
            <v-list-item class="hidden-md-and-up">
              <v-spacer />
              <v-list-item-group>
                <!-- This is questions reminder ----->
                <v-list-item-action v-show="requestItem.qna !== null">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        :id="'editQuestionsSPButtonMarketMobile' + r"
                        color="sunflower"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        @click="editQuestions(requestItem)"
                      >
                        <v-icon color="white"> mdi-help </v-icon>
                      </kdw-btn>
                    </template>
                    <span>{{ $t("tooltip.question") }}</span>
                  </v-tooltip>
                </v-list-item-action>
                <!-- This is quotation proposal ----->
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <kdw-btn
                        :id="'editQuotationSPButtonMarketDesktop' + r"
                        color="primary"
                        elevation="10"
                        min-width="50"
                        v-bind="attrs"
                        v-on="on"
                        @click="editQuotation(requestItem)"
                      >
                        <v-icon> mdi-currency-eur </v-icon>
                      </kdw-btn>
                    </template>
                    <span>{{ $t("tooltip.createQuotation") }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item-group>
            </v-list-item>
          </v-card-text>
          <!---- We are taking care of the bottom of the card --->
          <v-divider />
          <v-card-actions class="mt-4">
            <v-combobox
              :key="'ComboFilter1' + displayTrick"
              v-model="filterOptionsSelected"
              :items="getFilterOptions"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.filter')"
              light
              dense
              class="mx-2 ml-5"
              @change="filterOptionsChanged()"
            />
            <v-combobox
              :key="'ComboFilter2' + displayTrick"
              v-model="rowByPageSelected"
              :items="getRowByPage"
              item-text="name"
              item-value="id"
              :label="$t('generalSearchResults.rowsByPage')"
              class="mx-2"
              dense
              disabled
              light
            />
            <v-spacer />
            <v-chip disabled label class="mb-4"> 1/1 </v-chip>
            <kdw-btn
              min-width="10"
              small
              class="ml-3 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon>mdi-chevron-left </v-icon>
            </kdw-btn>
            <kdw-btn
              min-width="10"
              small
              class="mr-5 mb-4"
              color="rgba(60,60,59,0.6)"
              disabled
            >
              <v-icon> mdi-chevron-right </v-icon>
            </kdw-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!--- DIALOG BOX for DISPLAYING THE QUESTIONS -->
      <kdw-dialog-question-results
        v-if="editedItem.qna !== undefined"
        v-model="dialogQuestion"
        :question-item="editedItem.qna.questions"
      />
      <!--- DIALOG BOX for Making the quotation -->
      <v-dialog v-model="dialogQuotation" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <base-info-card
              :title="$t('marketSP.proposeQuotation')"
              color="primary"
            />
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <kdw-text-field
                    id="servicePriceTextQuotationDialog"
                    v-model="myAnswer.servicePrice"
                    :disabled="
                      editedItem.state !== undefined
                        ? editedItem.state.isDisabled()
                        : false
                    "
                    type="number"
                    :label="$t('RequestEU.price')"
                    prefix="€"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="menuDate"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value.sync="myAnswer.dateExpiry"
                    :disabled="
                      editedItem.state !== undefined
                        ? editedItem.state.isDisabled()
                        : false
                    "
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="myAnswer.dateExpiry"
                        :label="$t('marketSP.expirationDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="myAnswer.dateExpiry"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <kdw-btn @click="menuDate = false">
                        {{ $t("button.cancel") }}
                      </kdw-btn>
                      <kdw-btn
                        @click="$refs.menuDate.save(myAnswer.dateExpiry)"
                      >
                        {{ $t("button.validateQuestion") }}
                      </kdw-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-autocomplete
                  v-if="serviceList.length > 0"
                  id="serviceSelectListQuotationDialog"
                  v-model="selectedService"
                  :items="serviceList"
                  item-text="name"
                  item-value="id"
                  auto-select-first
                  dense
                  prepend-icon="mdi-map-marker"
                  :label="$t('marketSP.pickService')"
                  @change="ServiceQuotationSelected"
                />
              </v-row>
              <v-row>
                <v-col cols="12" sm="7" md="7">
                  <kdw-text-field
                    v-model="myAnswer.quotation"
                    :label="$t('marketSP.quotationFile')"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-file-input
                    id="pickquotationFileInputQuotationDialog"
                    v-model="myAnswer.quotationFile"
                    :rules="rules"
                    :disabled="
                      editedItem.state !== undefined
                        ? editedItem.state.isDisabled()
                        : false
                    "
                    :placeholder="$t('marketSP.pickQuotation')"
                    dense
                    show-size
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    id="noteCustomerTextQuotationDialog"
                    v-model="myAnswer.noteCustomer"
                    :disabled="
                      editedItem.state !== undefined
                        ? editedItem.state.isDisabled()
                        : false
                    "
                    :label="$t('marketSP.noteCustomer')"
                    auto-grow
                    outlined
                    clearable
                    prepend-icon="mdi-comment-eye-outline"
                    rows="1"
                  />
                  <v-textarea
                    id="notePersonalTextQuotationDialog"
                    v-model="myAnswer.notePersonal"
                    :disabled="
                      editedItem.state !== undefined
                        ? editedItem.state.isDisabled()
                        : false
                    "
                    :label="$t('marketSP.noteInternal')"
                    auto-grow
                    outlined
                    clearable
                    prepend-icon="mdi-comment-minus"
                    rows="1"
                  />
                  <v-textarea
                    id="serviceLegalTextQuotationDialog"
                    v-model="myAnswer.serviceLegaldescription"
                    :disabled="
                      editedItem.state !== undefined
                        ? editedItem.state.isDisabled()
                        : false
                    "
                    :label="$t('marketSP.legalCustomer')"
                    auto-grow
                    outlined
                    clearable
                    prepend-icon="mdi-comment-flash-outline"
                    rows="1"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <kdw-btn id="cancelQuotationDialog" text @click="cancelQuotation">
              {{ $t("button.cancel") }}
            </kdw-btn>
            <v-spacer />
            <kdw-btn
              id="saveQuotationDialog"
              :disabled="
                editedItem.state !== undefined
                  ? editedItem.state.isDisabled()
                  : false
              "
              @click="saveQuotation"
            >
              {{ $t("button.validateQuestion") }}
            </kdw-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-show="noOpportunity" class="primary--text">
      <strong>{{ $t("marketSearchResults.noNewOpportunity") }}</strong>
    </v-row>
  </div>
</template>

<script>
import { requestfromOnePostalCodeRayon } from "@/services/Distance";
import { formatServicePriceString } from "@/helpers/Formatter";
import { provideExpirationDate } from "@/helpers/GenericFunctions";
import { RequestService } from "@/services/Request";
import { Logger } from "aws-amplify";
import { RequestStateMachine } from "@/services/StateMachine";
import {
  fetchServicesSimple,
  fetchLegalAndCustomerNote,
} from "@/services/Service";
import { navigate } from "@/helpers/Navigation";
const logger = new Logger("MarketSearchResults");

export default {
  name: "MarketSearchResults",

  props: {
    searchEvent: {
      type: Boolean,
      default: false,
    },
    listCategory: {
      type: Array,
    },
    localisation: {
      type: Object,
    },
  },

  data: () => ({
    rowByPage: [],
    rowByPageSelected: { id: "all" },
    filterOptions: [],
    filterOptionsSelected: { id: "NAME↓" },
    displayTrick: 0,
    displayServices: false,
    // New variables
    request: {},
    dialogQuestion: false,
    editedIndex: -1,
    editedItem: { name: "" },
    defaultItem: { name: "" },
    dialogQuotation: false,
    myAnswer: {
      servicePrice: 0,
      dateExpiry: provideExpirationDate().substr(0, 10),
      serviceLegaldescription: "",
      noteCustomer: "",
      notePersonal: "",
      quotation: "",
      quotationFile: null,
    },
    myAnswerDefault: {
      servicePrice: 0,
      dateExpiry: provideExpirationDate().substr(0, 10),
      serviceLegaldescription: "",
      noteCustomer: "",
      notePersonal: "",
      quotation: "",
      quotationUrl: "",
      quotationFile: null,
    },
    selectedService: "",
    serviceList: [],
    menuDate: false,
    rules: [
      (value) =>
        !value || value.size < 2000000 || this.$i18n.t("AccountSP.sizeLogo"),
    ],
    noOpportunity: false,
  }),

  computed: {
    getRowByPage: function () {
      return this.getRowByPageMethod();
    },
    getFilterOptions: function () {
      return this.getFilterOptionsMethod();
    },
  },

  watch: {
    // We check if the parent requested for a search
    searchEvent: function (val) {
      if (val) {
        // We ask to reset the parent
        this.$emit("ResetSearchEvent");
        this.search();
      }
    },
  },

  created: function () {
    this.getFilterOptionsMethod();
    this.getRowByPageMethod();
    this.request = new RequestService();
  },

  methods: {
    displayServicePrice(isFixedPrice, ServicePrice) {
      return formatServicePriceString(isFixedPrice, ServicePrice);
    },
    getRowByPageMethod: function () {
      const table = [
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "5", name: "5" },
        { id: "10", name: "10" },
        { id: "15", name: "15" },
        { id: "all", name: this.$i18n.t("generalSearchResults.all") },
      ];
      if (this.rowByPageSelected.id === "all") {
        this.rowByPageSelected.name = this.$i18n.t("generalSearchResults.all");
      }
      return table;
    },
    getFilterOptionsMethod() {
      const table = [
        {
          id: "REQUESTDATE↑",
          name: this.$i18n.t(
            "generalSearchResults.filterServiceRequestRequestDateUp",
          ),
        },
        {
          id: "REQUESTDATE↓",
          name: this.$i18n.t(
            "generalSearchResults.filterServiceRequestRequestDateDown",
          ),
        },
        {
          id: "NAME↑",
          name: this.$i18n.t("generalSearchResults.filterNameUp"),
        },
        {
          id: "NAME↓",
          name: this.$i18n.t("generalSearchResults.filterNameDown"),
        },
      ];
      // console.log('this.filterOptionsSelected before')
      // console.log(this.filterOptionsSelected)
      if (this.filterOptionsSelected.id === "REQUESTDATE↑") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterServiceRequestRequestDateUp",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "REQUESTDATE↓") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterServiceRequestRequestDateDown",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "NAME↑") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterNameUp",
        );
        return table;
      }
      if (this.filterOptionsSelected.id === "NAME↓") {
        this.filterOptionsSelected.name = this.$i18n.t(
          "generalSearchResults.filterNameDown",
        );
      }
      // console.log('this.filterOptionsSelected after')
      // console.log(this.filterOptionsSelected)
      this.displayTrick++;
      return table;
    },

    // The end user requested a search
    async search() {
      // console.log('search ......')
      this.displayServices = false;
      this.noOpportunity = false;
      // console.log('this.localisation')
      // console.log(this.localisation)
      // console.log('this.listCategory')
      // console.log(this.listCategory)
      requestfromOnePostalCodeRayon(
        this.localisation.latitude,
        this.localisation.longitude,
        this.localisation.radius,
        this.listCategory,
        this.request.requests,
      )
        .then(() => {
          // console.log('this.request.requests')
          // console.log(this.request.requests)
          if (this.request.requests.length === 0) {
            // this.$emit('alertSnackBarMessage', { message: this.$i18n.t('marketSP.alertNoResult'), snackbarTimeout: 3000 })
            this.$root.kdwsnackbar.alert("marketSP.alertNoResult");
            this.noOpportunity = true;
          } else {
            this.sortFoundServices();
            this.displayServices = true;
          }
        })
        .catch((err) => logger.error(err));
    },

    // Filter option changed
    filterOptionsChanged() {
      // console.log('filterOptionsChanged', this.filterOptionsSelected)
      this.sortFoundServices();
    },
    /*
      selectOffer (service) {
        // console.log('selected Service:')
        // console.log(service)
        // We emit the result to the parent as the end user select the offer
        this.$emit('ServiceSelected', service)
      },
      */
    // This is managing the ordering options of the search content
    sortFoundServices() {
      switch (this.filterOptionsSelected.id) {
        case "NAME↑": // sort name by alphabetic order inverse
          this.request.requests.sort(function (a, b) {
            if (a.serviceName.toUpperCase() < b.serviceName.toUpperCase()) {
              return 1;
            }
            if (a.serviceName.toUpperCase() > b.serviceName.toUpperCase()) {
              return -1;
            }
            return 0;
          });
          break;
        case "NAME↓": // sort name by alphabetic order
          this.request.requests.sort(function (a, b) {
            if (a.serviceName.toUpperCase() < b.serviceName.toUpperCase()) {
              return -1;
            }
            if (a.serviceName.toUpperCase() > b.serviceName.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          break;
        case "REQUESTDATE↑": // sort by price inverse (from big to small)
          this.request.requests.sort(
            (d1, d2) =>
              new Date(d1.createdAt).getTime() -
              new Date(d2.createdAt).getTime(),
          );
          break;
        case "REQUESTDATE↓": // sort by price (from small to big)
          this.request.requests.sort(
            (d1, d2) =>
              new Date(d2.createdAt).getTime() -
              new Date(d1.createdAt).getTime(),
          );
          break;
      }
    },
    editQuestions(item) {
      // console.log(item)
      this.editedIndex = this.request.requests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogQuestion = true;
    },

    editQuotation(item) {
      // console.log('editQuotation')
      this.editedIndex = this.request.requests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.state = new RequestStateMachine(
        item.state.currentState,
        item.isFixedPrice,
      );
      // console.log('editedItem')
      // console.log(this.editedItem)
      this.myAnswer = Object.assign({}, this.myAnswerDefault);
      // We are fetching the service to make a connection.
      if (this.serviceList.length === 0) {
        fetchServicesSimple(this.serviceList)
          .then(() => {
            this.selectedService = "";
          })
          .catch((err) => logger.error(err));
      }
      this.dialogQuotation = true;
    },

    cancelQuotation() {
      this.dialogQuotation = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async saveQuotation() {
      // console.log('this.editedItem:')
      // console.log(this.editedItem)
      // console.log('this.myAnswer:')
      // console.log(this.myAnswer)
      // console.log('this.request.requests:')
      // console.log(this.request.requests)
      // console.log('this.selectedService:')
      // console.log(this.selectedService)
      // console.log('this.editedIndex:')
      // console.log(this.editedIndex)

      if (
        this.myAnswer.quotationFile === null &&
        (this.myAnswer.quotation === null || this.myAnswer.quotation === "")
      ) {
        this.$root.kdwsnackbar.alert("marketSP.alertNoQuotation");
        // this.$emit('alertSnackBarMessage', { message: this.$i18n.t('marketSP.alertNoQuotation'), snackbarTimeout: 3000 })
        return;
      }
      if (this.selectedService === "") {
        this.$root.kdwsnackbar.alert("marketSP.alertNoService");
        // this.$emit('alertSnackBarMessage', { message: this.$i18n.t('marketSP.alertNoService'), snackbarTimeout: 3000 })
        return;
      }
      this.editedItem.serviceID = this.selectedService;
      console.log(`this.editedItem(${JSON.stringify(this.editedItem)})`);
      await this.request.createAnswerForRequest(
        this.editedItem,
        this.myAnswer,
        this.editedIndex,
      ); // TODO: to be replaced with 'upsertAnswer' transition

      // We need now to attach the Service to the request for this SP!!!!!!!!!!!
      // console.log('this.request.attachServiceRequest(this.selectedService, this.editedItem, this.myAnswer)')
      // this.request.attachServiceRequest(this.selectedService, this.editedItem, this.myAnswer) // TODO: remove this as attachment is done by the 'upsertAnswer' transition

      this.dialogQuotation = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      navigate(this, "ServiceProviderCustomerMarket");
    },
    ServiceQuotationSelected() {
      // console.log('Selected service')
      fetchLegalAndCustomerNote(this.selectedService, this.myAnswer).then(
        () => {
          // console.log('this.myAnswer')
          // console.log(this.myAnswer)
        },
      );
    },
  },
};
</script>
