<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This is card with 3 parts:  ---- TO BE FINALISED ------
// - a picture on top
// - a title, description after
// - logo (mdi) and a button containing a text (services for now)
// Props:
//  - color: default color of the logo & button (primary is default)
//  - title: short title
//  - description: short descrition
//  - mdi: mdi (logo) to be used in the left part of the card (default is mdi-check-bold). List is here: https://pictogrammers.github.io/@mdi/font/2.0.46/
// Example of usage:
      <miniweb-card-experience />
// ------------------------------------------------------------------------------>
<template>
  <div>
    <v-card class="mx-auto" max-width="374" flat rounded elevation="11">
      <v-img
        height="200"
        src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
      />

      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <div><b>Description:</b><br />{{ description }}</div>
      </v-card-text>
      <v-divider class="mx-4" />
      <v-card-actions>
        <v-icon large :color="color">
          {{ mdi }}
        </v-icon>
        <v-spacer />
        <kdw-btn id="Services" :color="color"> Services </kdw-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CardExperience",

  props: {
    color: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    mdi: {
      type: String,
      default: "mdi-check-bold",
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
