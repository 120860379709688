import { API, Logger, Auth } from "aws-amplify";
import {
  updateSpCounters,
  sendEmailGQL,
  manageCognitoUser,
} from "../graphql/mutations";
import {
  getSpBillingLogo,
  spGetBillingMonthly,
  getByCityPostal,
} from "../graphql/queries";
const logger = new Logger("commonTools");

export async function sendEmail(templateName, templateParams) {
  var output = {};
  try {
    logger.info(
      "[commonTools]sending email templateName-:" +
        JSON.stringify(templateParams),
    );
    output = await API.graphql({
      query: sendEmailGQL,
      variables: {
        templateName,
        templateParams: JSON.stringify(templateParams),
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    logger.info("send email result:" + JSON.stringify(output));
  } catch (err) {
    logger.error("send email error: ", err);
    output.errorMessage = err.message;
    throw new Error(output.errorMessage);
  }
}

export async function spImpressionInc(spId) {
  console.log("increase spImpression spId:" + spId);
  const res = await API.graphql({
    query: updateSpCounters,
    variables: { spId },
    authMode: "AWS_IAM",
  });
  console.log("increase spImpression result:" + JSON.stringify(res));
}

export async function getByCityPostalCode(cityOrPostalCode) {
  console.log("getByCityPostalCode search:" + cityOrPostalCode);
  const res = await API.graphql({
    query: getByCityPostal,
    variables: { search: cityOrPostalCode },
    authMode: "AWS_IAM",
  });
  console.log("getByCityPostalCode result:" + JSON.stringify(res));
  return _parseKeyValueToJson(res.data.getByCityPostal).body;
}

export async function spGetLogoUsed(spId, mmyy) {
  console.log("spGetLogoUsed spId:" + spId);
  const res = await API.graphql({
    query: getSpBillingLogo,
    variables: { spId, mmyy },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  return JSON.parse(res.data.getSpBillingLogo);
}

export async function spGetBillMonthly(spId) {
  console.log("spGetBillMonthly spId:" + spId);
  const res = await API.graphql({
    query: spGetBillingMonthly,
    variables: { spId },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  console.log("spGetBillMonthly res:" + JSON.stringify(res));
  return JSON.parse(res.data.spGetBillingMonthly);
}

export async function sendEmailGql(templateName, templateParams) {
  console.log("sendEmailGql templateName:" + templateName);
  const res = await API.graphql({
    query: sendEmailGQL,
    variables: { templateName, templateParams: JSON.stringify(templateParams) },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  console.log("sendEmailGql result:" + JSON.stringify(res));
  return _parseKeyValueToJson(res.data.sendEmailGQL);
}

export async function manageUser(userData) {
  console.log("manageUser userData:" + userData);
  const res = await API.graphql({
    query: manageCognitoUser,
    variables: { userData },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  console.log("manageUser result:" + JSON.stringify(res));
  return _parseKeyValueToJson(res.data.manageCognitoUser);
}

export async function doGqlWithAwsLambdaAuthorizer(
  _query,
  _variables,
  _authToken,
) {
  // console.log('doGqlWithAwsLambdaAuthorizer query:' + _query + ';authToken:' + _authToken)
  let token = _authToken;
  if (!token) {
    const session = await Auth.currentSession();
    if (session) {
      token = session.getAccessToken().getJwtToken();
    }
  }

  const theToken = {
    type: "cognito", // futur proof thing
    token,
  };
  const res = await API.graphql({
    query: _query,
    variables: _variables,
    authToken: JSON.stringify(theToken),
    authMode: "AWS_LAMBDA",
  });

  // const res = await API.graphql({ query: _query, variables: _variables, authToken: token, authMode: 'AWS_LAMBDA' })
  // console.log('Everything is fine')
  // console.log('doGqlWithAwsLambdaAuthorizer result:' + JSON.stringify(res))
  return res;
}

function _parseKeyValueToJson(input) {
  const removeBracket = input.substring(1, input.length - 1);
  const regex = /([a-z]\w*)=((?:[^"]|"[^"]+")+?)(?=,\s*[a-z]\w*=|$)/g;

  let m;
  const els = [];

  while ((m = regex.exec(removeBracket)) !== null) {
    // skip the headers as not needed
    if (m[1] === "headers") continue;
    els.push(`"${m[1]}": ${m[2]}`);
  }

  const json = "{" + els.join(",") + "}";
  console.log("_parseKeyValueToJson json:" + json);
  console.log("_parseKeyValueToJson els:" + els);
  return JSON.parse(json);
}
