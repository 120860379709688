<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "KidiwiServices",
    titleTemplate: "%s",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Montserrat:wght@300;500;700&display=swap");

/*
  @font-face {
    font-family: 'Metropolis';
    src:
      local("Metropolis-SemiBold"),
      url(~@/assets/fonts/Metropolis-SemiBold.woff2) format('woff2'),
      url(~@/assets/fonts/Metropolis-SemiBold.woff) format('woff'),
      url(~@/assets/fonts/Metropolis-SemiBold.ttf) format('truetype');
  }
  @font-face {
    font-family: 'DINish';
    src:
      local("Dinish-Regular"),
      url(~@/assets/fonts/Dinish-Regular.woff2) format('woff2'),
      url(~@/assets/fonts/Dinish-Regular.woff) format('woff'),
      url(~@/assets/fonts/Dinish-Regular.ttf) format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'DINish';
    src:
      local("Dinish-Bold"),
      url(~@/assets/fonts/Dinish-Bold.woff2) format('woff2'),
      url(~@/assets/fonts/Dinish-Bold.woff) format('woff'),
      url(~@/assets/fonts/Dinish-Bold.ttf) format('truetype');
    font-weight: bold;
  }
  */
</style>

<style lang="sass">
.extra-padding
  padding-bottom: 96px !important
  padding-top: 96px !important

  @media screen and (max-width: 959px)
    padding-top: 48px !important
    padding-bottom: 48px !important
</style>
