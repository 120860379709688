<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This component is proposing to display a set of pictures in a Carousel or with a specific display (2 picture horizontal and 1 vertical)
// 3 pictures minimum are necessary for 2horizontal1vertical mode
// Props:
//   - mode: 2 values: "2horizontal1vertical" for =| display OR "Carousel" for Carousel display (picture slider) OR "3horizontal"
//   - pictures: Array with the list of picture to display
//   - loop: indicates if the carousel should make have the pictures in cycle or not (true = cycle, false = manual change)
// Example of usage:
      <miniweb-picture-displayer
        mode="2horizontal1vertical"
      />
// ------------------------------------------------------------------------------>
<template>
  <v-container>
    <v-row v-if="mode === '2horizontal1vertical'">
      <v-col cols="12" sm="7">
        <v-row>
          <v-card class="ma-3 mt-6">
            <v-img
              src="https://picsum.photos/350/165?random"
              max-height="140"
              class="grey darken-4"
            />
          </v-card>
        </v-row>
        <v-row>
          <v-card class="ma-3">
            <v-img
              src="https://picsum.photos/350/165?random"
              max-height="140"
              class="grey darken-4"
            />
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="12" sm="5">
        <v-card class="ma-3">
          <v-img
            src="https://picsum.photos/165/350?random"
            max-height="300"
            class="grey darken-4"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="mode === 'Carousel' && picturesList.length > 0">
      <v-col>
        <v-sheet color="white">
          <v-carousel height="300" hide-delimiters :cycle="loop">
            <v-carousel-item
              v-for="(picture, i) in picturesList"
              :key="i"
              :src="picture.src"
              reverse-transition="fade-transition"
              transition="fade-transition"
            />
          </v-carousel>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row v-if="mode === '3horizontal'" class="mt-5">
      <v-row v-for="(picture, i) in picturesList" :key="i">
        <base-img
          :key="i"
          :src="picture.src"
          max-height="200"
          max-width="500"
          class="my-2"
        />
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { getPictureUrlfromS3Bucket } from "@/helpers/GenericFunctionsS3Download";
export default {
  name: "PictureDisplayer",
  props: {
    mode: {
      type: String,
      default: "Carousel",
    },
    pictures: {
      type: Array,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    count: 0,
    picturesList: [],
  }),
  watch: {},
  created: function () {
    // if (this.picturesList.length === 0) this.picturesList = [...this.pictures]
    if (this.pictures.length > 0) {
      for (var i = 0; i < this.pictures.length; i++) {
        if (this.pictures[i]) {
          // we are loading the pictures
          getPictureUrlfromS3Bucket(this.pictures[i]).then((url) => {
            // console.log('logoUrl:', url)
            this.picturesList.push({ src: url });
          });
        }
      }
    }
  },
  methods: {},
};
</script>
