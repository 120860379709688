// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the manually generated QUERIES ON GRAPHQL
// ------------------------------------------------------------------------------

export const postalCodeByPostalCodeReturnGPS = `
  query PostalCodeByPostalCode(
    $postalCode: String!
    $townName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostalCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postalCodeByPostalCode(
      postalCode: $postalCode
      townName: $townName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        countryCode
        townName
        postalCode
        latitude
        longitude
      }
      nextToken
    }
  }
`;

export const postalCodeByTownNameSimplified = `
  query PostalCodeByTownName(
    $townName: String!
    $postalCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostalCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postalCodeByTownName(
      townName: $townName
      postalCode: $postalCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        townName
        postalCode
      }
      nextToken
    }
  }
`;

export const postalCodeByPostalCodeSimplified = /* GraphQL */ `
  query PostalCodeByPostalCode(
    $postalCode: String!
    $townName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostalCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postalCodeByPostalCode(
      postalCode: $postalCode
      townName: $townName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        countryCode
        townName
        postalCode
        latitude
        longitude
      }
      nextToken
    }
  }
`;
export const listPostalCodesSimplified = /* GraphQL */ `
  query ListPostalCodes(
    $id: ID
    $filter: ModelPostalCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPostalCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        countryCode
        townName
        postalCode
      }
      nextToken
    }
  }
`;
export const listCountriesSimplified = /* GraphQL */ `
  query ListCountries(
    $id: ID
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCountries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const getServiceProviderCost = /* GraphQL */ `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      costs {
        items {
          id
          date
          price
          cost
          invoice
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const getServiceProviderParameters = `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      parameters
    }
  }
`;

export const listCategorysAndSubCategorys = /* GraphQL */ `
  query listCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nameTag
        countryCode
        keywords
        subCategorys {
          items {
            id
            name
            nameTag
            countryCode
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCategoriesCheck = /* GraphQL */ `
  query ListCategories(
    $id: ID
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        nameTag
        countryCode
        subCategorys {
          items {
            name
            nameTag
            countryCode
          }
        }
      }
      nextToken
    }
  }
`;
export const listSubCategoriesCompact = /* GraphQL */ `
  query ListSubCategories(
    $id: ID
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        categorySubCategorysId
        name
        nameTag
        countryCode
      }
      nextToken
    }
  }
`;
export const getEndUserRequestAnswers = `
  query GetEndUser($id: ID!) {
    getEndUser(id: $id) {
      id
      firstname
      lastname
      requests {
        items {
          id
          serviceName
          categoryID
          services {
            items {
              service {
                ServicePrice
                id
              }
            }
          }  
          qna
          isFixedPrice
          datetimeExpiry
          state
          isProposalSP
          category {
            nameTag
          }
          createdAt
          updatedAt
          owner
          answers {
            items {
              id
              createdAt
              servicePrice
              quotation
              datetimeExpiry
              noteCustomer
              serviceLegaldescription
              onlineBooking
              state
              review {
                id
              }
              appointments {
                items {
                  id
                  appointment {
                    id
                    resourceID
                    resourceLastname
                    resourceFirstname
                    dateTime
                  }
                }
              }
              serviceProvider {
                id
                namecpy
                logocpy
                addresscpy1
                addresscpy2
                postcodecpy
                postcodecitycpy {
                  townName
                }
                countrycpy {
                  name
                }
                phonecpy
                emailcpy
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
      postcodecity {
        id
        countryCode
        townName
        postalCode
      }
      country {
        id
        name
      }
    }
  }
`;
export const getEndUserBasicData = `
  query GetEndUser($id: ID!) {
    getEndUser(id: $id) {
      firstname
      lastname
      phone
      email
    }
  }
`;

export const listServicesSimplify = `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceProviderID
        categoryID
        name
        serviceDescription
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        onlineBooking
        createdAt
        updatedAt
        notePersonal
        currency
        serviceRating
        reviewNb
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
        }
        subCategory {
          id
          name
          nameTag
          countryCode
          validated
          questions
        }
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
        }
      }
      nextToken
    }
  }
`;
export const listServicesSimplifyDistanceSearch = `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceProviderID
        name
        serviceDescription
        serviceLegaldescription
        isFixedPrice
        isActivated
        serviceAutoAccepted
        serviceDuration
        ServicePrice
        onlineBooking
        serviceRating
        reviewNb
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          questions
        }
        subCategory {
          id
          name
          nameTag
          countryCode
          questions
        }
        postcodecity {
          townName
          latitude
          longitude
        }
      }
      nextToken
    }
  }
`;

export const serviceRequestsBySPIDExtractRequestAnswer = `
  query ServiceRequestsBySPID(
    $serviceProviderID: String!
    $createdAtShort: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ServiceRequestsBySPID(
      serviceProviderID: $serviceProviderID
      createdAtShort: $createdAtShort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        request {
          id
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          endUser {
            id
            firstname
            lastname
            address1
            address2
            postcode
            phone
            email
            postcodecity {
              townName
            }
            country {
              name
            }
          }
          answers {
            items {
              id
              quotation
              datetimeExpiry
              servicePrice
              serviceLegaldescription
              noteCustomer
              onlineBooking
              review {
                id
              }
              appointments {
                items {
                  id
                  appointment {
                    resourceID
                    resourceFirstname
                    resourceLastname
                    dateTime
                    id
                  }
                }
              }
              notePersonal
              state
              serviceProvider {
                id
              }
            }
          }
        }
        createdAt
        updatedAt
        service {
          id
          serviceProviderID
          name
          isFixedPrice
          isActivated
          ServicePrice
          category {
            id
            name
          }
          subCategory {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;
export const listServicesRequestUser = `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceProviderID
        name
        serviceDescription
        isFixedPrice
        isActivated
        ServicePrice
        category {
          id
          name
        }
        subCategory {
          id
          name
        }
        requests {
          items {
            id
            serviceProviderID
            request {
                      id
                      createdAt
                      qna
                      datetimeExpiry
                      state
                      isFixedPrice
                      endUser {
                        id
                        firstname
                        lastname
                        address1
                        address2
                        postcode
                        postcodecity {
                          townName
                        }
                        country {
                          name
                        }
                        phone
                        email
                      }
                      answers {
                        items {
                          id
                          quotation
                          datetimeExpiry
                          servicePrice
                          serviceLegaldescription
                          noteCustomer
                          notePersonal
                          state
                          serviceProvider {
                            id
                          }
                        }
                      }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listRequestsSimplified = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceName
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        qna
        createdAt
        answers {
          nextToken
        }
        services {
          items {
            service {
              serviceProviderID
            }
          }
        }

        category {
          id
          name
          nameTag
        }
        subCategory {
          id
          name
          nameTag
        }
        answers {
          items {
            id
          }
          nextToken
        }
        postcodecity {
          countryCode
          townName
          postalCode
          latitude
          longitude
        }
        endUser {
          id
        }
      }
      nextToken
    }
  }
`;

export const listCountriesSimplify = `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const getServiceProviderConsent = /* GraphQL */ `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      consent
      namecpy
      banned
      siret
      siretVerified
      addresscpy1
      addresscpy2
      postcodecpy
      emailcpy
      consentcpy
      postcodecitycpy {
        id
        townName
      }
      countrycpy {
        id
        name
      }
    }
  }
`;
export const getServiceProviderCookie = /* GraphQL */ `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      cookieParameters
      cookieUpdateDate
    }
  }
`;
export const getEndUserCookie = `
  query GetEndUser($id: ID!) {
    getEndUser(id: $id) {
      cookieParameters
      cookieUpdateDate
    }
  }
`;
export const getEndUserConsent = `
  query GetEndUser($id: ID!) {
    getEndUser(id: $id) {
      id
      firstname
      lastname
      address1
      address2
      postcode
      email
      consent
      postcodecity {
        id
        townName
      }
      country {
        id
        name
      }
    }
  }
`;
export const getEndUserParameters = /* GraphQL */ `
  query GetEndUser($id: ID!) {
    getEndUser(id: $id) {
      parameters
    }
  }
`;

export const getServiceProviderCounter = `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      id
      countMonthMaxActiveService
      createdAt
      walletSize
    }
  }
`;
export const listServiceRequestsCountRelation = `
  query ListServiceRequests(
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceProviderID
        createdAtShort
      }
      nextToken
    }
  }
`;
export const listServiceRequestsServiceCheck = `
  query ListServiceRequests(
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceRequestsId
      }
      nextToken
    }
  }
`;
export const serviceRequestsBySPID = /* GraphQL */ `
  query ServiceRequestsBySPID(
    $serviceProviderID: String!
    $createdAtShort: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ServiceRequestsBySPID(
      serviceProviderID: $serviceProviderID
      createdAtShort: $createdAtShort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAtShort
      }
      nextToken
    }
  }
`;
export const serviceRequestsBySPIDGetRequestList = `
  query ServiceRequestsBySPID(
    $serviceProviderID: String!
    $createdAtShort: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ServiceRequestsBySPID(
      serviceProviderID: $serviceProviderID
      createdAtShort: $createdAtShort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        request {
          id
        }
      }
      nextToken
    }
  }
`;
export const getServiceDescriptionLegalPersonalNotes = `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      serviceDescription
      notePersonal
      serviceLegaldescription
    }
  }
`;
export const getServiceProviderListRessourcesID = `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      id
      resources {
        items {
          id
        }
        nextToken
      }
    }
  }
`;
export const getServiceProviderLogo = `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      logocpy
    }
  }
`;
export const getServiceProviderCompanyName = `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      namecpy
    }
  }
`;
export const getServiceRessourceDataList = `
  query GetService($id: ID!) {
    getService(id: $id) {
      name
      resources {
        items {
          id
          resource {
            id
            canBookOnline
          }
        }
        nextToken
      }
    }
  }
`;
export const getServiceRessourceDataListForBooking = `
  query GetService($id: ID!) {
    getService(id: $id) {
      resources {
        items {
          id
          resource {
            id
            canBookOnline
            firstname
            picture
            interval
            dayOffList
            weekWorkingDays
          }
        }
        nextToken
      }
    }
  }
`;

export const getServiceNameDuration = `
  query GetService($id: ID!) {
    getService(id: $id) {
      name
      serviceDuration
      bufferTimeBefore
      bufferTimeAfter
    }
  }
`;

export const getResourceListServices = `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      services {
        items {
          id
          service {
            id
            name
          }
        }
        nextToken
      }
    }
  }
`;
export const getResourceFirstnameLastname = `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      firstname
      lastname
      id
    }
  }
`;
export const getResourceForCalendar = `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      firstname
      lastname
      picture
      dayOffList
    }
  }
`;
export const getResourceForLoad = `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      firstname
      lastname
      phone
      email
      login
      interval
      picture
      webSiteAccess
      canBookOnline
      weekWorkingDays
      dayOffList
    }
  }
`;
export const getResourceForLoadForBooking = `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      firstname
      interval
      picture
      canBookOnline
      weekWorkingDays
      dayOffList
    }
  }
`;
export const getResourceParameters = `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      parameters
    }
  }
`;

export const listServiceResourcesSimplified = `
  query ListServiceResources(
    $filter: ModelServiceResourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceID
      }
      nextToken
    }
  }
`;
export const listServiceResourcesSimplifiedWithService = `
  query ListServiceResources(
    $filter: ModelServiceResourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceID
        service {
          id
          name
          serviceDuration
          bufferTimeBefore
          bufferTimeAfter
        }
      }
      nextToken
    }
  }
`;
export const listAppointmentsSimplified = `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateTime
        serviceDuration
        serviceName
        resourceFirstname
        resourceLastname
        enduserID
        enduserFirstName
        enduserLastname
        enduserPhone
        enduserMail
        answers {
          items {
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const getAnswerAppointmentDetails = `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      appointments {
        items {
          id
          appointment {
            id
            resourceID
            resourceFirstname
            resourceLastname
            dateTime
          }
        }
      }
    }
  }
`;
export const miniWebBySearchkey = `
  query MiniWebBySearchkey(
    $searchkey: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMiniWebFilterInput
    $limit: Int
    $nextToken: String
  ) {
    miniWebBySearchkey(
      searchkey: $searchkey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serviceProviderID
        searchkey
        name
        firstname
        siret
        toggleSiret
        address1
        address2
        country {
          name
        }
        email
        logo
        owner
        phone
        postcode
        postcodecity {
          id
          townName
          postalCode
        }
        themeId
        layoutId
        toggleEmail
        togglePhone
        priceRange
        jobName
        score
        showroomDescription
        showroomPictures
        showroomPicturesLoop
        showroomBackground
        servicesDescription
        servicesBackground
        toggleMiniweb
        togglePriceRange
        toggleAddress
      }
      nextToken
    }
  }
`;
export const getMiniWeb = `
  query GetMiniWeb($serviceProviderID: String!) {
    getMiniWeb(serviceProviderID: $serviceProviderID) {
      serviceProviderID
      searchkey
      name
      firstname
      address1
      address2
      country {
        id
        name
      }
      email
      logo
      owner
      phone
      postcode
      postcodecity {
        id
        countryCode
        townName
        postalCode
      }
      themeId
      miniWebCountryId
      miniWebPostcodecityId
      layoutId
      jobName
      showroomDescription
      priceRange
      toggleEmail
      togglePhone
      toggleAddress
      togglePriceRange
      score
      showroomPictures
      showroomPicturesLoop
      showroomBackground
      servicesDescription
      servicesBackground
      toggleMiniweb
      siret
      toggleSiret
      keywords
    }
  }
`;
export const getMiniWebSearchKey = `
  query GetMiniWeb($serviceProviderID: String!) {
    getMiniWeb(serviceProviderID: $serviceProviderID) {
      searchkey
      toggleMiniweb
    }
  }
`;
export const getReviewDialogReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      euReviewComment
      spRating
      spReviewDateTime
      spReviewComment
    }
  }
`;
export const reviewByServiceIDComputeRatingAverage = /* GraphQL */ `
  query ReviewByServiceID(
    $serviceID: ID!
    $spRating: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewByServiceID(
      serviceID: $serviceID
      spRating: $spRating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        spRating
      }
      nextToken
    }
  }
`;
export const reviewByServiceIDComments = /* GraphQL */ `
  query ReviewByServiceID(
    $serviceID: ID!
    $spRating: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewByServiceID(
      serviceID: $serviceID
      spRating: $spRating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        spRating
        euReviewDateTime
        euReviewComment
        spReviewDateTime
        spReviewComment
      }
      nextToken
    }
  }
`;

export const getCategoryAndSubCat = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      nameTag
      countryCode
      keywords
      validated
      questions
      subCategorys {
        items {
          id
          name
          nameTag
          countryCode
        }
        nextToken
      }
    }
  }
`;
