<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to manage the list of questions requested to the end user in case of quotation base
// Props:
//   - parentObj: pointer to the object parent.
//   - questions: Object containing the list of questions { question: [], category: '', subCategory: '' }
//
// Example of usage:
  <kdw-dialog-questions
    v-model="dialogQuestions"
    :parent-obj="this"
    :questions="questions"
  />
// ------------------------------------------------------------------------------>

<template>
  <v-dialog persistent width="95%" v-bind="$attrs" v-on="$listeners">
    AAA
    <v-stepper v-model="stepper" :value="stepper">
      <v-stepper-items class="mb-12">
        <v-stepper-content
          v-for="n in questions.question.length"
          :key="`${n}-item`"
          :step="n"
        >
          <v-card class="mb-12">
            <v-card-title>
              <v-chip
                >{{ questions.category }} ({{ n }} /
                {{ questions.question.length }})
              </v-chip>
              <br />
            </v-card-title>
            <h2>{{ question.title }}</h2>
            <v-card-text>
              <v-combobox
                v-if="question.type === 'MultipleSelect'"
                :id="'multipleSelect' + n"
                v-model="valueMultipleSelect"
                :items="itemsSelect"
                chips
                clearable
                center
                multiple
              />
              <v-combobox
                v-if="question.type === 'OneSelect'"
                :id="'oneSelect' + n"
                v-model="valueOneSelect"
                :items="itemsSelect"
                chips
                clearable
              />
              <v-text-field
                v-if="question.type === 'InputText'"
                :id="'inputText' + n"
                v-model="valueInputText"
              />
              <v-text-field
                v-if="question.type === 'InputNumber'"
                :id="'inputNumber' + n"
                v-model="valueInputText"
                type="number"
              />
            </v-card-text>
            <v-card-actions>
              <!-- CANCEL -->
              <!-- For desktop -->
              <kdw-btn
                :id="'cancelQuestionsDesktop' + n"
                class="hidden-sm-and-down"
                color="jean"
                text
                @click="cancelQuestions"
              >
                {{ $t("button.cancel") }}
              </kdw-btn>
              <!-- For Mobile -->
              <kdw-btn
                :id="'cancelQuestionsMobile' + n"
                class="hidden-md-and-up"
                color="jean"
                min-width="50"
                text
                @click="cancelQuestions"
              >
                {{ $t("button.cancel") }}
              </kdw-btn>
              <v-spacer />
              <!-- PREVIOUS -->
              <!-- For desktop -->
              <kdw-btn
                v-if="question.hasPrevious"
                :id="'previousQuestionsDesktop' + n"
                class="hidden-sm-and-down"
                color="jean"
                text
                @click="previousStep()"
              >
                {{ $t("button.previous") }}
              </kdw-btn>
              <!-- For Mobile -->
              <kdw-btn
                v-if="question.hasPrevious"
                :id="'previousQuestionsMobile' + n"
                class="hidden-md-and-up"
                color="jean"
                min-width="50"
                text
                @click="previousStep()"
              >
                {{ $t("button.previous") }}
              </kdw-btn>
              <v-spacer />
              <!-- NEXT OR VALIDATE -->
              <!-- For desktop -->
              <kdw-btn
                :id="'nextQuestionsDesktop' + n"
                class="hidden-sm-and-down"
                :color="question.hasNext ? 'jean' : 'primary'"
                right
                @click="nextStep(n + 1)"
              >
                {{
                  question.hasNext
                    ? $t("button.nextQuestion")
                    : $t("button.validateQuestion")
                }}
              </kdw-btn>
              <!-- For Mobile -->
              <kdw-btn
                :id="'nextQuestionsMobile' + n"
                class="hidden-md-and-up"
                :color="question.hasNext ? 'jean' : 'primary'"
                min-width="50"
                right
                @click="nextStep(n + 1)"
              >
                {{
                  question.hasNext
                    ? $t("button.nextQuestion")
                    : $t("button.validateQuestion")
                }}
              </kdw-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";
import {
  cancelQuestions as CcancelQuestions,
  getAnswerFromUser as CgetAnswerFromUser,
  setAnswerForUser as CsetAnswerForUser,
  nextStep as CnextStep,
  previousStep as CpreviousStep,
  // persistQuestions as CpersistQuestions,
  // clearPersistQuestions as CclearPersistQuestions,
  // isPersistQuestionsEmpty as CisPersistQuestionsEmpty,
} from "@/controllers/QuestionsV1";
export default {
  name: "KDWDialogQuestions",

  mixins: [Heading],

  props: {
    parentObj: {
      type: Object,
    },
    questions: {
      type: Object,
    },
  },

  data: () => {
    return {
      stepper: 0, // stepper index starts at 0
      valueMultipleSelect: [],
      valueOneSelect: "",
      valueInputText: "",
      itemsSelect: [], // ?
      question: {}, // current question
      defaultItem: { question: [], category: "", subCategory: "" }, // default
    };
  },
  watch: {
    // whenever question changes, this function will run
    questions(newQuestions, oldQuestions) {
      console.log("Questions changes");
      console.log(newQuestions);
      this.question = {};
      this.valueMultipleSelect = [];
      this.valueOneSelect = "";
      this.valueInputText = "";
      this.itemsSelect = [];
      this.stepper = 1;
    },
    stepper(newStep) {
      console.log("Stepper changes to ", newStep);
      // console.log(this.parentObj)
      this.nextStep(newStep);
    },
  },
  created: function () {},
  methods: {
    cancelQuestions() {
      this.$emit("input", false);
      return CcancelQuestions(this.parentObj, this);
    },
    nextStep(n = null) {
      return CnextStep(this.parentObj, this, n);
    },
    previousStep(n = null) {
      return CpreviousStep(this.parentObj, this, n);
    },
    getAnswerFromUser() {
      return CgetAnswerFromUser(this);
    },
    setAnswerForUser(value = null) {
      return CsetAnswerForUser(this, value);
    },
  },
};
</script>
