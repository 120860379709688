// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the Services for a specific ressource
// ------------------------------------------------------------------------------

import { API, Logger } from "aws-amplify";
import { getResourceListServices } from "@/graphql/kdwqueries";
import {
  createServiceResources as createServiceResourcesMutation,
  deleteServiceResources as deleteServiceResourcesMutation,
} from "@/graphql/mutations";
import { fetchServicesSimpleOnline } from "@/services/Service";
import { compare } from "@/helpers/GenericFunctions";

const logger = new Logger("ServiceResources");

export class ServiceResources {
  serviceAll = [];
  serviceCanAdd = [];
  serviceLinkedRessource = [];
  ressourceIdLocal = "";
  constructor(resourceId) {
    this.ressourceIdLocal = resourceId;
  }

  async fetchExistingServices() {
    this.serviceAll.splice(0, this.serviceAll.length);
    await fetchServicesSimpleOnline(this.serviceAll);
    this.serviceAll.sort(compare);
  }

  async fetchServiceLinkedRessource() {
    try {
      // console.log('fetchServiceLinkedRessource')
      const myResource = await API.graphql({
        query: getResourceListServices,
        variables: { id: this.ressourceIdLocal },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // console.log(myResource.data.getResource)
      // We clean the tables
      this.serviceCanAdd.splice(0, this.serviceCanAdd.length);
      this.serviceLinkedRessource.splice(0, this.serviceLinkedRessource.length);
      // we copy the list of services
      this.serviceCanAdd = [...this.serviceAll];
      for (var i = 0; i < this.serviceCanAdd.length; i++) {
        this.serviceCanAdd.add = false;
      }
      var found;
      var k;
      // We build the existing the list of service already allocated
      if (myResource.data.getResource.services.items.length > 0) {
        const serviceList = myResource.data.getResource.services.items;
        // console.log('serviceList:')
        // console.log(serviceList)
        for (var j = 0; j < serviceList.length; j++) {
          this.serviceLinkedRessource.push({
            id: serviceList[j].service.id,
            name: serviceList[j].service.name,
            serviceResourcesId: serviceList[j].id,
          });
          // We are removing from the list of the services set in serviceCanAdd (already present)
          found = false;
          k = 0;
          while (found === false && k < this.serviceCanAdd.length) {
            if (this.serviceCanAdd[k].id === serviceList[j].service.id) {
              this.serviceCanAdd.splice(k, 1);
              found = true;
            }
            k++;
          }
        }
      }
      this.serviceCanAdd.sort(compare);
      this.serviceLinkedRessource.sort(compare);
      return true;
    } catch (err) {
      logger.error(err);
      return false;
    }
  }

  async fetch() {
    await this.fetchExistingServices();
    // console.log('this.serviceAll')
    // console.log(this.serviceAll)
    await this.fetchServiceLinkedRessource();
    // console.log('this.serviceLinkedRessource')
    // console.log(this.serviceLinkedRessource)
    // console.log('this.serviceCanAdd')
    // console.log(this.serviceCanAdd)
  }

  // We are creating in DB the ServiceResources requested by the customer
  async addSelectedServices() {
    try {
      // console.log('addSelectedServices')
      var myServiceResources;
      for (var i = 0; i < this.serviceCanAdd.length; i++) {
        if (this.serviceCanAdd[i].add) {
          // graphqlV1-->V2: myServiceResources = { serviceResourcesServiceId: this.serviceCanAdd[i].id, serviceResourcesResourceId: this.ressourceIdLocal, resourceID: this.ressourceIdLocal }
          myServiceResources = {
            serviceResourcesId: this.serviceCanAdd[i].id,
            resourceServicesId: this.ressourceIdLocal,
            resourceID: this.ressourceIdLocal,
          };
          await API.graphql({
            query: createServiceResourcesMutation,
            variables: { input: myServiceResources },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          // We need to create a record
          // console.log(this.serviceCanAdd[i].name, 'to be created')
        }
      }
    } catch (err) {
      logger.error(err);
      return false;
    }
  }

  async deleteService(index) {
    try {
      await API.graphql({
        query: deleteServiceResourcesMutation,
        variables: {
          input: { id: this.serviceLinkedRessource[index].serviceResourcesId },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      this.serviceLinkedRessource.splice(index, 1);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}
