<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to manage the authentication:
// Props:
//   - parent: pointer to the object.
//   - navigateNext: page to navigate to. By default, this is 'HOME'
//
// Example of usage:
  <kdw-dialog-authenticator
    v-model="dialogCardInfo"
    :parent="this"
  />
// ------------------------------------------------------------------------------>

<template>
  <v-dialog persistent v-bind="$attrs" v-on="$listeners">
    <v-card>
      <v-card-title>
        <base-info-card :title="$t('hero.authenticator')" />
      </v-card-title>
      <kdw-auth-component
        :sign-up-type="ENDUSER"
        :navigate-next="navigateNext"
      />
      <kdw-btn text @click="closeDialog">
        {{ $t("button.cancel") }}
      </kdw-btn>
    </v-card>
  </v-dialog>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";
import { cancelQuestions as CcancelQuestions } from "@/controllers/Questions";
import { ENDUSER } from "@/services/User";

export default {
  name: "KDWDialogAuthenticator",

  mixins: [Heading],

  props: {
    parent: {
      type: Object,
    },
    navigateNext: {
      type: String,
      default: "HOME",
    },
  },

  data: () => {
    return {
      ENDUSER: ENDUSER,
    };
  },
  created: function () {},
  methods: {
    closeDialog() {
      this.$emit("input", false);
      return CcancelQuestions(parent);
    },
  },
};
</script>
