import { API } from "aws-amplify";
import { doStateMachine } from "../graphql/kdwmutations";

const TRANSITIONS = {
  request: {
    createFixedPrice: "createFixedPrice",
    createQuotationRequest: "createQuotationRequest",
    cancelledByEU: "cancelledByEU",
  },
  answer: {
    onSiteQuotationAnswer: "onSiteQuotationAnswer",
    upsertAnswer: "upsertAnswer",
    attachServiceRequest: "attachServiceRequest",
    cancelledBySP: "cancelledBySP",
    refusedQuoteByEU: "refusedQuoteByEU",
    acceptQuoteByEU: "acceptQuoteByEU",
  },
  appointment: {
    createAppointment: "createAppointment",
  },
};

/**
 *
 * @param serviceId - mandatory
 * @param appointmentDate - optional, Date string in format yyyy-mm-dd
 * @param appointmentTime - mandatory, Time string in format hh:mm
 * @returns
 */
export async function createFixedPriceRequest({
  serviceId,
  appointmentDate,
  appointmentTime,
}) {
  try {
    const params = JSON.stringify({
      serviceId,
      appointmentDate,
      appointmentTime,
    });
    // console.log('params in createFixedPriceRequest *************************************')
    // console.log(params)
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "REQUEST",
        transition: TRANSITIONS.request.createFixedPrice,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    // console.log('Data from createFixedPriceRequest')
    // console.log(data)
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function createQuotationRequest({
  serviceId,
  qna,
  postalCodeId,
  isProposalSP,
  categoryID,
  subCategoryID,
  requestName,
}) {
  try {
    const params = JSON.stringify({
      serviceId,
      qna,
      postalCodeId,
      isProposalSP,
      categoryID,
      subCategoryID,
      requestName,
    });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "REQUEST",
        transition: TRANSITIONS.request.createQuotationRequest,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function onSiteQuotationRequest({ requestId }) {
  try {
    const params = JSON.stringify({ requestId });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "ANSWER",
        transition: TRANSITIONS.answer.onSiteQuotationAnswer,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function upsertAnswer({
  serviceId,
  answerId,
  requestId,
  servicePrice,
  noteCustomer,
  notePersonal,
  serviceLegaldescription,
  filename,
  skipEmailFlag,
}) {
  try {
    const params = JSON.stringify({
      serviceId,
      answerId,
      requestId,
      servicePrice,
      noteCustomer,
      notePersonal,
      serviceLegaldescription,
      filename,
      skipEmailFlag,
    });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "ANSWER",
        transition: TRANSITIONS.answer.upsertAnswer,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function attachServiceRequest({ serviceId, requestId }) {
  try {
    const params = JSON.stringify({ serviceId, requestId });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "ANSWER",
        transition: TRANSITIONS.answer.attachServiceRequest,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function cancelledByEU({ requestId }) {
  try {
    const params = JSON.stringify({ requestId });
    console.log(params);
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "REQUEST",
        transition: TRANSITIONS.request.cancelledByEU,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function cancelledBySP({ answerId }) {
  try {
    const params = JSON.stringify({ answerId });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "ANSWER",
        transition: TRANSITIONS.answer.cancelledBySP,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function refusedQuoteByEU({ answerId }) {
  try {
    const params = JSON.stringify({ answerId });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "ANSWER",
        transition: TRANSITIONS.answer.refusedQuoteByEU,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function acceptQuoteByEU({ answerId, serviceId }) {
  try {
    const params = JSON.stringify({ answerId, serviceId });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "ANSWER",
        transition: TRANSITIONS.answer.acceptQuoteByEU,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function createAppointment({
  dateTime,
  serviceId,
  resourceId,
  answerId,
  slotDate,
  slotTime,
}) {
  try {
    const params = JSON.stringify({
      dateTime,
      serviceId,
      resourceId,
      answerId,
      slotDate,
      slotTime,
    });
    const { data } = await API.graphql({
      query: doStateMachine,
      variables: {
        type: "APPOINTMENT",
        transition: TRANSITIONS.appointment.createAppointment,
        params,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return _processResponse(data);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function _processResponse(data) {
  try {
    return JSON.parse(data.doStateMachine);
  } catch (err) {
    console.log(data);
    throw err;
  }
}
