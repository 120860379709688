import Vue from "vue";
import VueI18n from "vue-i18n";
// https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65
import { I18n } from "aws-amplify";
import AmplifyI18n from "amplify-i18n";

import en from "../locales/en.js";
import fr from "../locales/fr.js";
import { loadBrowserLocale } from "@/helpers/Navigation";

Vue.use(VueI18n);

// https://github.com/Locale/amplify-i18n
// load only specific locales
AmplifyI18n.configure(["en", "fr"]);

I18n.setLanguage(loadBrowserLocale());

export default new VueI18n({
  locale: loadBrowserLocale(),
  fallbackLocale: "fr",
  messages: {
    en,
    fr,
  },
});
