<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to manage the booking for a specific service requested by the end user.
// Props:
// - v-model: used to display on/off the dialog
// - service-id: service ID of the service we would like to book.
// - answer-id: used to attached the booking to the answer created for the user. Optional: default is ''
// - navigationObject: vue object to be use to point to, to make sure the navigation is working onto the next page. This is an optional field
// - event: this prop is designed to return an event with the info passed. this should contain the event name (eventName), separate with a ; and then the data to be return. This event is triggered when the validation of the booking is done. This is an optional field.
// - @eventName: this funciton is trigger when the validate function is passed.
// - @cancelDialogBooking: this function has been added to be used in hero page or equivalent to be informed when the user is not planning to continue the appointment booking (case we book first)
// - @oKDialogBookingHero: this function has been added to be used in hero page or equivalent to be informed when the user is planning to continue the appointment booking (case we book first)
// Example of usage:
        <kdw-dialog-booking
          v-model="test"
          service-id="4a24fd5c-04bc-48e5-a2cb-2be5b4ad2090"
          answerId=""
          navigationObject="this"
          event="eventName;4a2IDd5c-04ID-48ID-a2cb-2be5b4ad20ID"
          @eventName="eventName($emit)"
        />
// with an example of the variable to use:
//
// ------------------------------------------------------------------------------>

<template>
  <v-container>
    <!----- This is a dialog box to edit the slot  --->
    <v-dialog v-model="dialogBooking" max-width="900px" scrollable persistent>
      <v-card>
        <v-card-title>
          <base-info-card
            :title="$t('Booking.dialogBookingTitle')"
            color="primary"
          />
        </v-card-title>
        <v-card-text>
          <v-row dense class="blue lighten-5">
            <v-col cols="12" sm="9">
              {{ serviceName }}
            </v-col>
            <v-col cols="12" sm="3">
              <v-chip outlined> {{ serviceDuration }} min. </v-chip>
            </v-col>
          </v-row>
          <v-row align="center" justify="space-around">
            <v-icon x-large @click="previousDate()"> mdi-skip-previous </v-icon>
            <kdw-Date-Picker
              :key="'Date-Picker' + trickDisplay"
              v-model="date"
              @dateValidated="dateValidated()"
            />
            <v-icon x-large @click="nextDate()"> mdi-skip-next </v-icon>
          </v-row>
          <v-row>
            <v-expansion-panels v-model="panel" inset multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template #default="{ open }">
                    <v-row no-gutters>
                      <v-col cols="12">
                        {{ $t("Booking.ressourceTitle") }}
                        <b>{{ selectedRessource.name }}</b>
                      </v-col>
                      <v-col cols="8" class="text--secondary">
                        <!--
                        <v-chip
                          class="deep-purple accent-4 white--text"
                        >
                          {{ selectedRessource.name }}
                        </v-chip>
                        -->
                        <!--
                        <v-fade-transition leave-absolute>
                          <span
                            v-if="open"
                            key="0"
                          >
                            {{ $t('Booking.selectRessource') }}
                          </span>
                          <span
                            v-else
                            key="1"
                          >
                            We can have the name of the selected ressource
                          </span>
                        </v-fade-transition>
                        -->
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet>
                    <v-row align="center" justify="space-around">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-badge
                            bordered
                            color="green"
                            :value="selectedRessource.badge"
                            overlap
                          >
                            <v-avatar
                              color="indigo"
                              v-bind="attrs"
                              v-on="on"
                              @click="selectAllRessources()"
                            >
                              <v-icon dark> mdi-account-group </v-icon>
                            </v-avatar>
                          </v-badge>
                        </template>
                        <span> {{ $t("Booking.selectAnyRessources") }}</span>
                      </v-tooltip>
                      <v-tooltip
                        v-for="(bookingData, i) in bookingDatas"
                        :key="i"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-badge
                            bordered
                            color="green"
                            overlap
                            :value="bookingData.badge"
                          >
                            <v-avatar
                              v-bind="attrs"
                              v-on="on"
                              @click="selectRessource(bookingData, i)"
                            >
                              <img
                                v-show="bookingData.img !== ''"
                                :src="bookingData.img"
                                :alt="bookingData.shortName"
                              />
                              <v-img
                                v-show="bookingData.img === ''"
                                :src="require('@/assets/Avatar-02.svg')"
                              />
                            </v-avatar>
                          </v-badge>
                          <!--
                          <v-avatar
                            v-bind="attrs"
                            v-on="on"
                            @click="selectRessource(bookingData, i)"
                          >
                            <v-img
                              :src="bookingData.img"
                            />
                          </v-avatar>
                          -->
                        </template>
                        <span>{{ bookingData.name }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template #default="{ open }">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <!-- {{ $t('Booking.AppointmentTitle') }} <b> {{ selectedSlot.time }}</b> -->
                        <b>{{ returnTimeSlotSelected() }}</b>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet>
                    <v-row align="center" justify="space-around">
                      <!-- active-class="deep-purple accent-4 white--text" -->
                      <v-chip-group
                        v-for="(timeSlot, j) in timeSlotForSelectedRessources"
                        :key="j"
                        column
                      >
                        <v-chip @click="selectTimeSlot(timeSlot)">
                          {{ timeSlot.time }}
                        </v-chip>
                      </v-chip-group>
                    </v-row>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <kdw-btn text @click="closeDialogEditSlot()">
            {{ $t("button.cancel") }}
          </kdw-btn>
          <kdw-btn @click="saveDialogEditSlot()">
            {{ $t("button.validateQuestion") }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { buildMinutesTable, isTimeStartBiggerTimeEnd } from '@/helpers/GenericFunctionsTime'
import { Kscheduler } from "@/services/Scheduler";
import { ResourceList } from "@/services/ResourceList";
import { createAppointment } from "@/services/Appointment";
import { fetchNameDuration } from "@/services/Service";
import { navigate } from "@/helpers/Navigation";
import { getUserSub } from "@/services/User";

export default {
  name: "WorktimeDay",

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    serviceId: {
      type: String,
      default: "",
    },
    answerId: {
      type: String,
      default: "",
    },
    navigationObject: {
      type: Object,
    },
    event: {
      type: String,
      default: "",
    },
  },

  data: () => {
    return {
      selectedRessource: { selected: false, name: "", id: "" },
      // the selected slot contains true in selected if selected by the enduser, it constain the date and the slit time selected and the Id of the ressource selected.
      selectedSlot: {
        selected: false,
        date: "1900-01-01",
        time: "No Slot Selected",
        id: "",
      },
      // this is a dynamic table containing the slot list that combine the slots of all the selected ressources (can be only 1 ressource)
      timeSlotForSelectedRessources: [],
      dialogBooking: false,
      // date of the wanted booking
      date: "2021-09-01",
      trickDisplay: 0,
      scheduler: new Kscheduler(),
      // Used to open the expension panel. Not sure why we need 0 and 1 but this is working.
      panel: [0, 1],
      serviceIDLocal: "",
      // List of the ressources and associated slots
      resourceList: new ResourceList(),
      bookingDatas: [],
      serviceName: "",
      serviceDuration: "",
      /*
        bookingDatas: [{
                         id: '123',
                         name: 'John Test',
                         shortName: 'JT',
                         img: 'https://cdn.vuetifyjs.com/images/john.jpg',
                         availabilities: {
                           '2021-11-13': [{ time: '09:00', available: true, reference: null },
                                          { time: '09:15', available: true, reference: null },
                                          { time: '09:30', available: true, reference: null },
                           ],
                           '2021-11-14': [{ time: '11:00', available: true, reference: null },
                                          { time: '11:15', available: true, reference: null },
                                          { time: '11:30', available: true, reference: null },
                           ],
                         },
                       },
                       {
                         id: '456',
                         name: 'Bob Test',
                         shortName: 'BT',
                         img: 'https://cdn.vuetifyjs.com/images/john.jpg',
                         availabilities: {
                           '2021-11-13': [{ time: '09:00', available: true, reference: null },
                                          { time: '10:00', available: true, reference: null },
                                          { time: '10:15', available: true, reference: null },
                                          { time: '10:30', available: true, reference: null },
                           ],
                         },
                       },
        ],
        */
      /*
        bookingDatas: [{
                         id: '123',
                         name: 'John Test',
                         shortName: 'JT',
                         img: 'https://cdn.vuetifyjs.com/images/john.jpg',
                         availabilities: {
                           '2021-11-13': [{ time: '09:00' },
                                          { time: '09:15' },
                                          { time: '09:30' },
                           ],
                           '2021-11-14': [{ time: '11:00' },
                                          { time: '11:15' },
                                          { time: '11:30' },
                           ],
                         },
                       },
                       {
                         id: '456',
                         name: 'Bob Test',
                         shortName: 'BT',
                         img: 'https://cdn.vuetifyjs.com/images/john.jpg',
                         availabilities: {
                           '2021-11-13': [{ time: '09:00' },
                                          { time: '10:00' },
                                          { time: '10:15' },
                                          { time: '10:30' },
                           ],
                         },
                       },
        ],
        */
    };
  },

  watch: {
    value: async function (newValue) {
      // console.log('I am in dialog booking:', newValue)
      // only when the booking dialog must be open
      if (newValue === true) {
        // console.log('dialog booking is true')
        // We are cleaning the selected resource
        this.selectedRessource = { selected: false, name: "", id: "" };
        this.resetBookingDatas();
        // We initialise the set of data
        this.initialise();
      }
      this.dialogBooking = newValue;
    },
    serviceId: async function (newValue) {
      // We are checking to see if the service ID is changed
      // await this.refreshServiceID(newValue)
    },
  },

  created: function () {
    this.initialise();
  },

  methods: {
    resetBookingDatas() {
      this.bookingDatas.splice(0, this.bookingDatas.length);
    },

    async initialise() {
      // console.log('We pass in initialize function', this.value)
      this.dialogBooking = this.value;
      var today = new Date();
      // var nextday = new Date(today.getTime() + (24 * 60 * 60 * 1000))
      this.date = today.toISOString().substring(0, 10);
      // nextday = new Date(today.getTime() + (24 * 60 * 60 * 1000 * 9))
      // console.log(this.date)
      // const nextDayString = nextday.toISOString().substring(0, 10)
      this.selectedRessource.name = this.$i18n.t(
        "Booking.anyRessourceSelected",
      );
      // we initialise the available slots for the date and all the ressources.
      this.computeSlotForSelectedRessources();
      // console.log('resourceList after computeSlotForSelectedRessources:')
      // console.log(this.resourceList)
      this.selectedSlot.time = this.$i18n.t("Booking.noSlotSelected");
      // console.log('this.serviceId', this.serviceId)
      // We are updating the data linked to the serviceID
      if (this.dialogBooking) await this.refreshServiceID(this.serviceId);
      // console.log('resourceList after computeSlotForSelectedRessources after refreshServiceID:')
      // console.log(this.resourceList)
      this.trickDisplay++;
    },

    async refreshServiceID(newServiceID) {
      // console.log('refreshServiceID from Booking')
      if (newServiceID !== "") {
        this.serviceIDLocal = newServiceID;
        fetchNameDuration(this.serviceIDLocal).then((service) => {
          this.serviceName = service.name;
          this.serviceDuration = service.duration;
        });
        await this.resourceList.loadResourcesServiceCanBookOnline(
          this.serviceIDLocal,
        );
        // console.log('We pass the loadResourcesServiceCanBookOnline and going to getavailability')
        // we are searching for the default availability for the selected day.
        this.scheduler.getAvailability(
          this.resourceList.list,
          this.bookingDatas,
          this.date,
          this.serviceDuration,
        );
        // console.log('We pass the getavailability')
        this.computeSlotForSelectedRessources();
        // we are adding the badge to the list of ressources
        this.clearBookingDatasBadge();
        this.selectedRessource.badge = true;
        // console.log('this.resourceList', this.resourceList)
        // console.log('this.bookingDatas', this.bookingDatas)
        // this.dialogBooking = this.value
        // console.log('this.dialogBooking', this.dialogBooking)
      }
    },
    emitResult() {
      this.$emit("input", this.dialogBooking);
    },
    clearBookingDatasBadge() {
      for (var i = 0; i < this.bookingDatas.length; i++) {
        this.bookingDatas[i].badge = false;
      }
      this.selectedRessource.badge = false;
    },
    restoreBookingDataBadge(localId) {
      if (localId === "") {
        this.selectedRessource.badge = true;
      } else {
        var found = false;
        var i = this.bookingDatas.length - 1;
        if (i >= 0) {
          while (!found) {
            if (this.bookingDatas[i].id === localId) {
              this.bookingDatas[i].badge = true;
              found = true;
            }
            i--;
          }
        }
      }
    },
    returnTimeSlotSelected() {
      if (this.timeSlotForSelectedRessources.length > 0) {
        return (
          this.$i18n.t("Booking.AppointmentTitle") +
          " " +
          this.selectedSlot.time
        );
      } else {
        return this.$i18n.t("Booking.noAppointmentPossible");
      }
    },
    closeDialogEditSlot() {
      // We check if this is a cancel from the Hero page
      if (
        this.navigationObject !== null &&
        this.navigationObject !== undefined &&
        this.navigationObject.bookingOnlineFromHero
      ) {
        // We will need to clean the picked element.
        this.$emit("cancelDialogBooking", null);
        this.dialogBooking = false;
        this.emitResult();
        return;
      }
      this.dialogBooking = false;
      this.emitResult();
      if (this.navigationObject !== undefined) {
        navigate(this.navigationObject, "EndUserMyServices");
      }
    },
    previousDate() {
      // We are blokcing to book in the past or present day
      var realToday = new Date();
      // console.log('realToday', realToday)
      // var realTomorrow = new Date(realToday.getTime() + (24 * 60 * 60 * 1000))
      var today = new Date(this.date);
      // console.log('this.date', today)
      if (today <= realToday) {
        this.$root.kdwsnackbar.alert("Booking.noBookingPast");
        return;
      }
      // We are then computing the day before as it is still in the future
      var nextday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
      this.date = nextday.toISOString().substring(0, 10);
      const localId = this.selectedRessource.id;
      this.trickDisplay++;
      this.scheduler.getAvailability(
        this.resourceList.list,
        this.bookingDatas,
        this.date,
        this.serviceDuration,
      );
      this.computeSlotForSelectedRessources();
      // We restore the badge value according to the value found at the beginning
      this.clearBookingDatasBadge();
      this.restoreBookingDataBadge(localId);
    },
    nextDate() {
      var today = new Date(this.date);
      var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
      this.date = nextday.toISOString().substring(0, 10);
      // We need to check who is selected
      // console.log('this.selectedRessource', this.selectedRessource)
      const localId = this.selectedRessource.id;
      this.trickDisplay++;
      this.scheduler.getAvailability(
        this.resourceList.list,
        this.bookingDatas,
        this.date,
        this.serviceDuration,
      );
      this.computeSlotForSelectedRessources();
      // We restore the badge value according to the value found at the beginning
      this.clearBookingDatasBadge();
      this.restoreBookingDataBadge(localId);
      // console.log('timeSlotForSelectedRessources', this.timeSlotForSelectedRessources)
    },
    dateValidated() {
      // console.log('date validated')
      this.trickDisplay++;
      this.scheduler.getAvailability(
        this.resourceList.list,
        this.bookingDatas,
        this.date,
        this.serviceDuration,
      );
      this.computeSlotForSelectedRessources();
    },

    saveDialogEditSlot() {
      // We check if a slot is slected
      if (!this.selectedSlot.selected) {
        // Alert as the slot & ressources are nor selected
        this.$root.kdwsnackbar.alert("Booking.noSlotRessourceSelected");
        return;
      }
      // We check if this is coming from hero page:
      if (
        this.navigationObject !== null &&
        this.navigationObject !== undefined &&
        this.navigationObject.bookingOnlineFromHero
      ) {
        // TO COMPLETE ----- TO DO
        // console.log('Debug from selected appointment')
        // console.log(this.selectedSlot)
        // console.log(this.serviceId)
        // console.log(this.serviceName)
        // console.log(this.serviceDuration)
        // console.log(this.resourceList.list)
        // console.log(this.scheduler)
        // console.log(this.answerId)
        // We will need to clean the picked element.
        var appointmentData = {};
        // console.log('Je passe ici dans saveDialogEditSlot - 1')
        appointmentData.selectedSlot = this.selectedSlot;
        // console.log('Je passe ici dans saveDialogEditSlot - 2')
        appointmentData.serviceId = this.serviceId;
        // console.log('Je passe ici dans saveDialogEditSlot - 3')
        appointmentData.serviceName = this.serviceName;
        // console.log('Je passe ici dans saveDialogEditSlot - 4')
        appointmentData.serviceDuration = this.serviceDuration;
        // console.log('Je passe ici dans saveDialogEditSlot - 5')
        // Copy the ressource list
        appointmentData.resourceList = new ResourceList();
        // console.log('Je passe ici dans saveDialogEditSlot - 6')
        appointmentData.resourceList.copy(this.resourceList);
        appointmentData.scheduler = this.scheduler;
        // console.log('Appointment data before emitting')
        // console.log(appointmentData)
        this.$emit("oKDialogBookingHero", appointmentData);
        this.dialogBooking = false;
        this.emitResult();
        return;
      }
      // TO CHECK THIS PART LATER ON............................................BRUNO
      // We manage the other case
      // We need to create the appointment
      getUserSub().then((userSub) => {
        createAppointment(
          this.selectedSlot,
          this.serviceId,
          this.serviceName,
          this.serviceDuration,
          this.resourceList.list,
          this.scheduler,
          this.answerId,
          userSub,
        ).then((result) => {
          if (result) {
            this.$root.kdwsnackbar.alert("Booking.bookingDone");
            // We need to close the dialog
            this.closeDialogEditSlot();
            // We check if we need to emit an event.
            if (this.event !== "") {
              const eventData = this.event.split(";");
              if (eventData.length === 2) {
                // console.log('eventData')
                // console.log(eventData)
                // we create an event with the data sent to the parent
                this.$emit(eventData[0], eventData[1]);
              }
            }
          }
        });
      });
    },
    selectRessource(bookingData, i) {
      // console.log('ressource selected', bookingData.name)
      this.selectedRessource.name = bookingData.name;
      this.selectedRessource.id = bookingData.id;
      this.selectedRessource.selected = true;
      // We manage the badge visibility
      this.clearBookingDatasBadge();
      this.bookingDatas[i].badge = true;
      this.computeSlotForSelectedRessources();
    },
    selectAllRessources() {
      this.selectedRessource.name = this.$i18n.t(
        "Booking.anyRessourceSelected",
      );
      this.selectedRessource.id = "";
      this.selectedRessource.selected = false;
      this.clearBookingDatasBadge();
      this.selectedRessource.badge = true;
      this.computeSlotForSelectedRessources();
    },
    selectTimeSlot(timeSlot) {
      // console.log(timeSlot)
      this.selectedSlot.time = timeSlot.time;
      this.selectedSlot.date = this.date;
      this.selectedSlot.selected = true;
      this.selectedSlot.id = timeSlot.id;
    },
    resetSlotTime() {
      this.selectedSlot.time = this.$i18n.t("Booking.noSlotSelected");
      this.selectedSlot.selected = false;
      this.selectedSlot.date = "1900-01-01";
      this.selectedSlot.id = "";
    },
    isSlotAlreadyProvisionned(timeSlotToAdd) {
      var found = false;
      var i = 0;
      while (found === false && i < this.timeSlotForSelectedRessources.length) {
        if (this.timeSlotForSelectedRessources[i].time === timeSlotToAdd) {
          found = true;
          return true;
        }
        i++;
      }
      return false;
    },
    compare(a, b) {
      const bandA = a.time;
      const bandB = b.time;

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    },
    computeSlotForSelectedRessources() {
      // console.log('computeSlotForSelectedRessources')
      // empty the table
      this.timeSlotForSelectedRessources.splice(
        0,
        this.timeSlotForSelectedRessources.length,
      );
      // one specific ressource have been selected, we need to build the table with the existing slots of this ressource
      if (this.selectedRessource.selected === true) {
        // We are searching for the selected ressource:
        var found = false;
        var j = 0;
        // console.log('this.selectedRessource.id:')
        // console.log(this.selectedRessource.id)
        // console.log('this.bookingDatas[j].id')
        // console.log(this.bookingDatas[j].id)
        while (found === false && j < this.bookingDatas.length) {
          if (this.bookingDatas[j].id === this.selectedRessource.id) {
            found = true;
            // console.log(this.bookingDatas[j].availabilities)
            // console.log(this.date)
            // console.log(this.bookingDatas[j].availabilities[this.date])
            if (this.bookingDatas[j].availabilities[this.date] !== undefined) {
              for (
                var k = 0;
                k < this.bookingDatas[j].availabilities[this.date].length;
                k++
              ) {
                // if (this.bookingDatas[j].availabilities[this.date][k].available === true) {
                this.timeSlotForSelectedRessources.push({
                  time: this.bookingDatas[j].availabilities[this.date][k].time,
                  id: this.bookingDatas[j].id,
                });
                // }
              }
            }
            this.resetSlotTime();
            return;
          }
          j++;
        }
        // console.log('this.timeSlotForSelectedRessources')
        // console.log(this.timeSlotForSelectedRessources)
      } else {
        // console.log('No specific ressource have been selected.')
        // No specific ressource have been selected.
        // console.log(this.bookingDatas)
        for (var a = 0; a < this.bookingDatas.length; a++) {
          // console.log(this.bookingDatas[a].availabilities)
          // console.log(this.date)
          // console.log(this.bookingDatas[a].availabilities[this.date])
          // console.log(a)
          if (this.bookingDatas[a].availabilities[this.date] !== undefined) {
            for (
              var b = 0;
              b < this.bookingDatas[a].availabilities[this.date].length;
              b++
            ) {
              // if (this.bookingDatas[a].availabilities[this.date][b].available === true) {
              // console.log(this.bookingDatas[a].availabilities[this.date][b].time)
              if (
                this.isSlotAlreadyProvisionned(
                  this.bookingDatas[a].availabilities[this.date][b].time,
                ) === false
              ) {
                // console.log('isSlotAlreadyProvisionned === false')
                // console.log('push in: ', this.bookingDatas[a].availabilities[this.date][b].time)
                this.timeSlotForSelectedRessources.push({
                  time: this.bookingDatas[a].availabilities[this.date][b].time,
                  id: this.bookingDatas[a].id,
                });
              }
              // }
            }
          }
        }
        // console.log('this.timeSlotForSelectedRessources:')
        // console.log(this.timeSlotForSelectedRessources)
      }
      // We need to sort out the data.
      this.timeSlotForSelectedRessources.sort(this.compare);
      // console.log('this.timeSlotForSelectedRessources after sorting:')
      // console.log(this.timeSlotForSelectedRessources)
      this.resetSlotTime();
    },
  },
};
</script>
