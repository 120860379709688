import i18n from "@/plugins/i18n.js";
import { DateTime } from "luxon";

// convert minutes into Day, Hour, Min array (in this order
function convertMintoDHM(min, DHMArray) {
  // console.log('convertMintoDHM')
  // Compute days
  const day = Math.trunc(min / 1440);
  DHMArray.push(day);
  // Compute hours
  const hour = Math.trunc((min - 1440 * day) / 60);
  DHMArray.push(hour);
  // Compute remaining minutes
  DHMArray.push(min - 1440 * day - hour * 60);
}

// Format the day hour minutes into a meaningful string (X day, Y hours, Z mins)
export function formatServiceDurationFromDHM(day, hour, min) {
  // console.log('formatServiceDurationFromDHM')
  var duration = "";
  if (day !== 0) {
    duration = day + "j ";
  }
  if (hour !== 0) {
    duration = duration + hour + "h ";
  }
  if (min !== 0) {
    duration = duration + min + "m";
  }
  return duration;
}

// Format the minutes into a meaningful string (X day, Y hours, Z mins)
export function formatServiceDurationFromM(min) {
  // console.log('formatServiceDurationFromM')
  var duration = "";
  var DHMArray = [];
  convertMintoDHM(min, DHMArray);
  // console.log(DHMArray)
  duration = formatServiceDurationFromDHM(
    DHMArray[0],
    DHMArray[1],
    DHMArray[2],
  );
  return duration;
}

// Help to format the price depending on the nature of it 'On demand' or 'Price itself' in value
export function formatServicePrice(isFixedPrice, ServicePrice) {
  var price = "";
  if (isFixedPrice === false) {
    price = i18n.t("formatterLib.quotationBased");
  } else {
    price = ServicePrice;
  }
  return price;
}

// Help to format a floating number with space to make it more easy to read. Exmple: 1000000 --> 1 000 000
function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

// Help to format the price depending on the nature of it 'On demand' or 'Price itself' in String
export function formatServicePriceString(
  isFixedPrice,
  ServicePrice,
  checkServicePrice = false,
) {
  var price = "";
  // console.log('formatServicePriceString:', isFixedPrice, ServicePrice, checkServicePrice)
  if (isFixedPrice === false) {
    if (checkServicePrice) {
      if (isNaN(ServicePrice)) {
        price = i18n.t("formatterLib.quotationBased");
      } else {
        price = numberWithSpaces(ServicePrice).toString() + "€ TTC";
      }
    } else price = i18n.t("formatterLib.quotationBased");
  } else {
    price = numberWithSpaces(ServicePrice).toString() + "€ TTC";
  }
  // console.log('formatServicePriceString - result:', price)
  return price;
}

// Help to format the price value according to the country. Adding 0 if missing 1 digit at the end.
export function formatServicePriceValue(servicePriceValue, country) {
  if (country === "fr") {
    var priceValue = servicePriceValue.toString();
    var priceSplit = priceValue.split(",");
    if (priceSplit.length > 1) {
      // it means that we have a number with digit
      if (priceSplit[1].length < 2) return priceValue + "0";
    }
    priceSplit = priceValue.split(".");
    if (priceSplit.length > 1) {
      // it means that we have a number with digit
      if (priceSplit[1].length < 2) return priceValue + "0";
    }
  }
  return servicePriceValue;
}

// Help to format the AWS Date Time into undertsandable format. Exemple: 2021-05-18T21:33:15.917Z --> 2021-05-18 21:33
export function formatAWSDateTimeIntoDateTime(DateTimeAWS) {
  return DateTime.fromISO(DateTimeAWS).toFormat("yyyy-LL-dd HH:mm");
  // return DateTimeAWS.substring(0, 10) + ' ' + DateTimeAWS.substring(11, 16)
}

// Help to format the AWS Date Time into undertsandable date format. Exemple: 2021-05-18T21:33:15.917Z --> 2021-05-18
export function formatAWSDateTimeIntoDate(DateTimeAWS) {
  return DateTime.fromISO(DateTimeAWS).toFormat("yyyy-LL-dd");
  // return DateTimeAWS.substring(0, 10)
}

// This is a function to translate the category or subCategory name into the rigth value
// We need to check if we need to translate the subcategory.
export function formatCategoryOrSubCategoryName(
  categoryDefaultName,
  categoryCountryCode,
  categoryNameTag,
) {
  var catName = categoryDefaultName;
  // console.log('categoryDefaultName : ' + categoryDefaultName)
  if (categoryCountryCode !== i18n.locale) {
    if (categoryNameTag !== null && categoryNameTag !== "") {
      if (i18n.t(categoryNameTag) !== undefined) {
        catName = i18n.t(categoryNameTag);
      }
    }
  }
  // console.log('catName : ' + catName)
  return catName;
}

// const today = new Date();
// formatDate(today, 'DD-MM-YYYY HH:mm');
/* export function formatDate (date, format) {
    return moment(date).format(format)
} */

// take a date in the string format '2021-10-11', collect the time and the MM associated to this date and return the date & time in UTC time
export function convertDateTimeStringLocalUTC(date, HH, MM) {
  // console.log('______________________________________________')
  // console.log('convertDateTimeStringLocalUTC')
  // console.log('Date received:', date + ' ' + HH + ':' + MM)
  // const dateResult = moment(date + ' ' + HH + ':' + MM).utc().format('YYYY-MM-DD HH:mm')
  const dateResult = DateTime.fromISO(date + "T" + HH + ":" + MM).setZone(
    "utc",
  );
  // console.log('Date received UTC:', dateResult)
  // console.log('Date UTC:', dateResult.substring(0, 10))
  // console.log('Hour UTC:', dateResult.substring(11, 13))
  // console.log('Minutes UTC:', dateResult.substring(14, 16))

  // return a date, HH, MM in UTC time
  return {
    date: dateResult.toISODate(),
    HH: dateResult.toFormat("HH"),
    MM: dateResult.toFormat("mm"),
  };
}

// take a date in the string format '2021-10-11', collect the time and the MM associated to this date and return the date & time in UTC AWSDatetime
export function convertAWSDateTimeStringLocalUTC(date, HH, MM) {
  // console.log('______________________________________________')
  // console.log('convertDateTimeStringLocalUTC')
  // console.log('Date received:', date + ' ' + HH + ':' + MM)
  // const dateResult = moment(date + ' ' + HH + ':' + MM).utc().format('YYYY-MM-DDTHH:mm')
  const dateResult = DateTime.fromISO(date + "T" + HH + ":" + MM)
    .setZone("utc")
    .toISO();
  // console.log('Date received UTC:', dateResult)
  // console.log('Date UTC:', dateResult.substring(0, 10))
  // console.log('Hour UTC:', dateResult.substring(11, 13))
  // console.log('Minutes UTC:', dateResult.substring(14, 16))

  // return a AWSDateTime format in UTC time: 2021-11-23T19:46:13.806Z
  // return dateResult + ':00.000Z'
  return dateResult;
}

// take a date in the string format '2021-10-11', collect the time and the MM associated to this date and return the date & time in local time
export function convertDateTimeStringUTCLocal(date, HH, MM) {
  // console.log('______________________________________________')
  // console.log('convertDateTimeStringUTCLocal')
  // console.log('Date received:', date + ' ' + HH + ':' + MM)
  // console.log('Date received UTC:', moment(date + ' ' + HH + ':' + MM).utc().format())
  var dateResult = DateTime.fromISO(date + "T" + HH + ":" + MM + ":00Z");
  // const dateResult = moment(localTime).format('YYYY-MM-DD HH:mm')
  // console.log('Date received local:', dateResult)
  // console.log('Date UTC:', dateResult.substring(0, 10))
  // console.log('Hour UTC:', dateResult.substring(11, 13))
  // console.log('Minutes UTC:', dateResult.substring(14, 16))
  // console.log('______________________________________________')

  // return a date, HH, MM in local time
  return {
    date: dateResult.toISODate(),
    HH: dateResult.toFormat("HH"),
    MM: dateResult.toFormat("mm"),
    dateISO: dateResult.toISO(),
  };
}
