// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the postal code
// ------------------------------------------------------------------------------

// https://github.com/aws-amplify/amplify-js/issues/2233
// A regarder, Alternatives pour geolocaliser
// https://geo.api.gouv.fr/adresse
// https://console.cloud.google.com/apis/library/places-backend.googleapis.com
// Not used now but could be used for France
// https://github.com/BaseAdresseNationale/codes-postaux/commit/9d24acbfbe8f5c220500c5f07f9d2c2df493e2c4
// https://geo.api.gouv.fr/decoupage-administratif/communes

import { getPostalCode } from "@/graphql/queries";
import { API, Logger } from "aws-amplify";
import {
  postalCodeByPostalCodeReturnGPS,
  postalCodeByPostalCodeSimplified,
  listPostalCodesSimplified,
  listCountriesSimplified,
} from "@/graphql/kdwqueries";
import i18n from "@/plugins/i18n.js";

const logger = new Logger("Postal code library");
const authMode = "AWS_IAM";

export async function fecthGPSfromPostalCode(postalCode, gps) {
  try {
    console.log("fecthGPSfromPostalCode:" + postalCode);
    var apiData;
    var trouve = false;
    var nextTokenStart = "start";
    var listPostalCodesFromAPI;

    while (nextTokenStart !== null && trouve === false) {
      // j++
      // console.log('debut de la recherche :' + j)
      if (nextTokenStart === "start") {
        apiData = await API.graphql({
          query: postalCodeByPostalCodeReturnGPS,
          variables: { postalCode: postalCode, limit: 4000 },
          authMode,
        });
      } else {
        apiData = await API.graphql({
          query: postalCodeByPostalCodeReturnGPS,
          variables: {
            postalCode: postalCode,
            limit: 4000,
            nextToken: nextTokenStart,
          },
          authMode,
        });
      }
      listPostalCodesFromAPI = apiData.data.postalCodeByPostalCode.items;
      // console.log(listPostalCodesFromAPI)
      nextTokenStart = apiData.data.postalCodeByPostalCode.nextToken;
      // console.log('nextTokenStart-------------------------')
      // console.log(nextTokenStart)
      // console.log('nextTokenStart-------------------------')
      if (listPostalCodesFromAPI.length > 0) {
        // console.log('Trouve coordonnée GPS')
        trouve = true;
        // console.log(listPostalCodesFromAPI[0])
        // console.log('latitude' + listPostalCodesFromAPI[0].latitude)
        gps.push(listPostalCodesFromAPI[0].latitude);
        // console.log('push latitude')
        gps.push(listPostalCodesFromAPI[0].longitude);
        // console.log('push longitude')
        // console.log(gps)
        return;
      }
    }
    return [];
  } catch (err) {
    logger.error(err);
    alert(i18n.t("postalCodeLib.alertFailLoad"));
  }
}

export async function fecthGPSfromPostalCodeID(postalCodeID, gps) {
  try {
    // console.log('fecthGPSfromPostalCodeID:' + postalCodeID)
    var apiData;
    // var listPostalCodesFromAPI

    // apiData = await API.graphql({ query: getPostalCode, variables: { id: postalCodeID, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    apiData = await API.graphql({
      query: getPostalCode,
      variables: { id: postalCodeID, limit: 4000 },
      authMode: "AWS_IAM",
    });
    // console.log(apiData)
    // listPostalCodesFromAPI = apiData.data.getPostalCode
    // console.log(apiData.data.getPostalCode)
    if (apiData.data.getPostalCode.townName) {
      // console.log('Trouve coordonnée GPS')
      // console.log('push latitude')
      gps.push(apiData.data.getPostalCode.latitude);
      // console.log('push longitude')
      gps.push(apiData.data.getPostalCode.longitude);
      // console.log(gps)
      return;
    }
    return;
  } catch (err) {
    logger.error(err);
    alert(i18n.t("postalCodeLib.alertFailGPSCoordinate"));
  }
}

/*
  export async function CheckPostalCodeAndTownMatch (postalCode, town) {
    try {
        console.log('CheckPostalCodeAndTownMatch:' + postalCode)
        var apiData
        var trouve = false
        var nextTokenStart = 'start'
        var listPostalCodesFromAPI
        var j = 0

        while ((nextTokenStart !== null) && (trouve === false)) {
          j++
          console.log('debut de la recherche :' + j)
          if (nextTokenStart === 'start') {
            apiData = await API.graphql({ query: listPostalCodesSimplified, variables: { townName: town, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          } else {
            apiData = await API.graphql({ query: postalCodeByTownNameSimplified, variables: { townName: town, limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          }
          listPostalCodesFromAPI = apiData.data.PostalCodeByTownName.items
          console.log(listPostalCodesFromAPI)
          nextTokenStart = apiData.data.PostalCodeByTownName.nextToken
          console.log('nextTokenStart-------------------------')
          console.log(nextTokenStart)
          console.log('nextTokenStart-------------------------')
          if ((listPostalCodesFromAPI.length > 0) && (listPostalCodesFromAPI.postalCode === postalCode)) {
              console.log('find the match postal code and town name')
              trouve = true
              return listPostalCodesFromAPI.id
          }
        }
        return []
      } catch {
        alert('FAIL to get the postal code list .................................................................')
      }
  }
  */

export async function fetchTownfromPostalCode(postalCode, townArrayResult) {
  try {
    // console.log('iam-auth CheckPostalCodeAndTownMatch:' + postalCode)
    var apiData;
    var nextTokenStart = "start";
    var listPostalCodesFromAPI;
    var j = 0;
    const countryCode = "fr";

    // We return the id of the country code associated
    var countryCodeId = "";
    const myFilter1 = { alpha2: { eq: countryCode } };
    const apiData1 = await API.graphql({
      query: listCountriesSimplified,
      variables: { filter: myFilter1, limit: 4000 },
      authMode: "AWS_IAM",
    });
    // console.log('countries info')
    // console.log(apiData1.data.listCountries.items.length)
    // apiData.data.postalCodeByPostalCode.items
    if (apiData1.data.listCountries.items.length === 1)
      countryCodeId = apiData1.data.listCountries.items[0].id;

    // We build the town list
    const myFilter = { countryCode: { eq: countryCode } };
    // console.log(townArrayResult)
    townArrayResult.splice(0, townArrayResult.length);
    // console.log(townArrayResult)
    while (nextTokenStart !== null) {
      // console.log('debut de la recherche :', j)
      if (nextTokenStart === "start") {
        apiData = await API.graphql({
          query: postalCodeByPostalCodeSimplified,
          variables: { postalCode: postalCode, filter: myFilter, limit: 4000 },
          authMode: "AWS_IAM",
        });
      } else {
        apiData = await API.graphql({
          query: postalCodeByPostalCodeSimplified,
          variables: {
            postalCode: postalCode,
            filter: myFilter,
            limit: 4000,
            nextToken: nextTokenStart,
          },
          authMode: "AWS_IAM",
        });
      }
      // console.log(apiData)
      // console.log(apiData.data)
      // console.log(apiData.data.postalCodeByPostalCode)
      // console.log(apiData.data.postalCodeByPostalCode.items)
      listPostalCodesFromAPI = apiData.data.postalCodeByPostalCode.items;
      // console.log(listPostalCodesFromAPI)
      nextTokenStart = apiData.data.postalCodeByPostalCode.nextToken;
      // console.log('nextTokenStart-------------------------')
      // console.log(nextTokenStart)
      // console.log('nextTokenStart-------------------------')
      if (listPostalCodesFromAPI.length > 0) {
        // console.log('find some postal code')
        Promise.all(
          listPostalCodesFromAPI.map(async (codePost) => {
            // console.log(codePost)
            townArrayResult.push({
              number: j,
              townName: codePost.townName,
              id: codePost.id,
              latitude: codePost.latitude,
              longitude: codePost.longitude,
              countryCodeId: countryCodeId,
            });
            j++;
            // console.log(townArrayResult)
          }),
        );
      }
    }
    return;
  } catch (err) {
    logger.error(err);
    alert(i18n.t("postalCodeLib.alertFailGetTownFromPostalCode"));
  }
}

export async function fetchTownfromPartialName(
  partialName,
  townArrayResult,
  countryCode,
) {
  try {
    // console.log('iam-auth CheckPostalCodeAndTownMatch:' + postalCode)
    console.log("fetchTownfromPartialName:" + partialName);
    var apiData;
    var nextTokenStart = "start";
    var listPostalCodesFromAPI;
    var j = 0;
    const myFilter = {
      townName: { contains: partialName },
      and: { countryCode: { eq: countryCode } },
    };
    townArrayResult.splice(0, townArrayResult.length);
    while (nextTokenStart !== null) {
      // console.log('debut de la recherche :' + j)
      if (nextTokenStart === "start") {
        apiData = await API.graphql({
          query: listPostalCodesSimplified,
          variables: { filter: myFilter, limit: 4000 },
          authMode: "AWS_IAM",
        });
      } else {
        apiData = await API.graphql({
          query: listPostalCodesSimplified,
          variables: {
            filter: myFilter,
            limit: 4000,
            nextToken: nextTokenStart,
          },
          authMode: "AWS_IAM",
        });
      }
      console.log("searching...", partialName, " ", j);
      // console.log(apiData)
      // console.log(apiData.data)
      // console.log(apiData.data.postalCodeByPostalCode)
      // console.log(apiData.data.postalCodeByPostalCode.items)
      listPostalCodesFromAPI = apiData.data.listPostalCodes.items;
      // console.log(listPostalCodesFromAPI)
      nextTokenStart = apiData.data.listPostalCodes.nextToken;
      // console.log('nextTokenStart-------------------------')
      // console.log(nextTokenStart)
      // console.log('nextTokenStart-------------------------')
      if (listPostalCodesFromAPI.length > 0) {
        // console.log('find some postal code')
        Promise.all(
          listPostalCodesFromAPI.map(async (codePost) => {
            // console.log(codePost)
            townArrayResult.push({
              number: j,
              townName: codePost.townName,
              id: codePost.id,
              latitude: codePost.latitude,
              longitude: codePost.longitude,
            });
            j++;
            // console.log(townArrayResult)
          }),
        );
      }
    }
    return;
  } catch (err) {
    logger.error(err);
    alert(i18n.t("postalCodeLib.alertFailGetTownFromPostalCode"));
  }
}
