<template>
  <div class="locale-switcher">
    <select id="LanguageSwitcher" v-model="locale">
      // Note that IE, Edge and Windows aren't great as displaying emoticon and
      may fallback on country code // TODO should dynamically retrieve available
      locales
      <option value="en">🇬🇧</option>
      <option value="fr">🇫🇷</option>
    </select>
  </div>
</template>

<script>
import { I18n } from "aws-amplify";

export default {
  data() {
    return { locale: this.$i18n.locale };
  },
  watch: {
    locale(val) {
      // we're rewriting current path (url) to reflect change of locale
      const currentPath = this.$router.currentRoute.path;
      const newPath = currentPath.replace(new RegExp("^/[^/]*/?"), `/${val}/`);
      if (currentPath !== newPath) {
        this.$router.push({ path: newPath });
        this.$i18n.locale = val;
        I18n.setLanguage(val);
      }
    },
  },
};
</script>
