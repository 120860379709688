<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display the information list of a review for a specific service: serviceRating, reviewNb
// Props:
// - Service: service with the review data
// Example of usage:
      <kdw-reviews-display-service-results
        :service="service"
      />
// ------------------------------------------------------------------------------>

<template>
  <div>
    <v-icon small color="primary"> mdi-star </v-icon>
    ({{ service.serviceRating }}/5) - ({{
      service.reviewNb === null ? 0 : service.reviewNb
    }}
    {{ $t("reviewDialog.review") }})
  </div>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "ReviewDisplaysServiceResults",
  mixins: [Heading],
  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  data: () => {
    return {};
  },

  created: function () {},
  methods: {},
};
</script>
