// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the consent library
// ------------------------------------------------------------------------------

import {
  getServiceProviderConsent,
  getEndUserConsent,
  getServiceProviderCookie,
  getEndUserCookie,
} from "@/graphql/kdwqueries";
import { Logger, API } from "aws-amplify";
import i18n from "@/plugins/i18n.js";
import {
  ENDUSER,
  SERVICEPROVIDER,
  getUserType,
  getUserSub,
  checkCurrentUserType,
} from "@/services/User";
import {
  updateServiceProvider as updateServiceProviderMutation,
  updateEndUser as updateEndUserMutation,
} from "@/graphql/mutations";

const logger = new Logger("Consent");

export async function checkSPConsent() {
  try {
    const apiDataEndUser = await API.graphql({
      query: getServiceProviderConsent,
      variables: { id: await getUserSub() },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const ServiceProvider = apiDataEndUser.data.getServiceProvider;
    // console.log('ServiceProvider:')
    // console.log(ServiceProvider)
    if (ServiceProvider.length === 0) {
      return i18n.t("consentLib.alertNoSPFound");
    }
    if (ServiceProvider.namecpy === "") {
      return i18n.t("consentLib.alertSetCompanyName");
    }

    if (ServiceProvider.siret === "") {
      return i18n.t("consentLib.alertSetSiretValue");
    }

    if (ServiceProvider.addresscpy1 === "") {
      return i18n.t("consentLib.alertSetCompanyAddressLine1");
    }

    if (ServiceProvider.postcodecpy === "") {
      return i18n.t("consentLib.alertSetCompanyPostCode");
    }

    if (ServiceProvider.emailcpy === "") {
      return i18n.t("consentLib.alertSetCompanyEmail");
    }

    if (ServiceProvider.consentcpy === false) {
      return i18n.t("consentLib.alertSetCompanyConsent");
    }

    if (
      ServiceProvider.postcodecitycpy === undefined ||
      ServiceProvider.postcodecitycpy === null ||
      ServiceProvider.postcodecitycpy.id === ""
    ) {
      return i18n.t("consentLib.alertSetCity");
    }

    if (
      ServiceProvider.countrycpy === undefined ||
      ServiceProvider.countrycpy === null ||
      ServiceProvider.countrycpy.id === ""
    ) {
      return i18n.t("consentLib.alertSetCountry");
    }

    if (ServiceProvider.siretVerified === false) {
      return i18n.t("consentLib.alertSetValidateSiret");
    }

    if (ServiceProvider.banned === true) {
      return i18n.t("consentLib.alertBanned");
    }

    return "";
  } catch (err) {
    logger.error(err);
    return i18n.t("consentLib.alertNoSPFound");
  }
}

export async function checkEUConsent() {
  try {
    const apiDataEndUser = await API.graphql({
      query: getEndUserConsent,
      variables: { id: await getUserSub() },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const endUser = apiDataEndUser.data.getEndUser;
    // console.log('ServiceProvider:')
    // console.log(ServiceProvider)
    if (endUser.firstname === "") {
      return i18n.t("consentLib.alertSetFirstName");
    }
    if (endUser.lastname === "") {
      return i18n.t("consentLib.alertSetLastName");
    }

    if (endUser.address1 === "") {
      return i18n.t("consentLib.alertSetCompanyAddressLine1");
    }

    if (endUser.postcode === "") {
      return i18n.t("consentLib.alertSetCompanyPostCode");
    }

    if (endUser.emailcpy === "") {
      return i18n.t("consentLib.alertSetCompanyEmail");
    }

    if (endUser.consent === false) {
      return i18n.t("consentLib.alertSetCompanyConsent");
    }

    if (endUser.postcodecity.id === "") {
      return i18n.t("consentLib.alertSetCity");
    }

    if (
      endUser.country === undefined ||
      endUser.country === null ||
      endUser.country.id === ""
    ) {
      return i18n.t("consentLib.alertSetCountry");
    }
    return "";
  } catch (err) {
    logger.error(err);
    return i18n.t("consentLib.alertNoSPFound");
  }
}

async function checkSPCookie() {
  try {
    const apiDataEndUser = await API.graphql({
      query: getServiceProviderCookie,
      variables: { id: await getUserSub() },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const ServiceProvider = apiDataEndUser.data.getServiceProvider;
    // console.log('ServiceProvider:')
    // console.log(ServiceProvider)
    if (ServiceProvider.length === 0) {
      return { cookieParameters: "", cookieUpdateDate: "" };
    }

    return {
      cookieParameters: ServiceProvider.cookieParameters,
      cookieUpdateDate: ServiceProvider.cookieUpdateDate,
    };
  } catch (err) {
    logger.error(err);
    return { cookieParameters: "", cookieUpdateDate: "" };
  }
}

async function checkEUCookie() {
  try {
    // console.log('checkEUCookie')
    const apiDataEndUser = await API.graphql({
      query: getEndUserCookie,
      variables: { id: await getUserSub() },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const endUser = apiDataEndUser.data.getEndUser;
    // console.log('endUser:')
    // console.log(endUser)
    if (endUser.length === 0) {
      return { cookieParameters: "", cookieUpdateDate: "" };
    }

    return {
      cookieParameters: endUser.cookieParameters,
      cookieUpdateDate: endUser.cookieUpdateDate,
    };
  } catch (err) {
    logger.error(err);
    return { cookieParameters: "", cookieUpdateDate: "" };
  }
}

export async function checkCookie() {
  try {
    // the cookieGeneralChecked parameter is used to check if the cookie has been validated by the end user
    // console.log('checkCookie', await checkCurrentUserType())
    var cookieParametersTempo = "";
    if ((await checkCurrentUserType()) === ENDUSER) {
      // console.log('ENDUSER')
      cookieParametersTempo = await checkEUCookie();
    }
    if ((await checkCurrentUserType()) === SERVICEPROVIDER) {
      // console.log('SP')
      cookieParametersTempo = await checkSPCookie();
    }
    if (cookieParametersTempo !== "") {
      return cookieParametersTempo;
    }
    return { cookieParameters: "", cookieUpdateDate: "" };
  } catch (err) {
    logger.error(err);
    return { cookieParameters: "", cookieUpdateDate: "" };
  }
}

export async function updateCookie(cookievalue) {
  try {
    const myDate = new Date().toISOString();
    var dataToUpdate;
    if (cookievalue === true) {
      dataToUpdate = {
        id: await getUserSub(),
        cookieParameters: '{"cookieGeneralChecked":true}',
        cookieUpdateDate: myDate,
      };
    } else {
      dataToUpdate = {
        id: await getUserSub(),
        cookieParameters: '{"cookieGeneralChecked":false}',
        cookieUpdateDate: myDate,
      };
    }
    if (getUserType() === ENDUSER) {
      await API.graphql({
        query: updateEndUserMutation,
        variables: { input: dataToUpdate },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return true;
    }
    if (getUserType() === SERVICEPROVIDER) {
      await API.graphql({
        query: updateServiceProviderMutation,
        variables: { input: dataToUpdate },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return true;
    }
  } catch (err) {
    logger.error(err);
    return false;
  }
}
