// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the categories and Sub categories library
// ------------------------------------------------------------------------------

// import { getUserSub } from '@/services/User'
import { Logger, API } from "aws-amplify";
import {
  deleteAppointmentAnswers as deleteAppointmentAnswersMutation,
  deleteAppointment as deleteAppointmentMutation,
} from "@/graphql/mutations";
import {
  listAppointmentsSimplified,
  getEndUserBasicData,
} from "@/graphql/kdwqueries";
import { convertAWSDateTimeStringLocalUTC } from "@/helpers/Formatter";
import { DateTime } from "luxon";
import { createAppointment as backEndCreateAppointment } from "@/services/stateMachineBackend";
import {
  ANONYMOUS,
  ENDUSER,
  SERVICEPROVIDER,
  getUserType,
} from "@/services/User";

// import { endsWith } from 'core-js/core/string'
// import { convertUTCDateToLocalDate } from '@/helpers/GenericFunctionsTime'
// import { compare } from '@/helpers/GenericFunctions'
// import i18n from '@/plugins/i18n.js'
// import { formatCategoryOrSubCategoryName } from '@/helpers/Formatter'

const logger = new Logger("Appointments");

// selectedSlot: { selected: true, date: '1900-01-01', time: '10:00', id: 'id of the resource' },
export async function createAppointment(
  selectedSlot,
  serviceID,
  serviceName,
  serviceDurationMin,
  ressourceList,
  scheduler,
  answerId,
  enduserId,
) {
  try {
    // console.log('createAppointment')
    // console.log('selectedSlot:', selectedSlot)
    // console.log('answerId......:', answerId)
    // console.log('Je passe ici dans createAppointment - 1')
    // We collect the first name & lastname of the ressource to denomalise.
    /* const myResource = await API.graphql({
      query: getResourceFirstnameLastname,
      variables: { id: selectedSlot.id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }); */
    // console.log('Je passe ici dans createAppointment - 2')
    // console.log('myResource:')
    // console.log(myResource)
    // We create the date in AWS format
    // const date = new Date(selectedSlot.date.substring(0, 4), parseInt(selectedSlot.date.substring(5, 7)) - 1, selectedSlot.date.substring(8, 10), selectedSlot.time.substring(0, 2), selectedSlot.time.substring(3, 5))
    const myDate = convertAWSDateTimeStringLocalUTC(
      selectedSlot.date,
      selectedSlot.time.substring(0, 2),
      selectedSlot.time.substring(3, 5),
    );
    // console.log('Je passe ici dans createAppointment - 3')
    // console.log('myDate:', myDate)
    // We create the appointment.
    // We now validate that the appointment is still valid
    var found = -1;
    var i = 0;
    // console.log('ressourceList.length:', ressourceList.length)
    while (found === -1 && i < ressourceList.length) {
      // console.log('compare:', ressourceList[i].id, 'with', selectedSlot.id)
      if (ressourceList[i].id === selectedSlot.id) {
        found = i;
      }
      i++;
    }
    // console.log('Je passe ici dans createAppointment - 3')
    // console.log('found:', found)
    if (found !== -1) {
      var slotVerified = false;
      // console.log('FOUNDDDDDD')
      // Verification of the slot availability can start
      slotVerified = scheduler.verifySlotResource(
        ressourceList[found],
        selectedSlot,
        serviceDurationMin,
      );
      // console.log('slotVerified : ', slotVerified)
      if (slotVerified) {
        const params = {
          dateTime: myDate,
          serviceId: serviceID,
          resourceId: selectedSlot.id,
          answerId,
          slotDate: selectedSlot.date,
          slotTime: selectedSlot.time,
        };
        // console.log('params of the appointment')
        // console.log(params)
        await backEndCreateAppointment(params);
        return true;
      }
      return false;
    }
    /*
      else {
        console.log('We are in the else of FOUND')
      }
      */
    return false;
  } catch (err) {
    logger.error(err);
    return false;
  }
}

// delete an apointement and its associated AppointmentAnswer
export async function deleteAppointment(appointmentId, appointmentAnswerId) {
  try {
    if (appointmentId === "") {
      return false;
    }
    // delete the Appointment Answers to make the connection
    if (appointmentAnswerId !== "") {
      await API.graphql({
        query: deleteAppointmentAnswersMutation,
        variables: { input: { id: appointmentAnswerId } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    }
    // delete the appointment
    await API.graphql({
      query: deleteAppointmentMutation,
      variables: { input: { id: appointmentId } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
}

export class Appointments {
  list = [];
  //  [{ type: 'rangeByDuration', startDate: '2021-09-01', startHH: '14', startMM: '00', duration: '60' },
  //   { type: 'rangeByDuration', startDate: '2021-09-10', startHH: '15', startMM: '00', duration: '60' }]

  constructor(resourceID) {
    logger.info("Appointment list created.");
    // this.a = this.a + 2
    // if ((resourceID !== undefined) && (resourceID !== null)) {
    //  this.resourceID = resourceID
    // }
  }

  initialise() {
    this.list.splice(0, this.list.length);
  }

  copy(appointment) {
    this.list = [...appointment.list];
  }

  async load(resourceID) {
    // console.log('Load ------------------------------------------')
    // console.log('resourceID', resourceID)
    // this.list.splice(0, this.list.length)
    // We are managing the authentication mode for the request depending on the authentication status
    var authMode = "";
    const userType = getUserType();
    if (userType === ANONYMOUS) {
      // We are not logged
      authMode = "AWS_IAM";
    }
    if (userType === ENDUSER || userType === SERVICEPROVIDER) {
      // We are already logged
      authMode = "AMAZON_COGNITO_USER_POOLS";
    }

    try {
      const myFilter = {
        resourceID: {
          eq: resourceID,
        },
      };
      var appointmentAnswersId;
      var nextTokenStart = "start";
      var listFromAPI, apiData, localTime;
      while (nextTokenStart !== null) {
        if (nextTokenStart === "start") {
          // apiData = await API.graphql({ query: listAppointmentsSimplified, variables: { filter: myFilter, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          apiData = await API.graphql({
            query: listAppointmentsSimplified,
            variables: { filter: myFilter, limit: 4000 },
            authMode: authMode,
          });
        } else {
          // apiData = await API.graphql({ query: listAppointmentsSimplified, variables: { filter: myFilter, limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          apiData = await API.graphql({
            query: listAppointmentsSimplified,
            variables: {
              filter: myFilter,
              limit: 4000,
              nextToken: nextTokenStart,
            },
            authMode: authMode,
          });
        }
        // console.log('apiData:')
        // console.log(apiData)
        listFromAPI = apiData.data.listAppointments.items;
        // console.log('listFromAPI:')
        // console.log(listFromAPI)
        nextTokenStart = apiData.data.listAppointments.nextToken;
        if (listFromAPI.length > 0) {
          Promise.all(
            listFromAPI.map(async (appointment) => {
              // console.log(appointment)
              appointmentAnswersId = "";
              if (appointment.answers.items.length > 0) {
                appointmentAnswersId = appointment.answers.items[0].id;
              }
              // localTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')
              localTime = DateTime.fromISO(appointment.dateTime); // .toFormat('yyyy-LL-dd HH:mm')
              // localTime = moment.utc(appointment.dateTime).toDate()
              // localTime = moment(localTime).format('YYYY-MM-DD HH:mm')
              // console.log('moment: ' + localTime)
              // console.log('date: ' + localTime.substring(0, 10))
              // console.log('HH: ' + localTime.substring(11, 13))
              // console.log('MM: ' + localTime.substring(14, 16))
              this.list.push({
                type: "rangeByDuration",
                startDate: localTime.toISODate(),
                startHH: localTime.toFormat("HH"),
                startMM: localTime.toFormat("mm"),
                duration: appointment.serviceDuration,
                serviceName: appointment.serviceName,
                resourceFirstname: appointment.resourceFirstname,
                resourceLastname: appointment.resourceLastname,
                enduserID: appointment.enduserID,
                enduserFirstName: appointment.enduserFirstName,
                enduserLastname: appointment.enduserLastname,
                enduserPhone: appointment.enduserPhone,
                enduserMail: appointment.enduserMail,
                id: appointment.id,
                appointmentAnswersId: appointmentAnswersId,
              });
            }),
          );
        }
      }
    } catch (err) {
      logger.error(err);
    }
  }

  // Compute the allocation for the schedule library.
  // allocated: [
  //  { from: '2017-02-01 13:00', duration: 60 },
  //  { from: '2017-02-01 14:00', duration: 60 },
  // ],
  allocated() {
    if (this.list.length === 0) {
      return [];
    }
    var allocatedTable = [];
    // Extract the time in local time
    var dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    // console.log('dateNow:')
    // console.log(dateNow)
    var dateFetched;
    for (var i = 0; i < this.list.length; i++) {
      // console.log('YY', this.list[i].startDate.substring(0, 4))
      // console.log('MM', this.list[i].startDate.substring(5, 7))
      // console.log('DD', this.list[i].startDate.substring(8, 10))
      dateFetched = new Date(
        this.list[i].startDate.substring(0, 4),
        parseInt(this.list[i].startDate.substring(5, 7)) - 1,
        this.list[i].startDate.substring(8, 10),
        this.list[i].startHH,
        this.list[i].startMM,
      );
      // console.log('dateFetched:')
      // console.log(dateFetched)
      // we add the time only of this is in the future.
      if (dateFetched > dateNow) {
        // console.log('Date added..')
        allocatedTable.push({
          from:
            this.list[i].startDate +
            "T" +
            this.list[i].startHH +
            ":" +
            this.list[i].startMM,
          duration: this.list[i].duration,
        });
      }
      //  [{ type: 'rangeByDuration', startDate: '2021-09-01', startHH: '14', startMM: '00', duration: '60' },
      //   { type: 'rangeByDuration', startDate: '2021-09-10', startHH: '15', startMM: '00', duration: '60' }]
    }
    return allocatedTable;
  }

  async buildCalendarEvent(events) {
    if (this.list.length === 0) {
      return;
    }
    // console.log(this.list)
    var dateFetched, dateFetchedPlusDuration;
    var tempEnduserFirstName;
    var tempEnduserLastname;
    var tempEnduserPhone;
    var tempEnduserMail;
    var apiData;
    for (var i = 0; i < this.list.length; i++) {
      // console.log('YY', this.list[i].startDate.substring(0, 4))
      // console.log('MM', this.list[i].startDate.substring(5, 7))
      // console.log('DD', this.list[i].startDate.substring(8, 10))
      // dateFetched = new Date(this.list[i].startDate.substring(0, 4), parseInt(this.list[i].startDate.substring(5, 7)) - 1, this.list[i].startDate.substring(8, 10), this.list[i].startHH, this.list[i].startMM)
      // Using this mode, this is helping to avoid to have the AM/PM
      // TODO weird split here
      dateFetched = new Date(
        this.list[i].startDate +
          "T" +
          this.list[i].startHH +
          ":" +
          this.list[i].startMM +
          ":00",
      );
      // console.log(this.list[i].startDate)
      dateFetchedPlusDuration = new Date(
        DateTime.fromJSDate(dateFetched).plus({
          minutes: this.list[i].duration,
        }),
      );
      // console.log('dateFetched:', dateFetched)
      // console.log('dateFetchedPlusDuration:', dateFetchedPlusDuration)
      // console.log(dateFetched)
      // we add the time only of this is in the future.
      // console.log('Date added..')

      if (
        this.list[i].enduserID === "" ||
        this.list[i].enduserID === null ||
        this.list[i].enduserID === undefined
      ) {
        tempEnduserFirstName = this.list[i].enduserFirstName;
        tempEnduserLastname = this.list[i].enduserLastname;
        tempEnduserPhone = this.list[i].enduserPhone;
        tempEnduserMail = this.list[i].enduserMail;
      } else {
        // console.log('userID:', this.list[i].enduserID)
        apiData = await API.graphql({
          query: getEndUserBasicData,
          variables: { id: this.list[i].enduserID, limit: 4000 },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        // console.log('apiData from appointment', this.list[i].enduserID)
        // console.log(apiData)
        if (apiData.data.getEndUser !== null) {
          tempEnduserFirstName = apiData.data.getEndUser.firstname;
          tempEnduserLastname = apiData.data.getEndUser.lastname;
          tempEnduserPhone = apiData.data.getEndUser.phone;
          tempEnduserMail = apiData.data.getEndUser.email;
        }
      }
      // console.log('this.list[i]')
      // console.log(this.list[i])
      if (this.list[i].appointmentAnswersId !== "") {
        events.push({
          type: "BookedEvent",
          color: "sea",
          start: dateFetched,
          end: dateFetchedPlusDuration,
          startISO: DateTime.fromJSDate(dateFetched).toISO(),
          endISO: DateTime.fromJSDate(dateFetchedPlusDuration).toISO(),
          name: this.list[i].serviceName,
          timed: true,
          enduserFirstName: tempEnduserFirstName,
          enduserLastname: tempEnduserLastname,
          enduserPhone: tempEnduserPhone,
          enduserMail: tempEnduserMail,
          resourceID: this.list[i].id,
          resourceFirstname: this.list[i].resourceFirstname,
          resourceLastname: this.list[i].resourceLastname,
          appointementId: this.list[i].id,
          appointmentAnswersId: this.list[i].appointmentAnswersId,
        });
      } else {
        events.push({
          type: "AddedEvent",
          color: "jean",
          start: dateFetched,
          end: dateFetchedPlusDuration,
          startISO: DateTime.fromJSDate(dateFetched).toISO(),
          endISO: DateTime.fromJSDate(dateFetchedPlusDuration).toISO(),
          name: this.list[i].serviceName,
          timed: true,
          enduserFirstName: tempEnduserFirstName,
          enduserLastname: tempEnduserLastname,
          enduserPhone: tempEnduserPhone,
          enduserMail: tempEnduserMail,
          resourceID: this.list[i].id,
          resourceFirstname: this.list[i].resourceFirstname,
          resourceLastname: this.list[i].resourceLastname,
          appointementId: this.list[i].id,
          appointmentAnswersId: this.list[i].appointmentAnswersId,
        });
      }
    }
    // console.log('events')
    // console.log(events)
  }
}
