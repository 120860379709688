<template>
  <v-btn
    :color="color"
    :depressed="depressed"
    :min-width="minWidth"
    :tile="tile"
    class="font-weight-bold"
    rounded
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseBtn",

  props: {
    color: {
      type: String,
      default: "primary",
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: [Number, String],
      default: 144,
    },
    tile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
