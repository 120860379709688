// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Generic functions to manage S3 download features
// ------------------------------------------------------------------------------
import { Logger, Storage } from "aws-amplify";
const logger = new Logger("GenericFunctions");

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

export function loadFilePublic(
  fileNameToDownload,
  fileNameDownloadedifDifferent,
) {
  var fileNameDownloaded;
  if (
    fileNameDownloadedifDifferent === undefined ||
    fileNameDownloadedifDifferent === null ||
    fileNameDownloadedifDifferent === ""
  ) {
    fileNameDownloaded = fileNameToDownload;
  } else {
    fileNameDownloaded = fileNameDownloadedifDifferent;
  }
  Storage.get(fileNameToDownload, {
    download: true,
    progressCallback(progress) {
      console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
    },
  })
    .then((result) => {
      downloadBlob(result.Body, fileNameDownloaded);
    })
    .catch((err) => logger.error(err));
}

// This function is used to check if we need to replace the picture file in the S3 bucket
// logoOriginalNamePointer = original name of the file in the S3 bucket logoOriginalNamePointer.logoOriginalName
// logoObject = { originalName, name, image }
export async function pictureUpdateS3(logoObject) {
  try {
    // console.log('logoObject:')
    // console.log(logoObject)
    // Case the name does not change - We do not save.
    if (
      !logoObject ||
      logoObject.originalName === undefined ||
      logoObject.originalName === null ||
      logoObject.name === undefined ||
      logoObject.name === null ||
      logoObject.originalName === logoObject.name
    )
      return "false";
    // As this is a different name we delete the old one.
    if (logoObject && logoObject.originalName) {
      // We delete the old picture
      await Storage.remove(logoObject.originalName, { level: "public" });
      logger.info(
        "-- An image: " + logoObject.originalName + " have been removed",
      );
      logoObject.originalName = "";
    }
    if (logoObject.name) {
      // We add the store the new picture
      await Storage.put(logoObject.name, logoObject.image, { level: "public" });
      logger.info("An image:" + logoObject.name + " have been added");
      logoObject.originalName = logoObject.name;
    }
    return "true";
  } catch (err) {
    logger.error(err);
    return "error";
  }
}

// We remove all the object from the S3 linked to the object
// This function assumes that when the data are saved, the new name is stored in originalName, so we delete originalName only.
export async function deletePictureS3(logoObject) {
  try {
    // console.log('logoObject:')
    // console.log(logoObject)
    if (
      !logoObject ||
      logoObject.originalName === undefined ||
      logoObject.originalName === null
    )
      return false;
    // As this is a different name we delete the old one.
    if (logoObject && logoObject.originalName) {
      // We delete the old picture
      await Storage.remove(logoObject.originalName, { level: "public" });
      logger.info(
        "-- An image: " + logoObject.originalName + " have been removed",
      );
      logoObject.originalName = "";
    }
    return true;
  } catch (err) {
    logger.error(err);
    return "error";
  }
}

export async function getPictureUrlfromS3Bucket(pictureName) {
  // We collect the picture coming from the S3 public bucket
  try {
    if (pictureName) {
      const url = await Storage.get(pictureName, { level: "public" });
      return url;
    } else return null;
  } catch (err) {
    console.log(err);
    return null;
  }
}
