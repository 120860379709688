<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display the detailled information of the service provided by the service provided (Service Name, description of the service, Price, etc.):
// Props:
// - ServiceDetails: that contains the details elements of the service as an object: ServicePrice, serviceDuration,serviceDescription, category, subCategory
// - refresh: to handle an update in the display of this dialog (ie, URL)
// Example of usage:
      <kdw-dialog-info-service
        :value="dialog"
        :serviceDetails="editedItem"
      />
  with dialog that need to be set to true to display the Dialog Card.
// ------------------------------------------------------------------------------>

<template>
  <v-dialog
    v-model="dialogStatus"
    max-width="500px"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-container style="padding: 0px">
      <v-card class="rounded">
        <v-card-title
          class="primary--text text-h5 ml-4"
          style="word-break: break-word"
        >
          {{ serviceDetails.name }}
        </v-card-title>
        <v-card-text v-if="serviceDetails.serviceRating !== null">
          <v-rating
            v-model="serviceRating"
            color="primary"
            half-increments
            readonly
          />
          ({{ serviceDetails.serviceRating }}/5)
        </v-card-text>
        <!--- THIS IS THE DESKTOP PART ----->
        <v-card-text class="hidden-sm-and-down">
          <v-tabs>
            <v-tab>
              {{ $t("dialogInfoService.information") }}
            </v-tab>
            <v-tab v-if="serviceDetails.serviceRating !== null">
              {{ $t("dialogInfoService.review") }}
            </v-tab>
            <!-- information about the company & the service in desktop-->
            <v-tab-item>
              <!------- Company logo in desktop  ---->
              <v-list-item>
                <v-list-item-avatar
                  v-if="
                    serviceDetails.cpyLogo === '' ||
                    serviceDetails.cpyLogo === null ||
                    serviceDetails.cpyLogo === undefined
                  "
                  size="60"
                >
                  <v-img :src="require('@/assets/Avatar-02.svg')" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  v-if="
                    serviceDetails.cpyLogo !== '' &&
                    serviceDetails.cpyLogo !== null &&
                    serviceDetails.cpyLogo !== undefined
                  "
                  size="60"
                >
                  <v-img :src="urlLogo" />
                </v-list-item-avatar>
                <!------- 3 lignes with: SubCategory, Short description, Town Name  ---->
                <v-list-item-content>
                  <v-list-item-subtitle style="white-space: normal">
                    <v-icon small> mdi-folder-pound </v-icon>
                    {{ serviceDetails.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="
                      serviceDetails.subCategory !== undefined &&
                      serviceDetails.subCategory !== ''
                    "
                    style="white-space: normal"
                  >
                    <v-icon small> mdi-folder-pound-outline </v-icon>
                    {{ serviceDetails.subCategory }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="white-space: normal">
                    <v-icon small> mdi-map-marker </v-icon>
                    {{ serviceDetails.townName }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="white-space: normal">
                    <v-icon small> mdi-bank </v-icon>
                    {{ serviceDetails.cpyname }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="serviceDetails && serviceDetails.cpyUrl"
                    :key="'dialogInfoService' + refreshFront"
                    style="white-space: normal"
                  >
                    <v-icon small> mdi-web </v-icon>
                    <a :href="serviceDetails.cpyUrl" target="_blank">
                      {{ serviceDetails.searchkey }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-spacer />
                <v-list-item-action>
                  <kdw-label :text="serviceDetails.ServicePriceDisplay" />
                </v-list-item-action>
              </v-list-item>
              <!-------  Description  & Duration in desktop ---->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle
                    v-if="serviceDetails.serviceDescription !== ''"
                    class="font-italic"
                  >
                    {{ $t("hero.descriptionTable") }}:
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="serviceDetails.serviceDescription !== ''"
                  >
                    <strong style="white-space: pre-wrap">
                      {{
                        serviceDetails.serviceDescription ||
                        $t("hero.nodescription")
                      }}
                    </strong>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="serviceDetails.isFixedPrice">
                  <kdw-label
                    :text="serviceDetails.serviceDuration"
                    :white="false"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  v-if="serviceDetails.serviceLegaldescription !== ''"
                >
                  <strong style="white-space: pre-wrap">
                    {{
                      serviceDetails.serviceLegaldescription ||
                      $t("hero.nodescription")
                    }}
                  </strong>
                </v-list-item-subtitle>
              </v-list-item>
            </v-tab-item>
            <!-- information about the service review in desktop -->
            <v-tab-item
              v-if="serviceDetails.serviceRating !== null && reviews.length > 0"
            >
              <kdw-reviews-display-items :reviews="reviews" />
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <!-- THIS IS FOR MOBILE --->
        <v-card-text class="hidden-md-and-up">
          <v-tabs>
            <v-tab>
              {{ $t("dialogInfoService.information") }}
            </v-tab>
            <v-tab v-if="serviceDetails.serviceRating !== null">
              {{ $t("dialogInfoService.review") }}
            </v-tab>
            <v-tab-item>
              <v-list-item>
                <!------- Company logo in mobile  ---->
                <v-list-item-avatar
                  v-if="
                    serviceDetails.cpyLogo === '' ||
                    serviceDetails.cpyLogo === null ||
                    serviceDetails.cpyLogo === undefined
                  "
                  size="60"
                >
                  <v-img :src="require('@/assets/Avatar-02.svg')" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  v-if="
                    serviceDetails.cpyLogo !== '' &&
                    serviceDetails.cpyLogo !== null &&
                    serviceDetails.cpyLogo !== undefined
                  "
                  size="60"
                >
                  <v-img :src="urlLogo" />
                </v-list-item-avatar>
                <!------- 3 lignes with: SubCategory, Short description, Town Name in mobile ---->
                <v-list-item-content>
                  <v-list-item-subtitle style="white-space: normal">
                    <v-icon small> mdi-folder-pound </v-icon>
                    {{ serviceDetails.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="
                      serviceDetails.subCategory !== undefined &&
                      serviceDetails.subCategory !== ''
                    "
                    style="white-space: normal"
                  >
                    <v-icon small> mdi-folder-pound-outline </v-icon>
                    {{ serviceDetails.subCategory }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="white-space: normal">
                    <v-icon small> mdi-map-marker </v-icon>
                    {{ serviceDetails.townName }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="white-space: normal">
                    <v-icon small> mdi-bank </v-icon>
                    {{ serviceDetails.cpyname }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="serviceDetails && serviceDetails.cpyUrl"
                    :key="'dialogInfoService' + refreshFront"
                    style="white-space: normal"
                  >
                    <v-icon small> mdi-web </v-icon>
                    <a :href="serviceDetails.cpyUrl" target="_blank">
                      {{ serviceDetails.searchkey }}
                    </a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-------  Description  & Duration in mobile ---->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle
                    v-if="serviceDetails.serviceDescription !== ''"
                    class="font-italic">
                    {{ $t("hero.descriptionTable") }}:
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="serviceDetails.serviceDescription !== ''"
                  >
                    <strong style="white-space: pre-wrap">
                      {{
                        serviceDetails.serviceDescription ||
                        $t("hero.nodescription")
                      }}
                    </strong>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <kdw-label :text="serviceDetails.ServicePriceDisplay" />
                </v-list-item-action>
                <v-spacer />
                <v-list-item-action v-if="serviceDetails.isFixedPrice">
                  <kdw-label
                    :text="serviceDetails.serviceDuration"
                    :white="false"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  v-if="serviceDetails.serviceLegaldescription !== ''"
                >
                  <strong style="white-space: pre-wrap">
                    {{
                      serviceDetails.serviceLegaldescription ||
                      $t("hero.nodescription")
                    }}
                  </strong>
                </v-list-item-subtitle>
              </v-list-item>
            </v-tab-item>
            <v-tab-item
              v-if="serviceDetails.serviceRating !== null && reviews.length > 0"
            >
              <kdw-reviews-display-items :reviews="reviews" />
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <!-------  Cancel button  ---->
        <v-card-actions>
          <v-spacer />
          <!-- TODO cancel is scary, maybe not the best wording -->
          <kdw-btn
            color="primary"
            text
            outlined
            class="mr-5"
            @click="closeDialog"
          >
            {{ $t("button.ok") }}
          </kdw-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";
import { Logger, Storage, API } from "aws-amplify";
import { reviewByServiceIDComments } from "@/graphql/kdwqueries";

const logger = new Logger("DialogInfoService");

export default {
  name: "KDWServiceDetailResult",
  mixins: [Heading],
  props: {
    serviceDetails: Object,
    logo: String,
    value: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data: () => {
    return {
      dialogStatus: false,
      refreshFront: 0,
      urlLogo: null,
      serviceRating: null,
      reviews: [],
    };
  },

  watch: {
    logo: function (val) {
      // console.log('logo changed:', val)
      if (this.logo !== undefined && this.logo !== null && this.logo !== "") {
        Storage.get(val, { level: "public" })
          .then((result) => {
            this.urlLogo = result;
            // console.log('result of the url we got from the storage:')
            // console.log(result)
          })
          .catch((err) => logger.error(err));
      }
    },
    refresh: function () {
      this.refreshFront++
      // console.log('serviceDetails:')
      // console.log(this.serviceDetails)
    },
    value: function (newValue) {
      // console.log('DialogInfoService: ', newValue)
      this.dialogStatus = newValue;
      if (this.serviceDetails && this.serviceDetails.serviceRating) {
        this.serviceRating = this.serviceDetails.serviceRating;
      } else this.serviceRating = null;
      // console.log('this.serviceDetails')
      // console.log(this.serviceDetails)
      if (this.dialogStatus && this.serviceRating && this.serviceDetails.id) {
        try {
          API.graphql({
            query: reviewByServiceIDComments,
            variables: { serviceID: this.serviceDetails.id, limit: 40 },
            authMode: "AWS_IAM",
          }).then((apiDataReviewComments) => {
            // console.log('apiDataReviewComments:')
            // console.log(apiDataReviewComments)
            if (apiDataReviewComments.data.reviewByServiceID.items.length > 0) {
              this.reviews = [
                ...apiDataReviewComments.data.reviewByServiceID.items,
              ];
              // console.log('this.reviews:')
              // console.log(this.reviews)
            }
          });
        } catch (err) {
          logger.error(err);
        }
      } else this.reviews.splice(0, this.reviews.length);
    },
  },

  created: function () {
    // console.log('this.$attr = ', this.$attrs)
    // console.log('this.$listeners = ', this.$listeners)
    this.dialogStatus = false;
  },
  methods: {
    emitResult() {
      this.$emit("input", this.dialogStatus);
    },
    closeDialog() {
      this.dialogStatus = false;
      this.emitResult();
    },
  },
};
</script>
