// Imports
import Vue from "vue";
import Router from "vue-router";
import { ENDUSER, SERVICEPROVIDER } from "@/services/User";
import { I18n } from "aws-amplify";
import i18n from "../plugins/i18n";
import { verifyLocale } from "@/helpers/Navigation";
// import { prefetchData } from '@/services/miniweb/service'

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  // https://lokalise.com/blog/vue-i18n/
  routes: [
    {
      path: "/",
      redirect: i18n.locale,
    },
    {
      path: "/:locale",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/anonymous/home/Index.vue"),
        },
        {
          path: "faq",
          name: "AnonymousFaq",
          component: () => import("@/views/anonymous/home/MyFaq.vue"),
        },
        {
          path: "login",
          name: "Login",
          component: () => import("@/views/anonymous/home/Login.vue"),
          props: { signUpType: ENDUSER },
        },
        // Managing the rooting for Miniweb
        {
          path: "web/:searchkey",
          name: "MiniWeb",
          component: () =>
            import("@/components/kdw/miniweb/RouterComponent.vue"),
        },
      ],
    },
    {
      // Pro landing page supposedly before sign-in
      path: "/:locale/p",
      component: () => import("@/layouts/home/Index.vue"),
      props: { pro: true },
      children: [
        {
          path: "",
          name: "PHome",
          component: () => import("@/views/anonymous/home/ProIndex.vue"),
        },
        {
          path: "login",
          name: "PLogin",
          component: () => import("@/views/anonymous/home/Login.vue"),
          props: { signUpType: SERVICEPROVIDER },
        },
        {
          path: "*",
          name: "UserFourOhFour",
          component: () => import("@/views/anonymous/home/404.vue"),
        },
      ],
    },
    {
      path: "/:locale/user",
      component: () => import("@/layouts/enduser/Index.vue"),
      children: [
        {
          path: "account",
          name: "EndUserMyAccount",
          component: () => import("@/views/enduser/home/MyAccount.vue"),
        },
        {
          path: "services",
          name: "EndUserMyServices",
          component: () => import("@/views/enduser/home/MyServices.vue"),
        },
        {
          path: "globalparam",
          name: "EndUserParameters",
          component: () => import("@/views/enduser/home/MyParameters.vue"),
        },
        {
          path: "",
          name: "UserHomeFourOhFour",
          component: () => import("@/views/anonymous/home/404.vue"),
        },
        {
          path: "*",
          name: "UserFourOhFour",
          component: () => import("@/views/anonymous/home/404.vue"),
        },
      ],
    },
    {
      path: "/:locale/pro",
      component: () => import("@/layouts/serviceprovider/Index.vue"),
      children: [
        {
          path: "",
          name: "ProHome",
          component: () => import("@/views/serviceprovider/home/MyMarket.vue"),
        },
        {
          path: "account",
          name: "ServiceProviderMyAccount",
          component: () => import("@/views/serviceprovider/home/MyAccount.vue"),
        },
        {
          path: "resource",
          name: "ServiceProviderMyResource",
          component: () =>
            import("@/views/serviceprovider/home/MyResource.vue"),
        },
        {
          path: "service",
          name: "ServiceProviderMyServices",
          component: () =>
            import("@/views/serviceprovider/home/MyServiceList.vue"),
        },
        {
          path: "globalparam",
          name: "ServiceProviderParameters",
          component: () =>
            import("@/views/serviceprovider/home/MyGlobalParameters.vue"),
        },
        {
          path: "cost",
          name: "ServiceProviderCost",
          component: () => import("@/views/serviceprovider/home/MyCost.vue"),
        },
        {
          path: "market",
          name: "ServiceProviderMarket",
          component: () => import("@/views/serviceprovider/home/MyMarket.vue"),
        },
        {
          path: "customermarket",
          name: "ServiceProviderCustomerMarket",
          component: () =>
            import("@/views/serviceprovider/home/MyCustomerMarket.vue"),
        },
        {
          path: "calendar",
          name: "ServiceProviderAgenda",
          component: () =>
            import("@/views/serviceprovider/home/MyCalendar.vue"),
        },
        {
          path: "webadm",
          name: "ServiceProviderWebAdm",
          component: () => import("@/views/serviceprovider/web/WebAdmin.vue"),
        },
        {
          path: "*",
          name: "ServiceProviderFourOhFour",
          component: () => import("@/views/anonymous/home/404.vue"),
        },
      ],
    },
    {
      path: "/:locale/res",
      component: () => import("@/layouts/resource/Index.vue"),
      children: [
        {
          path: "",
          name: "ResHome",
          component: () => import("@/views/resource/home/ResourceCalendar.vue"),
        },
        {
          path: "account",
          name: "ResourceMyAccount",
          component: () => import("@/views/resource/home/ResourceAccount.vue"),
        },
        {
          path: "globalparam",
          name: "ResourceParameters",
          component: () =>
            import("@/views/resource/home/ResourceParameters.vue"),
        },
        {
          path: "calendar",
          name: "ResourceCalendar",
          component: () => import("@/views/resource/home/ResourceCalendar.vue"),
        },
        {
          path: "*",
          name: "ServiceProviderFourOhFour",
          component: () => import("@/views/anonymous/home/404.vue"),
        },
      ],
    },
    {
      path: "*",
      name: "FourOhFour",
      component: () => import("@/views/anonymous/home/404.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  // console.log('.............................')
  // console.log(to)
  // console.log(from)
  // console.log(next)
  // console.log(Vue.metaInfo.title)
  // console.log(i18n.t('topMenuEU.agenda'))
  // console.log('.............................')
  // deal with i18n when locale is changed/set via url directly
  if (to.params.locale === from.params.locale) {
    next();
    return;
  }
  // We are passing here the value of the MiniWeb site targetted using the URL /web/searchKey
  if (to && to.params && to.params.searchkey) {
    router.searchKey = to.params.searchkey;
  }
  // We set here the locale using the one in the URL
  i18n.locale = verifyLocale(to.params.locale);
  I18n.setLanguage(verifyLocale(to.params.locale));
  // setDocumentDirectionPerLocale(locale)
  // setDocumentTitle(i18n.t('app.title'))
  // Vue.title = i18n.t('app.title')
  next();
});

export default router;
