<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display a message to the end user:
// Props:
// - snackbarTimeout: time to display the message in ms (default = 2000). Use -1 to keep it displayed
// - snackbarMessage: Message to be displayed to the user.
// Example of usage:
  <kdw-Statusbar-card
    v-model="snackbarUpdate"
    :snackbar-timeout="snackbarTimeout"
    :snackbar-message="snackbarMessage"
  />
  with snackbarUpdate that need to be set to true to display the StatusBar
// ------------------------------------------------------------------------------>

<template>
  <v-snackbar
    id="snackbarDisplay"
    v-model="snackbarDisplay"
    :timeout="snackbarTimeout"
    color="blue-grey"
    rounded="pill"
    top
  >
    {{ snackbarMessage }}
    <v-btn id="btnSnackbarDisplayClose" text @click="closeDialog">
      {{ $t("button.close") }}
    </v-btn>
  </v-snackbar>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "KDWStatusBar",

  mixins: [Heading],

  data: () => {
    return {
      snackbarDisplay: false,
      snackbarMessage: "",
      snackbarTimeout: 5000,
    };
  },

  watch: {
    snackbarDisplay: function (val) {
      // watching changes of display
      // console.log(`snackbarDisplay watcher - val is ${val}.`)
    },
  },

  methods: {
    closeDialog() {
      this.snackbarDisplay = false;
      this.$emit("input", this.snackbarDisplay);
    },
    alert(messagekey, snackbarTimeout = 5000) {
      // console.log(`$alert called ${messagekey}`)
      // NOTE if no translation is being found then messagekey becomes snackbarMessage
      this.snackbarMessage = this.$i18n.t(messagekey);
      // console.log(`$alert message will be ${this.snackbarMessage}`)
      this.snackbarTimeout = snackbarTimeout;
      this.snackbarDisplay = true;
    },
  },
};
</script>
