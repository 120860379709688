import {
  commonData,
  identityData,
  showroomData,
  servicesData,
} from "@/services/miniweb/data/formatDataMiniweb";
import { TEMPLATE_MINIWEB_LIST } from "@/services/miniweb/resolver";
// import LoadSections from '@/mixins/load-sections'

export function miniwebFormatedData(loadedParam) {
  // console.log('loaded param in miniwebFormatedData:')
  // console.log(loadedParam)
  return {
    template: {
      ...commonData(
        {
          id: TEMPLATE_MINIWEB_LIST[0].id,
          name: TEMPLATE_MINIWEB_LIST[0].name,
        },
        loadedParam,
      ),
    },
    miniwebData: {
      identity: identityData(loadedParam),
      showroom: showroomData(loadedParam),
      services: servicesData(loadedParam),
    },
    /*
        miniwebToggle: {
            identity: identityToggle(loadedParam),
            showroom: showroomToggle(loadedParam),
        },
        */
    /*
            mixins: LoadSections([
            'sections/404',
//             'sections/contact-us',
//             'sections/info',
          ]),
        view: '@/views/anonymous/miniweb/basic/Index.vue',
        */
  };
}

// export const VIEW = () => import('@/views/anonymous/miniweb/basic/Index.vue')
export default miniwebFormatedData;
