import vuetify from "vuetify/es5/locale/en";

export default {
  $vuetify: { ...vuetify },
  parameters: {
    yes: "Yes",
  },
  meta: {
    description:
      "Small and medium size companies, here is a set of digital services to save your time. Spend time on your activity, we will take care of the rest.",
    titleTemplate:
      "KIDIWI Services | Accelerated digital transformation for services providers",
    star: "*",
  },
  error404: {
    mainline: "DOH! LOOKS LIKE THIS PAGE DOESN’T EXISTS.",
    getMeOutOfHere: "Get me out of here",
  },
  topmenu: {
    login: "login",
    professional: "Professionals",
  },
  topMenuEU: {
    myRequestServices: "My projects",
    agenda: "My Calendar",
  },
  sideMenuEU: {
    account: "My Account",
    parameters: "My parameters",
    param: "Calendar parameters",
    gdpr: "GDPR",
    signOut: "Sign Out",
  },
  topMenuSP: {
    market: "Market",
    customer: "Service Requests",
    miniweb: "MiniWeb",
    agenda: "My Calendar",
    rating: "Rating",
  },
  sideMenuSP: {
    account: "My account",
    ressource: "My resources",
    services: "My services",
    product: "My products",
    param: "My parameters",
    miniweb: "My Web page",
    gdpr: "GDPR",
    cost: "My costs",
    signOut: "Sign Out",
  },
  faq: {
    title: "Frequently Asked Questions (FAQ)",
  },
  login: {
    title: "Sign-In and Sign-Up",
    signupheaderEU: "Create a User account",
    signupheaderSP: "Create a Service Provider account",
    shouldbeanemail: "Username should be an email.",
    usernotexist: "User does not exist.",
    alreadyexist: "An account with the given email already exists.",
    incorrect: "Incorrect username or password.",
    passwordpolicy:
      "Your password must be 10 characters long minimum, contain at least 1 digit, contain at least 1 uppercase letter, contain at least 1 lowercase letter, contain at least 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =)",
    usernotconfirmed: "User is not confirmed.",
  },
  hero: {
    title: "Search a service",
    line1: {
      html: "Find your services efficiently.",
    },
    line2: {
      html: "<b>MULTI-SERVICES platform</b>",
    },
    line3: "(Beauty & Wellness, Movers, Medical, Construction, Music, etc...).",
    line4: {
      html: "<b>100% digital. Zéro commission.</b>",
    },
    postalCode: "Postal code",
    city: "Search by",
    cityPostal: "Postalcode or city",
    radius: "Radius",
    searchService: "Select a service",
    searchBtn: "Search...",
    details: "Details",
    serviceTable: "Service name",
    descriptionTable: "Description",
    priceTable: "Price (TTC)",
    fixedPrice: "Fixed price (",
    quotationBased: "Quotation based",
    durationTable: "Duration",
    categoryTable: "Category",
    catTable: "Cat.",
    subcategoryTable: "Sub-category",
    subcatTable: "Sub-cat.",
    whereTable: "Where",
    actionTable: "Actions",
    searchAlert: "Please select the town and the search criteria",
    search: "Search",
    dateRequest: "Request date",
    whoRequest: "Who request",
    whereRequest: "Request from",
    authenticator: "Authenticator",
    alertAuthenticatedNotEU:
      "The authenticated person is not an end user, so the request done is not taken into account.",
    nodescription: "...",
    pleaseVisit: "Please visit our champions: ",
    alertDisconnectFromSPRole:
      "Please log out from your Service Provider role to be able to pick a service.",
  },
  pictureSelector: {
    hint: "The picture should be less than 2M bytes and you should use one of the folowing format: png, bmp or jpeg.",
  },
  worktimeDay: {
    dialogEditSlotTitle: "Edit the slot",
    start: "Start",
    end: "End",
    working: "Working",
    work: "Work",
    break: "Break",
    errorStartTimeBiggerEndTime:
      "The end time must be smaller than start time.",
    slotdeleted: "Slot deleted",
    workingSlotAlreadyExist:
      "A working slot already exist. Please modify it if needed to enlarge it.",
    breakSlotAdded: "Break slot added.",
  },
  worktimeWeek: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  dayOff: {
    byDaysPeriod: "Range by Date",
    byTimePeriod: "Range by Time",
    dialogEditDayOffTitleByTime: "Edit the Day Off by Time",
    dialogEditDayOffTitleByDate: "Edit the Day Off by dates",
    errorStartDateBiggerEndDate:
      "The start date must be smaller than end date.",
  },
  calendar: {
    start: "Start",
    end: "End",
    by: "By",
    for: "For",
  },
  ressourceService: {
    dialogRessourceService: "Add a service to the resource",
    deleteService: "Delete the service",
    alertSucessCreateRes: "Service added",
  },
  ressources: {
    title: "Managing my resources",
    mainTitle: "Managing my resources",
    tableTitle: "Resources",
    update: "Resource update",
    ressourceCreation: "Resource creation",
    ressourceModification: "Resource modification",
    details: "Details",
    workhours: "Work hours",
    visibleExternally:
      "This resource should have its own external access (through web site)",
    idLogin: "Login ID",
    dayOff: "Day off",
    services: "Services",
    bookingExternally: "This resource can be booked using the online system",
    resendCode: "Re-send code",
    alertFailCreateRes: "Fail to create the resource.",
    alertSucessCreateRes: "Ressource created",
    alertFailUpdateRes: "Fail to update the resource.",
    alertFailAlreadyExistRes:
      "Each resource uses the mail as Login Id. This mail was already used. Resource creation failed",
    alertFailPhoneNumberRes:
      "The phone number should have the following format: +33 6 11 22 33 44",
    alertSucessUpdateRes: "Ressource updated",
    alertFailDeleteRes: "Fail to delete the resource.",
    alertSucessDeleteRes: "Ressource deleted",
    deleteQuestion: "Are you sure you want to delete this ressource?",
    ressourceMissingParam:
      "We are not able to create the ressource as mandatory data are missing",
    ressourcePhoneInvalid: "The phone number is not valid",
    interval: "Interval (min)",
    interval_hint: "Duration between proposed appointments",
  },
  Booking: {
    dialogBookingTitle: "Booking a slot",
    ressourceTitle: "Ressource names:",
    AppointmentTitle: "Appointment available slot:",
    selectRessource: "Select the ressource name",
    selectAppointment: "Select the slot you like",
    selectAnyRessources: "Select any resources",
    anyRessourceSelected: "All",
    noSlotSelected: "No slot selected",
    noBookingPast: "You can not book in the past or in present day",
    noSlotRessourceSelected: "Please select a ressource and a slot!",
    bookingDone: "The booking is done",
    noAppointmentPossible: "No appointment available, change the date.",
  },
  Calendar: {
    month: "Month",
    week: "Week",
    day: "Day",
    today: "Today",
    holiday: "Holiday",
    all: "All",
  },
  button: {
    ok: "OK",
    cancel: "Cancel",
    previous: "Previous",
    validateQuestion: "Validate",
    nextQuestion: "Continue",
    contactUs: "Contact Us",
    moreInfo: "More information",
    createAccountInfo: "Get started now",
    deleteLogo: "Delete logo",
    deletePhoto: "Delete photo",
    deletePicture: "Delete picture",
    deleteRequestEU: "Delete",
    stopRequestEU: "Stop",
    startRequestEU: "Start",
    question: "Questions",
    answer: "Answers",
    newService: "New Service",
    newRessource: "New resource",
    close: "Close",
    copyCpy: "Copy company data",
    accept: "Accept",
    addBreakSlot: "Add a break slot",
    addDayOffByRangeDate: "Add a day off by date range",
    addDayOffByRangeTime: "Add a day off by time range for a specific date",
    addWorkingSlot: "Add a working slot",
    copyWorkingSlot5Days: "Copy the Monday slots to Tue-Fri days",
    copyWorkingSlotAllDays: "Copy the Monday slots to all days",
    deleteWorkingSlot: "Delete the slot",
    modifyWorkingSlot: "Modify the slot",
    saveRessource: "Save",
    addServiceInRessource: "Add a service",
    addAppointmentWithoutBooking: "Local booking",
  },
  tooltip: {
    validateEURequest: "Validate request from End User",
    cardVisitEU: "Information about the service requester",
    cardVisitSP: "Information about the service provider",
    review: "Provide a review of the service",
    openQuotation: "Open the quotation",
    editQuotation: "Create/edit a quotation",
    createQuotation: "Create a quotation",
    onSiteQuotation:
      "Visit the site of the service requester to make a quotation",
    question: "Answers provided by the service requester",
    EUValidateSPProposal:
      "I validate this proposal made by the service provider",
    EURefuseProposal: "I refuse the quotation made by the service provider",
    PickSP: "Choose this service",
    serviceOverview: "Service overview",
    deleteSP: "Delete the proposal and quotation provided",
    deleteRessource: "Delete",
    saveRessource: "Save",
  },
  aboutOurProduct: {
    mainTitle: "About our product",
    item1Title: "Select the location",
    item1SubTitle: "Location",
    item1Text:
      "Enter your postal code or the city name where you want to get your service. Adjust the distance for your service provider search.",
    item2Title: "Pick the service",
    item2SubTitle: "No intermediary",
    item2Text:
      "Type a few letters so you can pick the service you are looking for through a list.",
    item3Title: "Search",
    item3SubTitle: "No cost to customer from Kidiwi",
    item3Text:
      'Click on "Search" button to display the available services. Then, you can validate by clicking on the check. In case no service providers are found, we record your request that can be answered later on.',
  },
  contactUs: {
    text1: "HAVE QUESTIONS? LET US KNOW!",
    text2: "We are answering all your questions",
  },
  kidiwiInfo: {
    address: "Address",
    phone: "Phone",
    mail: "E-mail",
    contact: "Contact",
    general:
      "KidiwiDigital is offering 100% digital solutions to connect end users and service providers.",
    legal_title: "Legal",
    legal: "Legal conditions",
    cgu: "Terms and conditions",
    gdpr: "Data management policy",
  },
  heroPro: {
    title: "Subscribe",
    // line1: 'Sell your services efficiently.',
    line1: "Tools to drive your services",
    line2:
      "Enjoy the KIDIWI Services experience and give some visibility to your company.", // to increase your market share.',
    or: "  ",
  },
  miniwebfeature: {
    line1: "Increase your customer base.",
    line2: "All year long.",
    tagline1: "People are looking for your expertise. We offer you:",
    li1: "A web page on internet",
    li2: "A QR code to put on your window, for people in your neighborhood",
    li3: "and this is free: no commission, no engagement.",
  },
  agendafeature: {
    line1: "Your digital assistant:",
    line2: "A personalized schedule made for you.",
    tagline1:
      "An easy to use calendar to help you navigate through your business schedules:",
    li1: "Customers booking on 365/365 days, 24h/24h",
    li2: "A daily/weekly/monthly view mode",
    li3: "Global or employee view",
  },
  bookingfeature: {
    line1: "50% fewer calls during your activity",
    tagline1:
      "You have more time to focus on your business and customers. Waste no time in handling manually the cutomer's appointment.",
    li1: "Your customers can book online with the employee of his choise.",
    li2: "Your employees will have independent access to their schedules.",
    li3: "And you will receive reminder about the upcoming appointments.",
  },
  reminderfeature: {
    line1: "The appointment reminder",
    line2: "Your customers no longer missed their appointment.",
    tagline1:
      "Tired of calling your customers one by one about their appointment? Thanks to the automatic email reminder you dont have to do these calls anymore.",
    li1: "",
    li2: "",
  },
  faqFeature: {
    line1: "FAQs",
    q1: "How does Kidiwi Services work?",
    a1: "First you need to create an account with us (It's free!). Then, login to your account to provision your services using our user-friendly web interface ...and that's it! Your customers will be able to access your services from Kidiwi Services. Optionally, you can also setup a personalized page with us to have better a engagement with your customers.",
    q2: "I am not comfortable with computers. What can I do?",
    a2: "We will help you to set up everything. We can also create content for your web pages. Thanks to artificial intelligence, it only takes a few clicks. We train you for free, and we can also help you with any problems. Our software is made to help you!!",
    q3: "How much will this cost me?",
    a3: "We want to provide a free service to self-employed people, VSEs and SMEs. We will offer paid modules in the future, but the basic version you use now will remain free and unlimited. Here are some examples: quote and invoice creation module, management of a video conference module, etc.",
    q4: "I do not have any computer, is it working?",
    a4: "Yes, our solution is working whatever the device (computer, tablet, mobile phone), for both merchants and customers.",
    q5: "My service pricing is both fixed and variable. Does Kidiwi Services support it?",
    a5: "Yes! you can create a fixed price service on the one hand, but also a variable price service. For the variable price service, you can respond by issuing a quotation.",
    q6: "Do you use my customers information for other purposes?",
    a6: "No. They are your customers. If you quit Kidiwi Services, you can retrieve back all of your customers information.",
  },
  dialogInfoService: {
    information: "Information",
    review: "Reviews",
  },
  addAppointmentWithoutBooking: {
    mainTitle: "Add an appointment",
    resource: "Resource",
    service: "Service",
    guess: "Guest",
    lackDate: "Please pick an appointment date",
    lackNameInfo: "Please pick some name information",
    lackContactInfo: "Please add a contact information (mail or phone number)",
    okCreationAppoint: "Your appointment has been created",
    koCreationAppoint: "An error occur during the appointment creation",
    deletedAppoint: "Appointment deleted",
    addedAppoint: "Appointment added",
  },
  themeFeature: {
    mainTitle: "About our product",
    line1: "KIDIWI Services helps the end user to find your services.",
    line2: "The solution is extremely easy to use.",
    line3: "Try it and let us know what you think about it.",
    title1: "Services",
    text1:
      "Create your services simply & have them online easily. For a doctor, a service will be a consultation. A painter will propose to paint a room for example. A plumber, to repair the water tap, A private coach will provide lessons, etc.. There is no limit to the number of services that can be defined.",
    title2: "All platform compliant",
    text2:
      "You can access via any type of browser our application on any device: PCs, MACs, Tablets or mobile phone. No need to download an application.",
    title3: "FREE",
    text3:
      "We are offering A FREE SERVICE for both End Users and Service Providers. SIMPLE and TRANSPARENT.",
    title4: "Quotation or fixed price",
    text4:
      "The platform allow you to decide the nature of your service: Hair-cut 20€ (fixed price), doctor 23€ (fixed price), plumber could be based on quotation. When the service is based on quotation, we have created specific set of questions to help you to make a quotation (more than 2000 questions so far). You can mix quotation and fixed price services.",
    title5: "Categories & Sub-Categories",
    text5:
      "This platform is serving several professions (doctors, plumber, moving companies, etc..). If your profession is not yet into the system, you can create a category/subcategory to add it. Example: Medical/Dentist, Well-Being/Nails",
    title6: "24/7 available",
    text6: "Easy to use, our plateforme is available 24/7.",
    title7: "Resource management",
    text7:
      "Define the list of employees, their working hours and their holidays and the services they can provide. For example, a hair dresser may have 3 employees.",
    title8: "Online Booking",
    text8:
      "When the customer wants a hair dresser appointment, he can book automatically its preferred employee the day/hour he wants, and according to its availability. Our system is generating reminders. You can concentrate on your activity.",
    title9: "Your web page",
    text9:
      "You can build your web page in a few clicks using our templates. You are able to customize the text, the colors and to use your pictures. You can create your site in minutes with your own name.",
    title10: "Review",
    text10:
      "The end users have the opportunity to share their experience by adding a review and a rating to your service. You are also able to answer to their comment.",
  },
  weHelpYourSuccess: {
    mainTitle: "Service providers",
    line1:
      "We are building Digital Tools to ease your work and to help your digital transformation. We are willing to build a 360 degrees solution.",
    title1: "Psychologist",
    text1: "You are looking for an easy way to get some new customers.",
    title2: "Movers",
    text2:
      "Finally a platform for you to support your digital transformation. Our solution is here to help you.",
    title3: "Constructions companies",
    text3:
      "You need to organise your work, limit the time lost between the different deliveries. We have the solution for you.",
    title4: "Hair dresser",
    text4:
      "Easy solution to sale your fixed price cost. It is coming with online booking function<",
  },
  identity: {
    firstname: "First name",
    lastname: "Last name",
    address1: "Address line 1",
    address2: "Address line 2",
    postcode: "Postal code",
    city: "City",
    country: "Country",
    phone: "Phone number",
    phoneFormat: "Ex: +33  0601020304 (Mandatory)",
    phoneFormatOptional: "Ex: +33601020304",
    mail: "E-mail",
    companyname: "Company name",
    siret: "Company registration number:",
    consent: "Consent",
    rcs: "Companies house",
    legalStatus: "Legal Status",
    cpyFRIndividual: "Sole proprietorship",
    cpyFRAutoEntrepreneur: "Self employed person",
    cpyFRSARL: "SARL (Limited liability company)",
    cpyFRSAS: "SAS (Simplified joint-stock company)",
    cpyFRSA: "SA (Public limited company)",
    cpyFRSNC: "SNC (General partnership)",
  },
  AccountEU: {
    title: "My Account details",
    mainTitle: "My Account details",
    consent1: "By using this service you explicitly accept the",
    consent2: "terms and conditions",
    consent3: ", the ",
    consent4: "legal",
    consent5: " and the ",
    consent6: "Data management policy",
    cookie1:
      "KIDIWI Services uses cookies to ensure you the best experience on our website <a href=",
    cookie2: ' target="_blank"> Data management</a> policy.',
    alertEUNoFound: "No corresponding end user record found.",
    alertEULoadDataIssue: "Warning fail to load person data.",
    alertEUFailedUpdate: "fail to update the end user.",
    alertEUSuccessUpdate: "Data upgraded sucessfully.",
    openInNewWindow: "Open in a new window",
  },
  AccountSP: {
    title: "My Account details",
    mainTitle: "Your account parameters",
    tabCpyAccount: "My company details",
    tabAccount: "Company account contact details",
    tabInvoice: "Invoice",
    siretVerified:
      "I acknowledge that my company is referenced and all the information I have provided are correct",
    pickLogo: "Pick a logo",
    pickPhoto: "Pick a photo",
    pickPicture: "Pick a picture",
    sizeLogo: "File size should be less than 2 MB!",
    alertSPNoFound: "No user found.",
    alertSPLoadDataIssue: "We did not succeed to download your data.",
    alertSPFailedUpdate: "We did not succeed to upgrade the user data.",
    alertSPSuccessUpdate: "Data upgraded sucessfully.",
    required: "Mandatory field!",
    mailFormat: "Please enter a valid email",
    dataCopied: "DataCopied",
    // Validation of the company data
    alertCpyNameNotSpecified:
      'Company name is not defined (tab "My Company details")',
    alertCpySiret: 'The SIRET number is not defined (tab "My Company details")',
    alertCpyRcs: 'The company house is not defined (tab "My Company details")',
    alertCpyLegalStatus:
      'The legal status is not defined (tab "My Company details")',
    alertCpyAddress1:
      'The first line of the company address is not defined (tab "My Company details")',
    alertCpyPostcode:
      'The postal code of the company address is not defined (tab "My Company details")',
    alertCpyserviceProviderPostcodecitycpyId:
      'The town of the company address is not defined (tab "My Company details")',
    alertCpyserviceProviderCountrycpyId:
      'The country of the company address is not defined (tab "My Company details")',
    alertCpyPhone:
      'The phone number of the company is not defined (tab "My Company details")',
    alertCpyEmail:
      'The mail of the company is not defined (tab "My Company details")',
    alertCpyConsent:
      'The company consent should be given to use KIDIWI Services (tab "My Company details")',
    alertCpySiretVerified:
      'You must confirm that your SIRET number is valid to use KIDIWI Services (tab "My Company details")',
    // Validation of the personal data of the responsible
    alertFirsname:
      'The firstname of the personal details is not defined (tab "Company Account Contact details")',
    alertLastname:
      'The lastname of the personal details is not defined (tab "Company Account Contact details")',
    alertAddress:
      'The first line of the personal details address is not defined (tab "Company Account Contact details")',
    alertPostcode:
      'The postal code of the personal details address is not defined (tab "Company Account Contact details")',
    alertserviceProviderPostcodecityId:
      'The town name of the personal details address is not defined (tab "Company Account Contact details")',
    alertserviceProviderCountryId:
      'The country name of the personal details address is not defined (tab "Company Account Contact details")',
    alertPhone:
      'The phone number of the personal details is not defined (tab "Company Account Contact details")',
    alertEmail:
      'The mail of the personal details is not defined (tab "Company Account Contact details")',
    alertConsent:
      'The personal consent should be given to use KIDIWI Services (tab "Company Account Contact details")',
  },
  CalendarEU: {
    title: "My Appointments",
  },
  CalendarSP: {
    title: "My Agenda",
  },
  RequestEU: {
    title: "My projects",
    mainTitle: "My projects",
    dialogQuestion: "Submitted questions and answers",
    proposalSPYes: "More proposal OK",
    proposalSPNo: "No more proposal",
    serviceProvider: "Service Provider",
    state: "State",
    price: "Price (TTC)",
    quotation: "Quotation",
    note: "Note",
    legal: "Legal",
    action: "Actions",
    appoint: "Appointment",
    addAppoint: "Add appointment",
    delAppoint: "Delete",
    serviceName: "Service name",
    requester: "Requester",
    dateRequest: "Date Request",
    fixedPrice: "Fixed",
    quotationBased: "Quotation based",
    expirationDate: "Request expiring",
    answerState: "Answer state",
    cancelRequest: "Are you sure you want to cancel this request?",
    refuseQuote: "Are you sure you want to refuse this quote?",
    validateQuote: "Please confirm that you want to validate this quotation",
    ascendingDate: "Sort by Ascending creation date",
    descendingDate: "Sort by Descending creation date",
    category: "Sort by Category",
    search: "Search services",
    delete: "Delete",
    validprop: "Validate proposal",
    infoprov: "Info provider",
    infoReview: "Review",
    resource: "Resource",
    resourceName: "Resource name",
  },
  reviewDialog: {
    title: "Your review",
    euReviewComment: "Your review about the service provider’s service",
    spReviewComment: "Service provider response",
    review: "reviews",
  },
  marketSP: {
    title: "Finding opportunities",
    mainTitle: "Finding opportunities",
    line1:
      "We are helping you to find opportunities around you. Below the number of kilometers used for the search around your company address:",
    categoryList: "List of category used for the services search",
    result: "Results",
    proposeQuotation: "Make a proposal for this request",
    expirationDate: "Expiration Date",
    pickService: "Pick your service",
    quotationFile: "Quotation file",
    pickQuotation: "Pick your quotation",
    noteCustomer: "Your notes to customer",
    noteInternal: "Your internal notes (not disclosed)",
    legalCustomer: "Your legal description to customer",
    alertSPPostcodeNotDefined:
      "You have to define the postcode and town name of your company (My Account)!",
    alertNoResult:
      "Nothing found for the category of services your are proposing!",
    alertNoQuotation:
      "The quotation file must be loaded to validate your request!",
    alertNoService: "The service should be selected to make a proposal!",
    fixedPrice: "Fixed price",
    quotationBased: "Quotation based",
    delete: "Delete",
    questions: "Questions",
    quotationOnsite: "Onsite quotation",
    editQuotation: "Edit quotation",
    downloadQuotation: "Download the quotation",
    contactEU: "Contact user",
    Accept: "Accept",
  },
  customerSP: {
    title: "Managing ongoing requests",
    mainTitle: "Managing ongoing requests",
    serviceRequest: "Service requests",
    cancelOffer:
      "Are you sure you want to cancel the offer to the end user and to stop to take care of this deal?",
    validateRequest: "Are you sure you want to validate the End user request?",
  },
  serviceSP: {
    title: "Manage my services",
    mainTitle: "Service list",
    subTitle: "My service list",
    categories: "Categories",
    subcategories: "Sub-catégories",
    newCategory: "New category?",
    newSubCategory: "New sub-category?",
    serviceDescription: "Service description",
    serviceName: "Service Name",
    internalNote: "Internal note (not shared with customer)",
    legalNote: "Legal note",
    fixedPriceService: "Fixed price service",
    quotationService: "Quotation based service",
    defaultLegal: " Default legal value",
    serviceOpen: "Service shared with customer?",
    serviceAutoConfirmed: "Is the service automatically accepted?",
    serviceDuration: "Service Duration",
    DurationDD: "Duration (DD)",
    DurationDD_hint: "Day(s)",
    DurationHH: "Duration (HH)",
    DurationHH_hint: "Hour(s)",
    DurationMM: "Duration (MM)",
    DurationMM_hint: "Minute(s)",
    beforeDuration: "Buffer Before",
    beforeDuration_hint: "Minute(s)",
    afterDuration: "Buffer After",
    afterDuration_hint: "Minute(s)",
    note: "Note: This service is taking into account the company postal code and the city you have registered for the localisation",
    deleteQuestion: "Are you sure you want to delete this service?",
    ajoutServiceNote:
      "You can add a service by clicking on the button: New Service.",
    alertFailFetchCategory: "Warning: we failed to download the categories.",
    alertFailFetchSubCategory:
      "Warning: we failed to download the sub-categories.",
    alertFailDeleteService: "Warning: we failed to delete the service",
    alertFailLoadService: "Warning: we failed to load your services.",
    alertFailLoadSubCategories:
      "Warning: we failed to download the sub-categories.",
    alertFailUpdateService: "Warning: we failed to update your service.",
    alertSuccessUpdateService: "We success to update your service.",
    alertFailUpdateCategory: "Warning: we failed to update the categories.",
    alertSuccessUpdateCategory: "We success to update the category.",
    alertFailUpdateSubCategory:
      "Warning: we failed to update the sub-categories.",
    alertSuccessUpdateSubCategory: "We success to update the sub-category.",
    alertSuccessServiceCreated: "Service created",
    alertFailServiceCreated: "Warning: the service creation failed",
    alertSuccessCategoryCreated: "Category created",
    alertFailCategoryCreated: "Warning: the category creation failed",
    alertSuccessSubCategoryCreated: "Sub-category created",
    alertFailSubCategoryCreated: "Warning: the sub-category creation failed",
    activated: "Activated",
    deactivated: "Deactivated",
    onlineBooking: "Book online",
    serviceUpdate: "Service updated",
    serviceCreation: "Create Service",
    serviceModification: "Modify Service",
    alertCategorySelected:
      "Warning: the category must be selected or a category must be défined.",
    alertServiceNameMandatory: "Warning: the service name is mandatory.",
    alertPriceNumberOnly:
      "Warning: the service price must contain only numbers.",
    alertPriceMustDefine:
      "Warning: the service price must not be 0 if you pick this option.",
    alertDayNumber: "Warning: the day duration must contain only numbers.",
    alertHourNumber: "Warning: the hour duration must contain only numbers.",
    alertMinutesNumber:
      "Warning: the minutes duration must contain only numbers.",
    alertDurationBeforeNumber:
      "Warning: the minutes before duration must contain only numbers.",
    alertDurationAfterNumber:
      "Warning: the minutes after duration must contain only numbers.",
    bookingOnline: "Book Online",
    alertCanNotDeleteService:
      "We can not delete your service because it has been already used by some End User. Only de-activation is possible.",
    alertCanNotModifyServicePriceType:
      "We can not modify your service pricing type because it has been already used by some End User. Please create a new one.",
    noServices: "No services specified yet. You can add some!",
    alertCategoryAlreadyExist:
      "You are tyring to add new categories/subcategories. This category already exist or the combinaison of category and sub-category already exist. You can not add it.",
  },
  paramEU: {
    title: "Settings",
    mainTitle: "Settings",
    language: "Language",
    language_line1:
      "Definir communication preferences. Choose the language used in mail exchanges.",
    english: "English",
    french: "French",
    alertUpdateOK: "Your parameters have been updated.",
  },
  paramSP: {
    title: "Settings",
    mainTitle: "Settings",
    searchRadiusOption: "Search radius",
    searchRadiusOption_line1:
      'Default search radius in "Market" in kilometers.',
    searchRadiusOption_line2:
      "The reference point is the postcal code and the town defined at company level (My account).",
    currentSubscriptionOption: "Current subscription",
    stopService: "Stop service",
    kidiwiBasic: "KIDIWI Basic",
    alertUpdateOK: "Your parameters have been updated.",
    walletOption: "Cost control",
    walletOption_line1:
      "This section is helping to define the maximal pay per use cost that you are planning to use next month. This value can not be decreased during the month.",
    minWalletValue: "€ (Minimum usage cost)",
    legalOption: "Legal",
    legalOption_line1:
      "Used to define the legal text to be used by default when a service is created",
    language: "Language",
    language_line1:
      "This option is allowing you to define your preferences for language. This will be applied for mail exchanges.",
    english: "English",
    french: "French",
    planBased: "Susbcribed plan:",
    textYearMonthBased: "Pick your preferred subscription method:",
    monthBased: "Monthly",
    yearBased: "Yearly",
    subscriptionDate: "Subscription date to the plan:",
    canNotChangeSubscriptionTooEarly:
      "You picked an annual fee, so you can not change the subscription type now!",
  },
  costSP: {
    title: "Cost management",
    mainTitle: "Cost management",
    pastMonthlyInvoiceOption: "Previous invoices",
    pastMonthlyInvoiceOption_title: "Monthly invoices",
    pastMonthlyInvoiceOption_date: "Date",
    MonthlyConsumptionOption: "Monthly consumption",
    amountWallet: "Maximum amount for the pay per use:",
    costNature: "Details of your consumption",
    hit: "Hit(s)",
    cost: "Cost HT(€)",
    total: "Current montly pay per use consumption:",
    freeTrial: "Free trial duration (",
    freeTrial_Ending: " month):",
    freeTrial_OnGoing: "On going",
    freeTrial_Ended: "Ended",
    totalBill: "Current montly consumption to invoice:",
    countMonthRelation:
      "Number of connection between members used during the month",
    countMonthLogoUsed: "Have you used the logo during the period",
    countMonthMaxActiveService:
      "Maximum number of service used during the month",
    monthlyFee: "Monthly basic cost:",
    yearlyFee: "Yearly basic cost this month:",
    yes: "YES",
    no: "NO",
    walletIncreaseBtn: "Wallet + 1€",
    walletOver: "Wallet over!!",
  },
  pricingSection: {
    titleLine1: "Select Pricing Plan",
    titleLine2: "Here is the plan we are propsing to start with",
    monthly: "Monthly",
    yearly: "Yearly",
    basic_Title_monthly: "Basic Monthly",
    basic_Title_yearly: "Basic Yearly",
    per: "PER",
    month: "MONTH",
    year: "YEAR",
    basic_SubTitle_monthly: "Best Plan for opportunistic Businesses",
    basic_SubTitle_yearly: "Best Plan for recurrent Businesses",
    payPerUse: "Pay per use",
    payPerUse_Basic_Relation: "Relational cost",
    payPerUse_Basic_Logo: "Logo display cost",
    payPerUse_Basic_Service: "Additional service cost (>1)",
    payPerUse_Basic_Spending: "Spending controler cost",
    payPerUse_Basic_Logo_description:
      "If the logo is used at least once a month, it will be charged on a monthly basis, whatever the usage duration.",
    payPerUse_Basic_Relation_description:
      "Everytime KidiwiServices is building a relation between a Member user and Menber Service Provider (and reverse), the charge applies and counting as 1.",
    payPerUse_Basic_Service_description:
      "Amount multiplied by the maximum number of services defined during the month minus 1.",
    payPerUse_Basic_Spending_description:
      "Mechanism to limit the pay per use charges applied to the Member Service provider. It defines a maximum between 5 euros and the value defined by the SP. The Service Provider can increase anytime the maximum amount defined for the current month. This value is reset to the default value set in the parameters at the beginning of each next month by changing the setting the month before.",
    price: "Price (€ HT)",
    description: "Description",
  },
  generalSearchResults: {
    filter: "Filter",
    filterNameUp: "Name↑",
    filterNameDown: "Name↓",
    filterPriceUp: "Price↑",
    filterPriceDown: "Price↓",
    filterCategoryUp: "Category↑",
    filterCategoryDown: "Category↓",
    filterSubCategoryUp: "SubCategory↑",
    filterSubCategoryDown: "SubCategory↓",
    filterServiceRequestNameUp: "ServiceName↑",
    filterServiceRequestNameDown: "ServiceName↓",
    filterServiceRequestRequestDateUp: "Request date↑",
    filterServiceRequestRequestDateDown: "Request date↓",
    filterServiceRequestExpiryDateUp: "Expiry date↑",
    filterServiceRequestExpiryDateDown: "Expiry date↓",
    filterServiceRequestAppointDateUp: "Appointment date↑",
    filterServiceRequestAppointDateDown: "Appointment date↓",
    rowsByPage: "Rows by page:",
    all: "All",
  },
  requestMng: {
    noServiceRequest: "There is no Service Request so far!",
  },
  marketSearchResults: {
    noNewOpportunity: "There is no new opportunity so far!",
    someOpportunity: "SOME OPPORTUNITIES FOR YOU",
  },
  miniweb: {
    title:
      "All the parameters mentionned below will allow you to define in a few clicks your webpage with Kidiwi Services.",
    companyParam: "Company parameters",
    webParam: "Web Site parameters",
    yourSiteName: "Your website name",
    yourSiteNameHint:
      "Kidiwi Services will verify that the name is unique in our plateform.",
    addKeyword: "Add a keyword",
    openSite: "Open Site",
    keyword: "Type your keyword",
    keywordHint: "The keywords help the search engines to find you.",
    alertDuplicateWebSiteName:
      "This web site name is already used. Please pick another one",
    alertSpaceWebSiteName:
      "The web site name should not contain any space or upper case.",
    alertUpdateSuccess: "Your web site parameter is updated correctly.",
    alertUpdateFailed: "Your web site parameter update FAILED.",
    alertSPFailedLoad: "We haven’t success to load the data. Please try again.",
    pickTemplate: "Pick the web page template style",
    pickTemplateHint:
      "For the moment, you can pick Elegant template only. Basic template is not yet ready.",
    pickColor: "Pick the theme color",
    pickColorHint: "This will change the color gradiant from your website",
    deleteMiniWebButton: "Delete my web site",
    CreateMiniWebButton: "Create my web site",
    alertCreationSuccess:
      "Your web site creation went well. Adjust your parameters",
    alertCreationFailed: "Your web site creation FAILED.",
    deleteMiniWebRequest: "Do you want to delete your web site?",
    alertDeletionSuccess: "Your web site deletion went well. ",
    alertDeletionFailed: "Your web site deletion FAILED.",
    // For the miniweb front
    noMoreWebSite: "The service provider have decided to stop its activity!",
    contact: {
      email: "Email:",
      phone: "Contact:",
    },
    address: {
      address: "Address:",
    },
    book: "Book!",
    showroomParam: "Showroom parameters",
    showroomDescription: "Description of your showroom",
    showroomPriceRange: "Price range",
    showroomScore: "Rating",
    showroomJobName: "Job name",
    showroomDescriptionHint: "This text will be visible on your web site",
    showroomBackgroundPicture: "Showroom picture background:",
    showroomPicturesLoop: "Picture automatic cycle",
    showroomPictures: "Showroom pictures:",
    servicesParam: "Parameters of the service web page",
    servicesDescription: "Description of your service web page",
    servicesDescriptionHint: "This text will be visible on your web site",
    servicesBackgroundPicture: "Service picture background:",
  },
  categoryLib: {
    alertFailFetchCat: "Fail to fetch a category.",
    alertFailCreateCat: "Fail to create the category.",
  },
  consentLib: {
    alertNoSPFound: "We could not find the associated record.",
    alertSetLastName: "Please set your last name in MyAccount.",
    alertSetFirstName: "Please set your first name in MyAccount.",
    alertSetCompanyName: "Please set the company name in MyAccount.",
    alertSetSiretValue: "Please set the siret value in MyAccount.",
    alertSetCompanyAddressLine1:
      "Please set the company address line 1 in MyAccount.",
    alertSetCompanyPostCode: "Please set the postcode in MyAccount.",
    alertSetCompanyEmail: "Please set the company e-mail in MyAccount.",
    alertSetCompanyConsent: "Please check the consent flag in MyAccount.",
    alertSetCity: "Please set the city name in MyAccount.",
    alertSetCountry: "Please set the company country in MyAccount.",
    alertSetValidateSiret:
      "Please set the validate that your siret number is valid in MyAccount.",
    alertBanned:
      "Please contact the customer service as you have been blocked by our service (payment, non compliance to our policies, etc.)",
  },
  costLib: {
    alertNoCostLoaded: "Warning fail to load the monthly cost.",
  },
  distanceLib: {
    alertIssueFetchingRequest:
      "Issue while fetching request in the mentionned radius",
  },
  parameterLib: {
    alertFailLoad: "Warning: fail to load Service Provider parameters.",
    alertFailSave: "Warning: fail to save the Service Provider parameters.",
  },
  postalCodeLib: {
    alertFailLoad: "Warning: fail to load the postal codes.",
    alertFailGPSCoordinate:
      "Waring: we could not find the GPS coordianate from postal code.",
    alertFailGetTownFromPostalCode:
      "Warning: We could not find the city from the postal code.",
  },
  requestLib: {
    alertQuotationIdentical:
      "it is the same file, so we are not updating the quotation file",
    toQuote: "To quote",
  },
  serviceLib: {
    alertFailLoad: "Warning: fail to load the services.",
  },
  formatterLib: {
    quotationBased: "Quote based",
  },
  stateMachineLib: {
    answer_PENDING_QUOTATION: "Pending quote",
    answer_ONSITE_QUOTATION: "Onsite quote",
    answer_QUOTED: "Quoted",
    answer_CONTRACT_VALIDATED: "Contract valided",
    answer_QUOTE_EXPIRED: "Quote expired",
    answer_QUOTE_REFUSED_BY_EU: "Quote refused by user",
    answer_QUOTE_CANCELLED_BY_SP: "Quote cancelled by Service Provider",
    answer_REFUSE_TO_QUOTE_BY_SP: "Service Provider refuse to quote",
    answer_CONTRACT_CANCELLED_BY_EU: "Contract cancelled by user",
    answer_CONTRACT_CANCELLED_BY_SP: "Contract cancelled by service provider",
    answer_REQUEST_CANCELLED_BY_EU: "Request cancelled by user",
    answer_REQUEST_EXPIRED: "Answer expired",
    request_STARTED: "Request started",
    request_VALIDATED: "Request validated",
    request_CANCELLED_BY_EU: "Request cancelled by user",
    request_EXPIRED: "Request expired",
  },
  cat: {
    elevator: "Elevator",
    airConditioning: "Air conditioning",
    doorWindow: "Window - Door",
    garden: "Garden - Outside",
    painting: "Painting",
    craft: "Craft",
    housebuilding: "Building, renovating house",
    roof: "Roof cover",
    housediagnotic: "Real estate diagnosis",
    demolition: "Demolishing - Evacuating",
    isolation: "Isolation",
    cabinetry: "Cabinetry",
    plumbing: "Plumbing",
    locksmith: "Locksmith",
    floortiling: "Floor tiling",
    plaster: "Plaster",
    heating: "Heating",
    pestcontrol: "Pest control",
    security: "Security",
    cleaning: "Cleaning",
    kitchen: "Kitchen",
    moving: "Moving",
    electricity: "Electricity",
    event: "Event",
    music: "Music",
    medical: "Medical",
    beauty: "Beauty & wellness",
    computerElectronics: "Computer & Electronic",
    sport: "Sport",
    teaching: "Teaching",
    course: "Course",
    therapist: "Therapist",
    immo: "Real estate",
    insurance: "Insurance",
    communication: "Communication",
    design: "Design",
    chimney: "Chimney Engineering",
  },
  subCat: {
    stair: "Stair",
    blinder: "Blinder",
    gardenHouse: "Garden shed",
    setDressing: "Custom closet/dressing/cupboard design builder",
    accessHandicap: "Access for people with reduced mobility",
    architectBuilding: "Architect (renovation construction)",
    achitectGardener: "Garden designer",
    automaticWaterDispenser: "Automatic sprinkler",
    centralisedAirCollect: "Central ventilation",
    helpSmallCraft: "Installation assistance - repairing (small jobs)",
    computerCable: "Setting cables for computers",
    tile: "Tiling",
    coldChamber: "Cold room",
    roofCarpenter: "Wood and metallic frame",
    heaterCentralMaintenance: "Boiler (maintenance et repair)",
    hearterInstall: "Heating system (installation, remplacement)",
    heaterOutside: "Outdoor heating system",
    hearderSwimmingPool: "Swimming pool heater",
    gazContainer: "Gaz tank",
    airCond: "Air conditioner",
    garageBuilding: "Garage construction",
    buildBuilding: "Building construction",
    buildHouse: "House construction",
    buildMezzanine: "Mezzanine construction",
    buildGarden: "Garden & green spaces",
    buildOutsideTile: "Outdoor tiling",
    diagnosisWater: "House sanitation evaluation",
    diagnosisElectric: "House electric evaluation",
    diagnosticIsolation: "House enegetic performance evaluation (DPE)",
    diagnosisLead: "House lead and gaz evaluation",
    destruction: "Demolition (building, walls...)",
    killRoofMousse: "Roof tile cleaning",
    killPigeon: "Pigeon removal",
    killRats: "Rat removal",
    removeAsbestos: "Asbestos removal, evaluation",
    killInsects: "Pest removal",
    ligthOutside: "Outdoor lightning",
    ligthInside: "Indoor lightning",
    gardenMaintenance: "Garden maintenance",
    roofLeak: "Roof terrasse sealing",
    frontage: "Facade",
    windowsInstall: "Window - Window door: Install or remplace",
    windowRoof: "skylight",
    houseBase: "House fundation",
    fountain: "Outdoor fountain",
    stairRailing: "Barrier",
    mainHousework: "Main house work",
    hearterInstallReplace: "Heater installation, replacement",
    bathroomInstall: "Bathroom installation, renovation",
    roofInstall: "Roof installation, renovation",
    electricInstall: "Electric installation",
    elevatorInstall: "Elevator installation, replacement",
    loadElevator: "Load lift installation",
    intercom: "Interphone / Videophone",
    roofIsolation: "Attic/roof isolation",
    isolationOutside: "Exterieur insulation",
    isolationSound: "Sound insulation",
    isolationThermal: "Thermal insulation",
    cleaningWindow: "Window cleaning",
    cleaningOffice: "Office cleaning",
    buildingProjectManager: "Building project manager",
    carpet: "Carpet cleaning",
    removeCleanIndustrial: "Inductrial cleaning - Graval removal",
    solarPanel: "Solar panel",
    paintInside: "Indoor painting",
    paintOutside: "Outdoor painting",
    smallElectricRepair: "Small electric job",
    smallBuildingRepair: "Small building job",
    smallPlumberingRepair: "Small plumbing job",
    SwimingPool: "Swimming pool",
    ceilingHeater: "Heated ceiling",
    groundHeater: "Floor heating",
    plumbering: "Plumbing (new, renovation)",
    heatPump: "Heat pump",
    entranceDoor: "Entrance door / Door shielding",
    insideDoor: "Indoor door",
    garageDoor: "Garage door",
    renewWoodFlooring: "Wood flooring renewal",
    well: "Well",
    replaceWindow: "Window glass replacement",
    colletRainWater: "Rainwater collector",
    collectHeat: "Heat recovery unit",
    repairWoodingFloor: "Wood flooring renovation (sanding, vitrification)",
    openWall: "Wall opening / Wall drilling ",
    buildConcreteGround: "Concrete floor building",
    buildPlasticGround: "Vinyl flooring",
    houseElevation: "House elevation",
    earthwork: "House earthworks",
    roofPlant: "Green roof",
    analysisHouse: "Real estate evaluation or diagnostic",
    difficultAccessJob: "Acrobatic work or difficult access work",
    houseAirControl: "Flow control mechanical ventilation",
    windowShop: "Shop window",
    automaticBlinder: "Automatic blinders",
    birthday: "Birthday",
    mariage: "Wedding",
    wineTesting: "Wine Tasting",
    guitar: "Guitar lessons",
    piano: "Piano lessons",
    violon: "Violon lessons",
    drums: "Drum lessons",
    bass: "Bass lessons",
    haircutter: "Hairdresser",
    pedicure: "Pedicure",
    manucure: "Manicure",
    massage: "Massage",
    hairremoval: "Hair removal",
    anatomo: "Anatomo-Cyto-Pathologist",
    anesthesiologist: "Anesthesiologist Resuscitator",
    radiation: "Radiation oncologist",
    medicalOncologist: "Medical oncologist",
    cardiologist: "Cardiologist",
    generalSurgeon: "General surgeon",
    infantSurgeon: "Infant surgeon",
    maxillofacialSurgeon: "Maxillofacial surgeon",
    maxillofacialSurgeonStomatologist:
      "Maxillofacial surgeon and stomatologist",
    oralSurgeon: "Oral surgeon",
    orthopedicSurgeon: "Orthopedic surgeon",
    orthopedicSurgeonTraumatologist: "Orthopedic surgeon and traumatologist",
    plasticSurgeon: "Plastic surgeon",
    thoracicCardiovascularSurgeon: "Thoracic and cardiovascular surgeon",
    urologicalSurgeon: "Urological surgeon",
    vascularSurgeon: "Vascular surgeon",
    visceralSurgeon: "Visceral surgeon",
    dentist: "Dentist",
    dentalSurgeonSpecializingInDentofacialOrthopedics:
      "Dental surgeon specializing in dentofacial orthopedics",
    dentalSurgeonsSpecializingInOralSurgery:
      "Dental surgeons specializing in oral surgery",
    dentalSurgeonsSpecializingInOralMedecine:
      "Dental surgeons specializing in oral medecine",
    dermatolog: "Dermatologist and venerologist",
    endocrinologistDiabetologist: "Endocrinologist-diabetologist",
    gastroenterologistHepatologist: "Gastroenterologist and hepatologist",
    geriatrician: "Geriatrician",
    gynecologist: "Gynecologist",
    gynecologistObstetrician: "Gynecologist Obstetrician",
    hematologist: "Hematologist",
    biologist: "Biologist",
    doctor: "Doctor - General practitioner",
    geneticist: "Geneticist",
    nuclearMedicineSpecialist: "Nuclear medicine specialist",
    medicalSpecialistInPublicHealthAndSocialMedicine:
      "Medical specialist in public health and social medicine",
    nephrologist: "Nephrologist",
    neurosurgeon: "Neurosurgeon",
    neurologist: "Neurologist",
    neuropsychiatrist: "Neuropsychiatrist",
    obstetrician: "Obstetrician",
    ophthalmologist: "Ophthalmologist",
    otorhinolaryngologist: "Otorhinolaryngologist",
    OtorhinolaryngologistCervicoFacialSurgery:
      "Otorhinolaryngologist and cervico-facial surgery",
    pediatrics: "Pediatrician",
    pulmonologist: "Pulmonologist-Respirologist",
    psychiatrist: "Psychiatrist",
    psychiatristChild: "Child and adolescent psychiatrist",
    radiologist: "Radiologist",
    radiotherapist: "Radiotherapist",
    medicalResuscitator: "Medical resuscitator",
    rheumatologist: "Rheumatologist",
    midwife: "Midwife",
    internalMedicineSpecialist: "Internal medicine specialist",
    specialistInPhysicalMedicineAndRehabilitation:
      "Specialist in physical medicine and rehabilitation",
    Stomatologue: "Stomatologue",
    psychologist: "Psychologist",
    psychoanalyst: "Psychoanalyst",
    nutrionist: "Nutrionist",
    softInstall: "Software installation",
    repairMobile: "Mobile phone repair",
    repairComputer: "Computer repair",
    personalTrainer: "Personal Trainer",
    teachingFrench: "French",
    courseLinguistic: "Linguistics",
    naturopath: "Naturopath",
    naturopathH: "Naturopathy tips for healthy living",
    quantumHypnosis: "Quantum Healing Hypnosis",
    quantumHypnosisF: "Quantum Healing Hypnosis",
    hypnosis: "Hypnosis",
    spirituality: "Spirituality",
    mower: "Lawn mower",
    rototiller: "Rototiller",
    chainsaw: "Chainsaw",
    insuranceGoods: "Goods, Persons, Loan",
    juiceDetox: "Full Health Detox Juice Workshop",
    digitalCom: "Digital Communication",
  },
  k: {
    elev: {
      load: "Hoist",
      floor: "Floor",
      auto: "winch",
    },
    craft: {
      hammer: "Hammer",
      screw: "Screw",
      screwdrive: "Screwdrive",
      pin: "Nail",
      repair: "repair",
      fix: "Fix",
    },
    heat: {
      heater: "Heater",
      climatisation: "Air-conditioning",
      climMachine: "split",
      inertial: "Inertia",
      fluid: "Fluid",
      solar: "Solar",
    },
    aircon: {
      rev: "Reversible",
      split: "split",
    },
    build: {
      blockP: "Concrete block",
      iron: "Iron reinforcement ",
      blockA: "brick",
      concrete: "Concrete",
      ciment: "Cement",
      tile: "tile",
      gutter: "Gutter",
      cladding: "Cladding",
      scaffolding: "Scaffolding",
      fence: "Fence",
    },
    roof: {
      tile: "Roof tile",
      photovoltaic: "Photovoltaic",
      frame: "Frame",
    },
    kit: {
      designer: "Kitchen designer",
      furniture: "Furniture kit",
      fridge: "Fridge",
      plate: "Induction plate",
      ceramic: "Vitroceramic",
      oven: "Oven",
      microwave: "Microwave",
      equip: "Equiped kitchen",
      tailored: "Tailored kitchen",
      hood: "Kitchen fan",
      induction: "Induction stove",
      piano: "Gaz stove",
    },
    diag: {
      estate: "Real estate",
      asbestos: "Asbestos",
      switchboard: "Switchboard",
      differential: "Differential interruptioner",
      termite: "Termite",
      thermite: "Thermite",
      risk: "Natual risk",
      dpe: "DPE Diagnosis of Energy Performance",
    },
    break: {
      rubble: "Graval",
      hammer: "Hammer",
      truck: "Truck",
      rubblebag: "Graval bag",
      disposal: "Garbage disposal",
      jackhammer: "Jackhammer",
    },
    cabinetry: {
      woordwork: "Wood work",
      window: "Window",
      furniture: "Furniture",
      kitchen: "Kitchen",
      door: "Door",
      firedoor: "Fire security door",
      hatch: "Hatch",
    },
    elec: {
      switchboard: "Switchboard",
      differential: "Differential interruptioner",
      lamp: "Lamp",
      led: "Led",
      lead: "Fuse",
      socket: "Consent plug",
      switch: "Switch",
      groundPlug: "Socket",
      spot: "Spot light",
      electrician: "Electrician",
    },
    window: {
      carpentry: "Carpentry",
      uisserie: "Door frame",
      servicedoor: "Service door",
      pvc: "PVC",
      alu: "Aluminium",
    },
    isolation: {
      wool: "Fiber glass",
      wadding: "Polyurethane",
      insulating: "Insulating",
    },
    clean: {
      cleaning: "Cleaner",
      cleansing: "Cleansing",
      maintenance: "Maintenance",
      desinfection: "Desinfection",
    },
    paint: {
      after: "White",
      layer: "Layers",
      pincel: "Brush",
      roll: "Paint roller",
      stain: "Lasure",
      varnish: "Varnish",
      water: "Water paint",
      painter: "Painter",
    },
    plaster: {
      tile: "Gypsum block",
      placo: "Gypsum plasterboard",
      layer: "Coating",
      plasterer: "plasterer",
      ba13: "BA13",
    },
    plumb: {
      connection: "PVC pipe",
      tap: "tap",
      toilet: "Toilet",
      unblocking: "Unblocking",
      bathroom: "Bathroom",
      cleaning: "Curage",
      leak: "Water leak",
      shower: "Shower",
      washbasin: "Washbasin",
      bidet: "Bidet",
      enclosure: "Shower panel",
      bathtab: "Bath tab",
      mirror: "Mirror",
      furniture: "Furniture",
      toiletEl: "Wall mounted toilet ",
      accessory: "Accessories",
      plumber: "Plumber",
    },
    lock: {
      key: "Key",
      key1: "Blocked",
      lock: "Lock",
      door: "Door",
    },
    ground: {
      floorTile: " Floor and wall tiles",
      tiler: "Tiler",
      tiles: "Porcelain tiles",
      sandstone: "Glazed stone tiles",
      travertin: "Ceramic tiles",
      woodfloor: "Wood floor",
      slate: "Stone",
      wood: "Wood",
      oak: "Oak",
      laminate: "Laminate",
      gerflex: "Gerflex",
      carpet: "Carpet",
      marble: "Marble",
      coco: "Coco",
      waxedconcrete: "Waxed Concrete",
    },
    secu: {
      alarm: "Alarm",
      system: "System",
      videoSurveillance: "Video surveillance",
      surveillance: "Digital",
      gardeCorps: " protection",
      bodyGuard: "Body guard",
      interphone: "Interphone",
      visiophone: "Intercom",
      camera: "Camera",
      gaz: "Gaz defense",
      defense: "Defense",
      safe: "Safe",
    },
    pest: {
      rat: "Rat",
      mouse: "Mouse",
      ant: "Ant",
      cockroach: "Cockroach",
      cockroaches: "termite",
      phytosanitary: "Phytosanitary",
      ecologic: "Ecologic",
      cleaning: "Cleaning",
      desinfection: "Desinfection",
      disinfestation: "Disinfestation",
      fumigation: "Fumigation",
    },
    event: {
      party: "Party",
      dance: "Dance",
      wedding: "Wedding",
      weddingplanner: "Wedding Planner",
      dj: "DJ",
      music: "Musicians / Band",
      photograph: "Photograph",
      cameraman: "Cameraman",
      caterer: "Caterer",
      printer: "Printer",
      announcement: "Announcement",
    },
    beauty: {
      nail: "Nail",
      leg: "Leg",
      suit: "Suit",
      esthetic: "Esthetic",
      haircut: "Haircut",
      hair: "Hair",
    },
    computerElectronics: {
      it: "IT",
      screen: "Ecran",
      powersupply: "Alimentation",
      cable: "Cable",
      word: "Word",
      excel: "Excel",
      powerpoint: "Power Point",
      nas: "NAS",
      safari: "Safari",
      internetExplorer: "Internet Explorer",
      mozilla: "Firefox Mozilla",
      edge: "Microsoft Edge",
      apple: "Apple",
      samsung: "Samsung",
      xiaomi: "Xiaomi",
      phone: "Phone",
    },
    sport: {
      bodyAttack: "BodyAttack",
      bodyBalance: "BodyBalance",
      bodyCombat: "BodyCombat",
      bodyJam: "BodyJam",
      bodyPump: "BodyPump",
      boost: "Boost Performances",
      cardio: "Cardio",
      climb_course: "Rock Climbing lessons",
      coach: "Trainer",
      collective: "Collective Courses",
      cross: "CrossFit",
      diving: "Diving",
      fit: "Get fit",
      fitness: "Fitness",
      golf_course: "Golf lessons",
      golf_trainer: "Golf Trainer",
      golf: "Golf driving range",
      hiit: "HIIT training",
      mills: "LesMills",
      muscle: "Build Muscle",
      musculation: "BodyBuilding",
      outdoor: "Outdoor",
      pilates: "Pilates",
      spinning: "Spinning",
      strech: "Streching",
      strong: "Get Stronger",
      tennis_course: "Tennis lessons",
      tennis_trainer: "Tennis Trainer",
      tennis: "Tennis Court",
      trainer: "Trainer",
      weight_training: "Weight Training",
      weight: "Loose Weight",
      weight1: "Loose Weight",
      weightlift: "Weightlifting",
      workout: "Workout",
      yoga: "Yoga",
    },
    teaching: {
      frenchteaching: "French teaching",
      englishteaching: "English teaching",
      italianteaching: "Italian teaching",
      spanishteaching: "Spanish teaching",
      mathteaching: "Mathematics teaching",
    },
    course: {
      frenchcourse: "French course FLE",
      englishcourse: "English course",
      italiancourse: "Italian course",
      spanishcourse: "Spanish course",
      mathcourse: "Mathematics course",
    },
    immo: {
      property: "Property",
      realtor: "Realtor",
      home: "Home",
      land: "Land",
      estate: "Estate",
      mortgage: "Mortgage",
      investment: "Investment",
      listing: "Listing",
      market: "Market",
    },
    insurance: {
      coverage: "Coverage",
      policy: "Policy",
      deductible: "Deductible",
      liability: "Liability",
      protection: "Protection",
      claim: "Claim",
    },
    communication: {
      brand: "Brand communication",
      marketing: "Marketing communication",
      digital: "Digital marketing",
      media: "Social media communication",
      community: "Online community management",
      content: "Content creation",
      audience: "Audience engagement",
      customer: "Customer communication",
      influencer: "Influencer marketing",
      storytelling: "Brand storytelling",
    },
    design: {
      graphic: "Graphic design",
      web: "Web design",
      logo: "Logo design",
      ux: "UX/UI design",
      interior: "Interior design",
      industrial: "Industrial design",
      fashion: "Fashion design",
      architecture: "Architectural design",
      product: "Product design",
      packaging: "Packaging design",
    },
    chimney: {
      stove: "Wood Stove",
      sweeping: "Chimney sweeping",
      insert: "Wood Insert",
      pellet: "Pellet Burning stove",
      chimney: "Chimney",
      casing: "Chimney casing",
    },
  },
};
