// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the distance computation between 2 postal codes
// ------------------------------------------------------------------------------

import {
  fecthGPSfromPostalCode,
  fecthGPSfromPostalCodeID,
} from "@/services/PostalCode";
import {
  formatAWSDateTimeIntoDateTime,
  formatServiceDurationFromM,
  formatCategoryOrSubCategoryName,
} from "@/helpers/Formatter";
import { checkPointInsideEllipse } from "@/helpers/GenericFunctions";
import { API } from "aws-amplify";
import { getUserSub } from "@/services/User";
import { RequestStateMachine } from "@/services/StateMachine";
import i18n from "@/plugins/i18n.js";
import { updateServiceProvider as updateServiceProviderMutation } from "@/graphql/mutations";
// import { getServiceProviderCountMonthAppearInSearch } from '@/graphql/kdwqueries.js'
import { getServiceProvider } from "@/graphql/queries";
import {
  spImpressionInc,
  doGqlWithAwsLambdaAuthorizer,
} from "@/services/commonTools";

// import { listServices } from '@/graphql/queries'
import {
  listServicesSimplifyDistanceSearch,
  listRequestsSimplified,
  serviceRequestsBySPIDGetRequestList,
  getServiceProviderLogo,
  getServiceProviderCompanyName,
} from "@/graphql/kdwqueries";

const LATITUDE10KM = 0.08994;
const LONGITUDE10KM = 0.124;

// Get the list of services from a given SP.
// Used by MINI WEB
export async function servicesfromOneSP(serviceProviderID, ServicesListArray) {
  try {
    var apiData;
    var nextTokenStart = "start";
    var listFromAPI;
    var duration, subCat, subCatID, shortDesc, fetchedQuestions;
    var number = 0;
    // We filter againt the selected service, and the active services
    const myFilter = {
      isActivated: { eq: true },
      and: { serviceProviderID: { eq: serviceProviderID } },
      // isActivated: { eq: true }, and: { serviceCategoryId: { eq: pickedCategoryID } },
    };
    // console.log('myFilter:', myFilter)
    while (nextTokenStart !== null) {
      if (nextTokenStart === "start") {
        apiData = await API.graphql({
          query: listServicesSimplifyDistanceSearch,
          variables: { filter: myFilter, limit: 4000 },
          authMode: "AWS_IAM",
        });
      } else {
        apiData = await API.graphql({
          query: listServicesSimplifyDistanceSearch,
          variables: {
            filter: myFilter,
            limit: 4000,
            nextToken: nextTokenStart,
          },
          authMode: "AWS_IAM",
        });
      }
      // console.log('apiData:')
      // console.log(apiData)
      listFromAPI = apiData.data.listServices.items;
      // console.log(listFromAPI)
      nextTokenStart = apiData.data.listServices.nextToken;
      // console.log('nextTokenStart-------------------------')
      // console.log(nextTokenStart)
      // console.log('nextTokenStart-------------------------')
      if (listFromAPI.length > 0) {
        // console.log('find some postal code')
        Promise.all(
          listFromAPI.map(async (service) => {
            // console.log('Inside the range:' + service.name)
            // Now we found a service, we add it to the list.
            // console.log('duration original:' + service.serviceDuration)
            duration = formatServiceDurationFromM(service.serviceDuration);
            // console.log('duration:' + duration)
            // console.log('Price or:' + service.ServicePrice)
            // price = formatServicePrice(service.isFixedPrice, service.ServicePrice)
            // console.log('Price:' + price)
            // console.log('subcat name' + service.subCategory.name)
            // console.log('subcat' + service.subCategory)
            subCat = "";
            subCatID = "";
            if (service.subCategory !== null) {
              subCat = formatCategoryOrSubCategoryName(
                service.subCategory.name,
                service.subCategory.countryCode,
                service.subCategory.nameTag,
              );
              subCatID = service.subCategory.id;
            } else {
              subCat = "";
              subCatID = "";
            }
            // console.log('lenght:' + service.serviceDescription.length)
            if (service.serviceDescription.length > 41) {
              shortDesc = service.serviceDescription.substr(0, 40) + "...";
            } else {
              shortDesc = service.serviceDescription;
            }
            fetchedQuestions = "";
            // console.log('service.category.questions:')
            // console.log(service.category.questions)
            // console.log('service.subCategory.questions:')
            // console.log(service.subCategory.questions)
            // console.log('locale:')
            // console.log(i18n.locale)
            if (service.subCategory !== null) {
              if (service.subCategory.questions !== null) {
                // we are checking which language to fetch for the questions
                if (i18n.locale === "fr") {
                  fetchedQuestions = service.subCategory.questions[0];
                } else {
                  if (
                    i18n.locale === "en" &&
                    service.subCategory.questions.length > 1
                  ) {
                    fetchedQuestions = service.subCategory.questions[1];
                  }
                }
              } else {
                if (service.category.questions !== null) {
                  // we are checking which language to fetch for the questions
                  if (i18n.locale === "fr") {
                    fetchedQuestions = service.category.questions[0];
                  } else {
                    if (
                      i18n.locale === "en" &&
                      service.category.questions.length > 1
                    ) {
                      fetchedQuestions = service.category.questions[1];
                    }
                  }
                }
              }
            } else {
              if (service.category.questions !== null) {
                // we are checking which language to fetch for the questions
                if (i18n.locale === "fr") {
                  fetchedQuestions = service.category.questions[0];
                } else {
                  if (
                    i18n.locale === "en" &&
                    service.category.questions.length > 1
                  ) {
                    fetchedQuestions = service.category.questions[1];
                  }
                }
                // fetchedQuestions = service.category.questions[0]
              }
            }
            // console.log('fetched question :' + fetchedQuestions)
            // console.log('SubCat:' + subCat)
            // ServicesListArray.push({ id: service.id, name: service.name, latitude: service.postcodecity.latitude, longitude: service.postcodecity.longitude })
            // console.log('service : ')
            // console.log(service)
            const catName = formatCategoryOrSubCategoryName(
              service.category.name,
              service.category.countryCode,
              service.category.nameTag,
            );
            // console.log('service.serviceProviderID')
            // console.log(service.serviceProviderID)
            // Get SP ID logo
            // cpyLogo = await getSPIDLogo(service.serviceProviderID)
            ServicesListArray.push({
              number: number,
              id: service.id,
              name: service.name,
              serviceDescription: service.serviceDescription,
              serviceDescriptionShort: shortDesc,
              ServicePrice: service.ServicePrice,
              serviceDuration: duration,
              category: catName,
              categoryNameTag: service.category.nameTag,
              categoryID: service.category.id,
              subCategory: subCat,
              subCategoryID: subCatID,
              townName: service.postcodecity.townName,
              question: fetchedQuestions,
              isFixedPrice: service.isFixedPrice,
              serviceProviderID: service.serviceProviderID,
              serviceAutoAccepted: service.serviceAutoAccepted,
              serviceLegaldescription: service.serviceLegaldescription,
              onlineBooking: service.onlineBooking,
              serviceRating: service.serviceRating,
              reviewNb: service.reviewNb,
            });
            number = number + 1;
            // console.log(ServicesListArray)
            // We increase an SP counter linked to the nb of time the service appear in the search.
            await spImpressionInc(service.serviceProviderID);
          }),
        );
      }
    }
  } catch (err) {
    console.log(err);
    alert("Issue while fetching services for this specific SP");
  }
}

// Calculate the distance between two coordinates by latitude and longitude, including a Javascript implementation.
// West and South locations are negative. Remember minutes and seconds are out of 60 so S31 30' is -31.50 degrees.
// Don't forget to convert degrees to radians. Many languages have this function. Or its a simple calculation: radians = degrees * PI / 180.
function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

// Get the list of services from a given location corresponding to an item refrenced by postal code ID, a range given (rayon) for a dedicated service.
// Used by HERO PAGE
export async function servicesfromOnePostalCodeRayon(
  townOriginLatitude,
  townOriginLongitude,
  rayon,
  pickedCategoryID,
  ServicesListArray,
) {
  try {
    // console.log('servicesfromOnePostalCodeRayon - Get list of service from 1 town and a range of :' + rayon)
    // var GPSA = []
    // console.log('lat or: ' + townOriginLatitude)
    // console.log('long or: ' + townOriginLongitude)
    // console.log('ID category:' + pickedCategoryID)
    var apiData;
    var nextTokenStart = "start";
    var listFromAPI;
    var duration, subCat, subCatID, shortDesc, fetchedQuestions;
    var number = 0;
    // We filter againt the selected service, and the active services
    const myFilter = {
      isActivated: { eq: true },
      and: { categoryID: { eq: pickedCategoryID } },
      // isActivated: { eq: true }, and: { serviceCategoryId: { eq: pickedCategoryID } },
    };
    // console.log('myFilter:', myFilter)
    while (nextTokenStart !== null) {
      if (nextTokenStart === "start") {
        apiData = await API.graphql({
          query: listServicesSimplifyDistanceSearch,
          variables: { filter: myFilter, limit: 4000 },
          authMode: "AWS_IAM",
        });
      } else {
        apiData = await API.graphql({
          query: listServicesSimplifyDistanceSearch,
          variables: {
            filter: myFilter,
            limit: 4000,
            nextToken: nextTokenStart,
          },
          authMode: "AWS_IAM",
        });
      }
      // console.log('apiData:')
      // console.log(apiData)
      listFromAPI = apiData.data.listServices.items;
      // console.log(listFromAPI)
      nextTokenStart = apiData.data.listServices.nextToken;
      // console.log('nextTokenStart-------------------------')
      // console.log(nextTokenStart)
      // console.log('nextTokenStart-------------------------')
      if (listFromAPI.length > 0) {
        // console.log('find some postal code')
        Promise.all(
          listFromAPI.map(async (service) => {
            // Now we need to check the range.
            // console.log(service)
            // console.log('lat: ' + service.postcodecity.latitude)
            // console.log('long: ' + service.postcodecity.longitude)
            // console.log('a= ' + Math.trunc(rayon / 10) * LATITUDE10KM)
            // console.log('b= ' + Math.trunc(rayon / 10) * LONGITUDE10KM)
            if (
              checkPointInsideEllipse(
                townOriginLatitude,
                townOriginLongitude,
                service.postcodecity.latitude,
                service.postcodecity.longitude,
                Math.trunc(rayon / 10) * LATITUDE10KM,
                Math.trunc(rayon / 10) * LONGITUDE10KM,
              ) > 1
            ) {
              // console.log('Outside the range:' + service.name)
            } else {
              // console.log('Inside the range:' + service.name)
              // Now we found a service, we add it to the list.
              // console.log('duration original:' + service.serviceDuration)
              duration = formatServiceDurationFromM(service.serviceDuration);
              // console.log('duration:' + duration)
              // console.log('Price or:' + service.ServicePrice)
              // price = formatServicePrice(service.isFixedPrice, service.ServicePrice)
              // console.log('Price:' + price)
              // console.log('subcat name' + service.subCategory.name)
              // console.log('subcat' + service.subCategory)
              subCat = "";
              subCatID = "";
              if (service.subCategory !== null) {
                subCat = formatCategoryOrSubCategoryName(
                  service.subCategory.name,
                  service.subCategory.countryCode,
                  service.subCategory.nameTag,
                );
                subCatID = service.subCategory.id;
              } else {
                subCat = "";
                subCatID = "";
              }
              // console.log('lenght:' + service.serviceDescription.length)
              if (service.serviceDescription.length > 41) {
                shortDesc = service.serviceDescription.substr(0, 40) + "...";
              } else {
                shortDesc = service.serviceDescription;
              }
              fetchedQuestions = "";
              // console.log('service.category.questions:')
              // console.log(service.category.questions)
              // console.log('service.subCategory.questions:')
              // console.log(service.subCategory.questions)
              // console.log('locale:')
              // console.log(i18n.locale)
              if (service.subCategory !== null) {
                if (service.subCategory.questions !== null) {
                  // we are checking which language to fetch for the questions
                  if (i18n.locale === "fr") {
                    fetchedQuestions = service.subCategory.questions[0];
                  } else {
                    if (
                      i18n.locale === "en" &&
                      service.subCategory.questions.length > 1
                    ) {
                      fetchedQuestions = service.subCategory.questions[1];
                    }
                  }
                } else {
                  if (service.category.questions !== null) {
                    // we are checking which language to fetch for the questions
                    if (i18n.locale === "fr") {
                      fetchedQuestions = service.category.questions[0];
                    } else {
                      if (
                        i18n.locale === "en" &&
                        service.category.questions.length > 1
                      ) {
                        fetchedQuestions = service.category.questions[1];
                      }
                    }
                  }
                }
              } else {
                if (service.category.questions !== null) {
                  // we are checking which language to fetch for the questions
                  if (i18n.locale === "fr") {
                    fetchedQuestions = service.category.questions[0];
                  } else {
                    if (
                      i18n.locale === "en" &&
                      service.category.questions.length > 1
                    ) {
                      fetchedQuestions = service.category.questions[1];
                    }
                  }
                  // fetchedQuestions = service.category.questions[0]
                }
              }
              // to cancel
              // if (service.category.questions !== null) {
              //   if (service.subCategory.questions !== null) {
              //    fetchedQuestions = service.subCategory.questions[0]
              //  } else {
              //    fetchedQuestions = service.category.questions[0]
              //  }
              // }
              // console.log('fetched question :' + fetchedQuestions)
              // console.log('SubCat:' + subCat)
              // ServicesListArray.push({ id: service.id, name: service.name, latitude: service.postcodecity.latitude, longitude: service.postcodecity.longitude })
              // console.log('service : ')
              // console.log(service)
              const catName = formatCategoryOrSubCategoryName(
                service.category.name,
                service.category.countryCode,
                service.category.nameTag,
              );
              // console.log('service.serviceProviderID')
              // console.log(service.serviceProviderID)
              // Get SP ID logo
              // cpyLogo = await getSPIDLogo(service.serviceProviderID)
              ServicesListArray.push({
                number: number,
                id: service.id,
                name: service.name,
                serviceDescription: service.serviceDescription,
                serviceDescriptionShort: shortDesc,
                ServicePrice: service.ServicePrice,
                serviceDuration: duration,
                category: catName,
                categoryNameTag: service.category.nameTag,
                categoryID: service.category.id,
                subCategory: subCat,
                subCategoryID: subCatID,
                townName: service.postcodecity.townName,
                question: fetchedQuestions,
                isFixedPrice: service.isFixedPrice,
                serviceProviderID: service.serviceProviderID,
                serviceAutoAccepted: service.serviceAutoAccepted,
                serviceLegaldescription: service.serviceLegaldescription,
                onlineBooking: service.onlineBooking,
                serviceRating: service.serviceRating,
                reviewNb: service.reviewNb,
              });
              number = number + 1;
              // console.log('ServicesListArray:')
              // console.log(ServicesListArray)
              // console.log(ServicesListArray)
              // We increase an SP counter linked to the nb of time the service appear in the search.
              await spImpressionInc(service.serviceProviderID);
            }
          }),
        );
      }
    }
  } catch (err) {
    console.log(err);
    alert("Issue while fetching services in the mentionned distance");
  }
}

// This needs to be denormalised
export async function getSPIDLogo(serviceProviderID) {
  try {
    // console.log('ServiceProviderID:')
    // console.log(serviceProviderID)
    const apiData = await API.graphql({
      query: getServiceProviderLogo,
      variables: { id: serviceProviderID },
      authMode: "AWS_IAM",
    });
    // console.log('apiData getServiceProviderLogo:')
    // console.log(apiData.data.getServiceProvider.logocpy)
    return apiData.data.getServiceProvider.logocpy;
  } catch (err) {
    console.log(err);
    return "";
  }
}

export async function getSPIDCompanyName(serviceProviderID) {
  try {
    // console.log('ServiceProviderID:')
    // console.log(serviceProviderID)
    const apiData = await API.graphql({
      query: getServiceProviderCompanyName,
      variables: { id: serviceProviderID },
      authMode: "AWS_IAM",
    });
    // console.log('apiData getServiceProviderCompanyName:')
    // console.log(apiData)
    return apiData.data.getServiceProvider.namecpy;
  } catch (err) {
    console.log(err);
    return "";
  }
}

// eslint-disable-next-line no-unused-vars
async function increaseSPCountMonthAppearInSearch(spid) {
  try {
    // read the counter
    console.log(spid);
    // console.log(getServiceProviderCountMonthAppearInSearch)
    console.log(getServiceProvider);
    const getServiceProviderCountMonthAppearInSearch = `
      query GetServiceProvider($id: ID!) {
        getServiceProvider(id: $id) {
          countMonthAppearInSearch
        }
      }
    `;
    // getServiceProviderCountMonthAppearInSearch
    const apiData = await API.graphql({
      query: getServiceProviderCountMonthAppearInSearch,
      variables: { id: spid },
      authMode: "AWS_IAM",
    });
    console.log(apiData);
    // update the counter
    const mySPData = {
      id: spid,
      countMonthAppearInSearch:
        apiData.data.getServiceProvider.CountMonthAppearInSearch + 1,
    };
    await API.graphql({
      query: updateServiceProviderMutation,
      variables: { input: mySPData },
      authMode: "AWS_IAM",
    });
  } catch (err) {
    console.log(err);
    // alert('Issue while fetching services in the mentionned distance')
  }
}

// We assume Category is always containing something.
// Example of filter behavior:
// filter:
// {
//  and: [
//    { isProposalSP: {eq: true}},
//    { isFixedPrice: {eq: false}},
//    {
//     or: [
//       { categoryID: {eq: "76a5bc2d-0a75-43c2-baf5-78f969dddd78"}},
//       { categoryID: {eq: "619ec097-7b1b-46b2-9606-3c1ab783d312"}}
//       ]
//   }
//    ]
// }
async function buildFilter(pickedCategoryIDArray) {
  // We build the filter for the Catagory ID
  var chainedCategoryFilterTable = [];
  // console.log('pickedCategoryIDArray 1:')
  // console.log(pickedCategoryIDArray)
  for (var j = 0; j < pickedCategoryIDArray.length; j++) {
    // chainedCategoryFilterTable.push(buildBasicFilteringObject('categoryID', 'eq', pickedCategoryIDArray[j].id))
    chainedCategoryFilterTable.push({
      categoryID: { eq: pickedCategoryIDArray[j].id },
    });
  }

  const chainedCategoryFilter = { or: chainedCategoryFilterTable };
  // console.log('chainedCategoryFilter1:')
  // console.log(chainedCategoryFilter)

  // We are looking for the list of RequestID already used for this SP, to be able to add them to the filter later on.
  var requestIdList = [];
  var apiData, listFromAPI;
  var nextTokenStart = "start";

  while (nextTokenStart !== null) {
    if (nextTokenStart === "start") {
      apiData = await API.graphql({
        query: serviceRequestsBySPIDGetRequestList,
        variables: { serviceProviderID: await getUserSub(), limit: 4000 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } else {
      apiData = await API.graphql({
        query: serviceRequestsBySPIDGetRequestList,
        variables: {
          serviceProviderID: await getUserSub(),
          limit: 4000,
          nextToken: nextTokenStart,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    }
    // console.log(apiData)
    listFromAPI = apiData.data.ServiceRequestsBySPID.items;
    // console.log('serviceRequestsBySPIDGetRequestList')
    // console.log(listFromAPI)
    nextTokenStart = apiData.data.ServiceRequestsBySPID.nextToken;
    if (listFromAPI.length > 0) {
      Promise.all(
        listFromAPI.map(async (request) => {
          requestIdList.push(request.request.id);
        }),
      );
    }
  }
  // console.log('requestIdList')
  // console.log(requestIdList)

  // We build the filter for the Request ID - { id: { ne: value } }
  var chainedRequestFilterTable = [];
  if (requestIdList.length > 0) {
    for (var i = 0; i < requestIdList.length; i++) {
      chainedRequestFilterTable.push({ id: { ne: requestIdList[i] } });
    }
  }

  // We are now adding the Category filter we have built before
  chainedRequestFilterTable.push(chainedCategoryFilter);
  // We are now adding the static filters
  chainedRequestFilterTable.push({ isProposalSP: { eq: true } });
  chainedRequestFilterTable.push({ isFixedPrice: { eq: false } });
  // console.log('chainedRequestFilterTable1')
  // console.log(chainedRequestFilterTable)
  // We can now return the final object
  return { and: chainedRequestFilterTable };
}

// Get the list of services from a given location corresponding to an item refrenced by postal code ID, a range given (rayon) for a dedicated service.
// USED BY MARKET PAGE
export async function requestfromOnePostalCodeRayon(
  townOriginLatitude,
  townOriginLongitude,
  rayon,
  pickedCategoryIDArray,
  requestListArray,
) {
  try {
    // console.log('requestfromOnePostalCodeRayon - Get list of service from 1 town and a range of :' + rayon)
    const mySpecialFilter = await buildFilter(pickedCategoryIDArray);
    // console.log('mySpecialFilter:')
    // console.log(mySpecialFilter)

    requestListArray.splice(0, requestListArray.length);
    var apiData, listFromAPI, myState;
    var subCat, subCatTag, fetchedQuestions;
    var nextTokenStart = "start";
    // var OK = false

    nextTokenStart = "start";
    while (nextTokenStart !== null) {
      if (nextTokenStart === "start") {
        apiData = await doGqlWithAwsLambdaAuthorizer(listRequestsSimplified, {
          filter: mySpecialFilter,
          limit: 4000,
        });
      } else {
        apiData = await doGqlWithAwsLambdaAuthorizer(listRequestsSimplified, {
          filter: mySpecialFilter,
          limit: 4000,
          nextToken: nextTokenStart,
        });
      }
      // console.log('apiData from requestfromOnePostalCodeRayon:')
      // console.log(apiData)

      listFromAPI = apiData.data.listRequests.items;
      // console.log('listFromAPI after filtering:')
      // console.log(listFromAPI)
      nextTokenStart = apiData.data.listRequests.nextToken;
      if (listFromAPI.length > 0) {
        Promise.all(
          listFromAPI.map(async (request) => {
            // This is to check if the ID is contained in the category table passed in parameter --> NO MORE NEEDED AS THIS IS IN THE FILTER
            // --> We dont need this anymore - if (pickedCategoryIDArray.some((el) => { return el.id === request.category.id })) {
            // Now we need to check the range.
            // console.log('request from requestfromOnePostalCodeRayon:')
            // console.log(request)
            if (request.postcodecity) {
              if (
                checkPointInsideEllipse(
                  townOriginLatitude,
                  townOriginLongitude,
                  request.postcodecity.latitude,
                  request.postcodecity.longitude,
                  Math.trunc(rayon / 10) * LATITUDE10KM,
                  Math.trunc(rayon / 10) * LONGITUDE10KM,
                ) > 1
              ) {
                // console.log('Outside the range')
              } else {
                // console.log('request:')
                // console.log(request)
                // OK = false
                // console.log('On or inside the ellipse')
                // -----> if (request.services.items.length > 0) {
                // Now we found a request, we need to check if this is in the category list provided:
                // This is to check if the ID is contained in the category table passed in parameter
                // console.log(request.services.items[0].service)

                /* Removed due to the new filtering
                    if (request.services.items[0].service === null) {
                      OK = true
                    } else if (request.services.items[0].service.serviceProviderID !== await getUserSub()) {
                      OK = true
                    }
                    if (OK === true) {
                    */
                subCat = "";
                subCatTag = "";
                // subCatID = ''
                if (request.subCategory !== null) {
                  subCat = request.subCategory.name;
                  subCatTag = request.subCategory.nameTag;
                  // subCatID = request.subCategory.id
                } else {
                  subCat = "";
                  subCatTag = "";
                  // subCatID = ''
                }
                // console.log('request after filtering:')
                // console.log(request)
                // console.log('request.endUser.Id:' + request.endUser.id)
                fetchedQuestions = JSON.parse(request.qna);
                myState = new RequestStateMachine(request.state, false);
                // this is in so we need to add the request to the list
                requestListArray.push({
                  endUserId: request.endUser.id,
                  requestId: request.id,
                  serviceName: request.serviceName,
                  isFixedPrice: request.isFixedPrice,
                  catName: request.category.name,
                  catNameTag: request.category.nameTag,
                  subcatName: subCat,
                  subcatNameTag: subCatTag,
                  qna: fetchedQuestions,
                  createdAt: formatAWSDateTimeIntoDateTime(request.createdAt),
                  townName: request.postcodecity.townName,
                  state: myState,
                });
                // }
                // ----> }
              }
            }
            // --> We dont need this anymore - }
          }),
        );
      }
    }
  } catch (err) {
    console.log(err);
    alert(i18n.t("distanceLib.alertIssueFetchingRequest"));
  }
}

// -----------------------------------  FUNCTIONS NOT USED ------------------------------
// Not used
export async function distanceInKmBetweenTwoTownByPostalCode(
  postalCodeTownA,
  postalCodeTownB,
) {
  try {
    // console.log('compute distance between 2 towns')
    // console.log(postalCodeTownA)
    // console.log(postalCodeTownB)
    // Retrieve the GPS coordinate of Town A
    var GPSA = [];
    await fecthGPSfromPostalCode(postalCodeTownA, GPSA);
    // console.log('---------------------------GPSA:')
    // console.log(GPSA)
    // Retrieve the GPS coordinate of Town B
    var GPSB = [];
    await fecthGPSfromPostalCode(postalCodeTownB, GPSB);
    // console.log('---------------------------GPSB:')
    // console.log(GPSB)
    const result = await distanceInKmBetweenEarthCoordinates(
      GPSA[0],
      GPSA[1],
      GPSB[0],
      GPSB[1],
    );
    console.log("result = " + result);
    return result;
  } catch {
    alert("Issue while computing the distance between 2 towns!");
  }
}

// Not Used
export async function distanceInKmBetweenTwoTownByPostalCodeID(
  postalCodeIDTownA,
  postalCodeIDTownB,
) {
  try {
    // console.log('compute distance between 2 towns')
    // console.log(postalCodeTownA)
    // console.log(postalCodeTownB)
    // Retrieve the GPS coordinate of Town A
    var GPSA = [];
    await fecthGPSfromPostalCodeID(postalCodeIDTownA, GPSA);
    // console.log('---------------------------GPSA:')
    // console.log(GPSA)
    // Retrieve the GPS coordinate of Town B
    var GPSB = [];
    await fecthGPSfromPostalCodeID(postalCodeIDTownB, GPSB);
    // console.log('---------------------------GPSB:')
    // console.log(GPSB)
    const result = await distanceInKmBetweenEarthCoordinates(
      GPSA[0],
      GPSA[1],
      GPSB[0],
      GPSB[1],
    );
    console.log("result = " + result);
    return result;
  } catch {
    alert("Issue while computing the distance between 2 towns!");
  }
}

// Not USED
async function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  console.log("calcule distance !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
  var earthRadiusKm = 6371;
  var dLat = degreesToRadians(lat2 - lat1);
  var dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  console.log(earthRadiusKm * c);
  return earthRadiusKm * c;
}
