<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to picke a date using a menu
// Props:
//
// Example of usage:
      <kdw-Date-Picker
        v-model="dateToPick"
        @dateValidated="dateValidated()"
      />
// ------------------------------------------------------------------------------>

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <!-- in v-text-field, add :value="computedDateFormatted" is replacing v-model="date" -->
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormatted"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      show-adjacent-months
      :locale="getLocale()"
    >
      <v-spacer />
      <kdw-btn text @click="menu = false">
        {{ $t("button.cancel") }}
      </kdw-btn>
      <kdw-btn @click="validateDate(date)">
        {{ $t("button.validateQuestion") }}
      </kdw-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { translateLocaleBrowserType } from "@/helpers/Navigation";
import { DateTime } from "luxon";

export default {
  name: "WorktimeDay",

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data: () => {
    return {
      menu: false,
      date: "",
    };
  },

  computed: {
    computedDateFormatted() {
      return this.date
        ? DateTime.fromISO(this.date, {
            locale: this.$i18n.locale,
          }).toLocaleString(DateTime.DATE_HUGE)
        : "";
    },
  },

  created: function () {
    // console.log(this.value)
    this.date = this.value;
  },

  methods: {
    emitResult() {
      this.$emit("input", this.date);
    },
    validateDate(date) {
      this.$refs.menu.save(date);
      this.emitResult();
      this.$emit("dateValidated");
    },
    getLocale() {
      return translateLocaleBrowserType(this.$i18n.locale);
    },
  },
};
</script>
