// import { resolveById, resolveByName } from '@/services/miniweb/resolver'
import { resolveMiniWebByCriteria } from "@/services/miniweb/resolver";
import { miniWebBySearchkey } from "@/graphql/kdwqueries";
import { API, Logger } from "aws-amplify";
import { isObjectEmpty } from "@/helpers/GenericFunctions";

const logger = new Logger("MiniwebService");

// TODO might want to add somekind of limits (size & duration) to this ever growing cache ..
const cache = {};

// Router would call Service to prefetchData upon routing
// Service (pre)fetch template.id from DB using searchkey
// Service cache data from DB
// Service calls Resolver using template.id to return corresponding vue layout

// keep a singleton map thing somewhere
export async function prefetchData(searchkey) {
  // logger.info(`prefetchData - miniweb searchkey is ${searchkey}.`)
  const result = await getMiniWebConfig(searchkey);

  // console.log('result:')
  // console.log(result)
  var resolvedData = {};
  if (isObjectEmpty(result)) {
    // console.log('Result is empty')
    resolvedData.template = {};
  } else {
    // We are converting the data from showRoomPicture into a table of pictures.
    result.showroomPictures = result.showroomPictures.split(",");
    // This data is from the template.
    result.toggleScore = true;
    resolvedData = resolveMiniWebByCriteria(result.layoutId, result);
    resolvedData.searchkey = searchkey;
  }
  // logger.info(`prefetchData - miniweb is ${resolvedVue}.`)
  // We are storing the data formatted.
  cache[searchkey] = resolvedData;
  return resolvedData; // resolvedVue
}

export async function getMiniWebConfig(searchkey) {
  // console.log('prefetchData - getMiniWebConfig - miniweb searchkey is',searchkey)
  if (searchkey in cache) {
    // console.log('prefetchData - getMiniWebConfig - miniweb searchkey in cache')
    const miniweb = cache[searchkey];
    logger.info(`cache - miniweb is ${JSON.stringify(miniweb)}.`);
    return miniweb;
  } else {
    // logger.info(`get - miniweb - fetching`)
    var miniweb = await API.graphql({
      query: miniWebBySearchkey,
      variables: {
        searchkey,
      },
      authMode: "AWS_IAM",
    });
    // logger.info(`get - miniweb straingth data are ${JSON.stringify(miniweb)}.`)
    if (
      miniweb &&
      miniweb.data &&
      miniweb.data.miniWebBySearchkey &&
      miniweb.data.miniWebBySearchkey.items
    ) {
      if (miniweb.data.miniWebBySearchkey.items.length > 0) {
        miniweb = miniweb.data.miniWebBySearchkey.items[0];
      } else miniweb = {};
    } else miniweb = {};
    logger.info(`get - miniweb is ${JSON.stringify(miniweb)}.`);
    return miniweb;
  }
}
