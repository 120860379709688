<!------------------------------------------------------------------------------
// KIDIWI Digital property
// This component is to display a vertical bar containing the standard info of the company
// for the theme Elegant.
// Props:
//  - dataTemplate: list of data to be displayed, coming from the parent component.<template>
</template>

<script>
export default {

}
</script>

<style>

</style>
// Example of usage:
      <miniweb-template-elegant-general-info
        :data-template="dataTemplate"
      />
// ------------------------------------------------------------------------------>
<template>
  <v-card :min-height="minHeight" v-bind="$attrs" v-on="$listeners">
    <v-card-actions
      v-if="dataTemplate.miniwebData.identity.logo"
      class="justify-center"
    >
      <v-avatar size="120">
        <img
          :src="logoUrl"
          :alt="dataTemplate.miniwebData.identity.companyName"
        />
      </v-avatar>
    </v-card-actions>
    <v-card-text>
      <div
        v-if="dataTemplate.miniwebData.showroom.jobName"
        class="text-h5 font-weight-black"
      >
        <v-icon :color="dataTemplate.template.themeColor">
          mdi-fountain-pen-tip
        </v-icon>
        {{ dataTemplate.miniwebData.showroom.jobName }}
      </div>
      <div v-if="dataTemplate.miniwebData.identity.townName">
        <v-icon :color="dataTemplate.template.themeColor">
          mdi-map-marker
        </v-icon>
        {{ dataTemplate.miniwebData.identity.townName }}
      </div>
      <div v-if="dataTemplate.miniwebData.showroom.priceRange">
        <v-icon :color="dataTemplate.template.themeColor">
          mdi-currency-eur
        </v-icon>
        {{ dataTemplate.miniwebData.showroom.priceRange }}
      </div>
      <div v-if="dataTemplate.miniwebData.ratings.score">
        <v-icon :color="dataTemplate.template.themeColor"> mdi-star </v-icon>
        {{ dataTemplate.miniwebData.ratings.score }}
      </div>
    </v-card-text>
    <v-card-text class="text-caption text-center">
      <span v-if="dataTemplate.miniwebData.identity.phone"
        >{{ $t("miniweb.contact.phone") }}
        {{
          dataTemplate.miniwebData.identity.firstname
            ? "(" + dataTemplate.miniwebData.identity.firstname + ") "
            : ""
        }}
        {{ dataTemplate.miniwebData.identity.phone }} </span
      ><br />
      <span v-if="dataTemplate.miniwebData.identity.email"
        >{{ $t("miniweb.contact.email") }}
        {{ dataTemplate.miniwebData.identity.email }} </span
      ><br />
      <span v-if="dataTemplate.miniwebData.identity.address1"
        >{{ $t("miniweb.address.address") }}
        {{
          dataTemplate.miniwebData.identity.address1 +
          ", " +
          dataTemplate.miniwebData.identity.address2 +
          ", " +
          dataTemplate.miniwebData.identity.postcode +
          " " +
          dataTemplate.miniwebData.identity.townName +
          "-" +
          dataTemplate.miniwebData.identity.countryName
        }}</span
      ><br />
      <span v-if="dataTemplate.miniwebData.identity.siret"
        >{{ $t("identity.siret") }}
        {{ dataTemplate.miniwebData.identity.siret }}</span
      >
    </v-card-text>
    <v-card-actions class="justify-center">
      <kdw-btn
        class="ma-2"
        :color="dataTemplate.template.themeColor"
        dark
        @click="showroomToService()"
      >
        {{ $t("miniweb.book") }}
      </kdw-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getPictureUrlfromS3Bucket } from "@/helpers/GenericFunctionsS3Download";

export default {
  name: "ElegantTemplateGeneralInfo",
  props: {
    dataTemplate: {
      type: Object,
    },
  },
  data: () => ({
    logoUrl: null,
  }),
  computed: {
    minHeight() {
      var height = window.innerHeight;
      height = Math.round(height * 0.6);
      return height;
    },
  },
  created: function () {
    if (
      this.dataTemplate.miniwebData &&
      this.dataTemplate.miniwebData.identity
    ) {
      // console.log('logoName:', this.dataTemplate.miniwebData.identity.logo)
      getPictureUrlfromS3Bucket(
        this.dataTemplate.miniwebData.identity.logo,
      ).then((url) => {
        // console.log('logoUrl:', url)
        this.logoUrl = url;
      });
    } else return "https://cdn.vuetifyjs.com/images/john.jpg";
  },
  methods: {
    showroomToService() {
      this.$vuetify.goTo("#ServicesTag", {
        duration: 1000,
        offset: 0,
        easing: "easeInOutQuint",
      });
    },
  },
};
</script>
